import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,AbstractControl,ValidationErrors,FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService,SettingsService } from '../_services';
declare var jquery: any;
declare var $: any;
@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
       hide=1;
    mobile_no = '';
    constructor(
            private setting: SettingsService,
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router) { }
static areEqual(c: AbstractControl): ValidationErrors | null {
        const keys: string[] = Object.keys(c.value);
        for (const i in keys) {
            if (i !== '0' && c.value['new_password'] !== c.value['confirm_password']) {
                return { areEqual: true };
            }
        }
    }
    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.maxLength(50), Validators.email]],
            password: ['', Validators.required],
            passwords: new FormGroup({
                new_password: new FormControl(),
                confirm_password: new FormControl()
            }, LoginComponent.areEqual)
        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        document.body.classList.add('jw-modal-open');
        this.loading = true;
        if(!this.hide){
this.setting.save(
            this.f.email.value,'1', this.f.password.value, this.f.passwords['controls'].new_password.value)
            .subscribe(
                data => {
                    if (data.status.code == 0) {
                        this.submitted = false;
                         document.body.classList.remove('jw-modal-open');
                         this.loading = false;
                        this.toastr.success(data.status.message);
                           location.href = this.returnUrl;

                         } else {
                        document.body.classList.remove('jw-modal-open');
                        this.loading = false;
                        this.toastr.error(data.status.message)
                        }
                },
                error => {
                    this.toastr.error(error)
                    document.body.classList.remove('jw-modal-open');
                    this.loading = false;
                });
        }else {


        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .subscribe(
                data => {
   this.loading = false;
    this.submitted = false;
      document.body.classList.remove('jw-modal-open');
                     if (data.status.code ==0) {
   location.href = this.returnUrl
                     }else if (data.status.code ==5) {
                       this.hide=0;
                       const newPassCtrl = this.loginForm.controls['passwords'].get('new_password');
        const reTypePassCtrl = this.loginForm.controls['passwords'].get('confirm_password');
        newPassCtrl.setValidators([Validators.required, Validators.maxLength(100)]);
            newPassCtrl.updateValueAndValidity();
            reTypePassCtrl.setValidators([Validators.required, Validators.maxLength(100)]);
            reTypePassCtrl.updateValueAndValidity();

                     }else{
// 
                        this.toastr.error(data.status.message)
                        document.body.classList.remove('jw-modal-open');
                        this.loading = false;
                    } 
                    
                },
                error => {

                    this.toastr.error(error)
                    document.body.classList.remove('jw-modal-open');
                    this.loading = false;
                });
        }
    }
}
