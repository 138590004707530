<site-header></site-header>
<div class="content-wrapper">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark">Dashboard</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item active text-uppercase">WELCOME <span *ngIf="adminName != 'undefined'">{{adminName}}</span> <span *ngIf="companyName && role =='1'">{{companyName}}</span></li>
          </ol>
        </div>
        </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
      <div class="container-fluid">
        <div class="col-lg-12">
          <div class="new_bar">
            <form   [formGroup]="searchForm" #myForm="ngForm">
              <ul>
                <!-- <li>
                  <ngx-select-dropdown #id
                  [config]="clientConfig"
                  [options]="clients$"
                  [multiple]="true"
                  (change)="filter()"
                  formControlName="client"
                  class="client">
                  </ngx-select-dropdown>
                  
                </li>
                <li>
                  
                  <ngx-select-dropdown #id
                  [config]="productConfig"
                  [options]="products$"
                  [multiple]="true"
                  (change)="filter()"
                  formControlName="product"
                  >
                  </ngx-select-dropdown>
                </li> -->
                <!-- <li class="seerch_cls"> -->
                <!-- <button type="submit" class="btn seerch_cls">Search</button> -->
                <!-- </li> -->
              </ul>
            </form>
          </div>
        </div>
      </div>
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <img src="/assets/images/xyz.png" id="image_logo">
          </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
      </div>