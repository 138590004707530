<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="login-box">
    <div class="login-logo">
        <!-- <a href="/login"><img src="assets/images/logo.png" class="img-responsive" alt="logo"></a> -->
    </div>
    <!-- /.login-logo -->
    <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg" *ngIf="hide">Sign in to start your session</p>
          <p class="login-box-msg" *ngIf="!hide">Change password to login</p>
            <form [formGroup]="loginForm" #myForm="ngForm" (ngSubmit)="onSubmit()"
                class="" >
                <div class="input-group mb-3" *ngIf="hide">
                    <input type="email" class="form-control" placeholder="Email"  formControlName="email"
                    [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors }">
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                        </div>
                    </div>
                    <div *ngIf="(f.email.touched || submitted) && f.email.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.email.errors?.required">Email is required</div>
                    </div>

                    <div *ngIf="(f.email.touched || submitted) && f.email.errors?.email"
                        class="invalid-feedback">
                        <div *ngIf="f.email.errors?.email"  >Please enter
                        valid email</div>
                    </div>
                    <div *ngIf="(f.email.touched || submitted) && f.email.errors?.maxlength"
                        class="invalid-feedback">
                        <div *ngIf="f.email.errors?.maxlength">Maximum 50 characters are allowed
                        </div>
                    </div>
                </div>
                <div class=" " formGroupName="passwords" *ngIf="!hide">
                    <div class="input-group mb-3">
                        <input   autocomplete="off" type="password" class="form-control password" id="inputNew" formControlName="new_password" placeholder="New Password" autocomplete="off" [ngClass]="{ 'is-invalid': (f.passwords['controls'].new_password.touched || submitted) && f.passwords['controls'].new_password.errors }"

                        >
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        <div *ngIf="(f.passwords['controls'].new_password.touched || submitted) && f.passwords['controls'].new_password.errors?.required" class="invalid-feedback">
                            <div *ngIf="f.passwords['controls'].new_password.errors?.required">New Password is required</div>
                        </div>
                        <div *ngIf="(f.passwords['controls'].new_password.touched || submitted) && f.passwords['controls'].new_password.errors?.maxlength" class="invalid-feedback">
                            <div *ngIf="f.passwords['controls'].new_password.errors?.maxlength">Maximum 100 characters are allowed</div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input autocomplete="off" type="password" class="form-control" id="inputConfirm" formControlName="confirm_password" placeholder="Confirm Password" autocomplete="off" [ngClass]="{ 'is-invalid': (f.passwords['controls'].confirm_password.touched || submitted) && (f.passwords['controls'].confirm_password.errors?.required
                        || f.passwords.errors?.areEqual) }" />

                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        <div *ngIf="(f.passwords['controls'].confirm_password.touched || submitted) && f.passwords['controls'].confirm_password.errors?.required " class="invalid-feedback">
                            <div *ngIf="f.passwords['controls'].confirm_password.errors?.required">
                            Confirm Password is required</div>
                        </div>
                        <div *ngIf="(f.passwords['controls'].confirm_password.touched || submitted) && f.passwords['controls'].confirm_password.errors?.maxlength " class="invalid-feedback">
                            <div *ngIf="f.passwords['controls'].confirm_password.errors?.maxlength">
                            Maximum 100 characters are allowed</div>
                        </div>
                        <div *ngIf="(f.passwords['controls'].confirm_password.touched || submitted) && (f.passwords.errors?.areEqual)" class="invalid-feedback">Passwords do not match
                        </div>
                    </div>
                </div>
                <div class="input-group mb-3" *ngIf="hide">
                    <input type="password" class="form-control" placeholder="Password"  formControlName="password"
                    [ngClass]="{ 'is-invalid': (f.password.touched || submitted) && f.password.errors }">
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                        </div>
                    </div>
                    <div *ngIf="(f.password.touched || submitted) && f.password.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.password.errors?.required">Password is required</div>
                    </div>
                </div>
                <div class="row">

                    <!-- /.col -->
                    <div class="col-4">
                        <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                    </div>
                    <!-- /.col -->
                </div>
            </form>
           <!--  <p class="mb-1">
                <a href="/forgot-password">I forgot my password</a>
            </p> -->
        </div>
        <!-- /.login-card-body -->
    </div>
</div>
