import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { SettingsService } from '../_services';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';


declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, AfterViewInit {
	adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
	companyName = localStorage.getItem(`${environment.appName}` + '_companyName');
	unit_price = localStorage.getItem(`${environment.appName}` + '_unit_price');

	role: any;
	settingForm: FormGroup;
	unitForm: FormGroup;
	loading = false;
	submitted = false;
	isResetFlag: boolean = false;
	constructor(
		private setting: SettingsService,
		private toastr: ToastrService,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router
	) { }

	static areEqual(c: AbstractControl): ValidationErrors | null {
		const keys: string[] = Object.keys(c.value);
		for (const i in keys) {
			if (i !== '0' && c.value['new_password'] !== c.value['confirm_password']) {
				return { areEqual: true };
			}
		}
	}

	ngOnInit() {

		this.unitForm = this.formBuilder.group({
			qty_price: ['', [Validators.required, Validators.maxLength(50),]],
			unit_commission: ['', [Validators.required, Validators.maxLength(50),]],
			unit_commission_price: ['', [Validators.required, Validators.maxLength(50),]],
			carrier_price: ['', [Validators.required, Validators.maxLength(50),]],
			product_abrevation: ['', [Validators.required, Validators.maxLength(50),]],
			default_state: ['', [Validators.required, Validators.maxLength(50),]],
			aggreement_terms: ['', [Validators.required, Validators.maxLength(50),]],
			mfg_labour_war: ['', [Validators.required, Validators.maxLength(50),]],
			mfg_part_war: ['', [Validators.required, Validators.maxLength(50),]],
			product_manufacture: ['', [Validators.required, Validators.maxLength(50),]],
			email_invoice_to: ['', [Validators.required, Validators.maxLength(50), Validators.email]],
			email_remit: ['', [Validators.required, Validators.maxLength(50), Validators.email]],
			email_history: ['', [Validators.required, Validators.maxLength(50), Validators.email]],
		});

		this.setting.getData().subscribe(data => { console.log(data); 
			var that=this;
			data.data.forEach(function(value) {
				that.unitForm.setValue({
					qty_price: value.unit_price ? value.unit_price:'',
					unit_commission: value.unit_commission ? value.unit_commission : '',
					unit_commission_price: value.unit_commission_price ? value.unit_commission_price : '',
					carrier_price: value.carrier_price ? value.carrier_price : '',
					product_abrevation: value.product_abrevation ? value.product_abrevation : '',
					default_state: value.default_state ? value.default_state : '',
					aggreement_terms: value.aggreement_terms ? value.aggreement_terms : '',
					mfg_labour_war: value.mfg_labour_war ? value.mfg_labour_war : '',
					mfg_part_war: value.mfg_part_war ? value.mfg_part_war : '',
					product_manufacture: value.product_manufacture ? value.product_manufacture : '',
					email_invoice_to: value.email_invoice_to ? value.email_invoice_to : '',
					email_remit: value.email_remit ? value.email_remit : '',
					email_history: value.email_history ? value.email_history : ''
				});
			})

		}, error => { console.error(error); });

		if (this.router.url == '/edit_data') {
			$('#content').hide();
		}

		if (this.router.url == '/settings') {
			$('#quantity').hide();
		}

		this.role = localStorage.getItem(`${environment.appName}` + '_role');

		this.settingForm = this.formBuilder.group({
			upd_username: ['', [Validators.required, Validators.maxLength(50), Validators.email]],
			is_reset_password: '',
			upd_password: ['', Validators.required],
			passwords: new FormGroup({
				new_password: new FormControl(),
				confirm_password: new FormControl()
			}, SettingsComponent.areEqual)
		});

		

		/*this.unitForm.setValue({
			qty_price:'',
		});*/
	}
	get f() { return this.settingForm.controls; }

	handleChange(event): void {
		const newPassCtrl = this.settingForm.controls['passwords'].get('new_password');
		const reTypePassCtrl = this.settingForm.controls['passwords'].get('confirm_password');

		if (event.target.checked) {
			newPassCtrl.setValidators([Validators.required, Validators.maxLength(100)]);
			newPassCtrl.updateValueAndValidity();
			reTypePassCtrl.setValidators([Validators.required, Validators.maxLength(100)]);
			reTypePassCtrl.updateValueAndValidity();

			this.isResetFlag = true;
			newPassCtrl.enable();
			reTypePassCtrl.enable();
		} else {
			newPassCtrl.clearValidators();
			newPassCtrl.updateValueAndValidity();
			reTypePassCtrl.clearValidators();
			reTypePassCtrl.updateValueAndValidity();

			this.isResetFlag = false;
			newPassCtrl.disable();
			reTypePassCtrl.disable();
		}
	}

	get u() { return this.unitForm.controls; }
	quantitySubmit(){
		console.log(this.unitForm);
		this.submitted = true;
		if(this.unitForm.invalid){
			return false;
		}
		document.body.classList.add('jw-modal-open');
		this.loading = true;
		this.setting.updateUnit(this.u.qty_price.value,
			this.u.unit_commission.value,
			this.u.unit_commission_price.value,
			this.u.carrier_price.value,
			this.u.product_abrevation.value,
			this.u.default_state.value,
			this.u.aggreement_terms.value,
			this.u.mfg_labour_war.value,
			this.u.mfg_part_war.value,
			this.u.product_manufacture.value,
			this.u.email_invoice_to.value,
			this.u.email_remit.value,
			this.u.email_history.value

			).subscribe(data => { 
				document.body.classList.remove('jw-modal-open');
				this.loading = false;
				this.submitted = false;
				this.toastr.success('Successfully updated');
				location.reload();
				console.log(data); 

			}, error => { console.error(error); });


	}


	onSubmit(form, formWrap) {
		this.submitted = true;
		if (this.settingForm.invalid) {
			return;
		}

		document.body.classList.add('jw-modal-open');
		this.loading = true;
		this.setting.save(
			this.f.upd_username.value, this.f.is_reset_password.value, this.f.upd_password.value, this.f.passwords['controls'].new_password.value)
			.subscribe(
				data => {
					if (data.status.code == 0) {
						this.submitted = false;
						this.isResetFlag = false;
						document.body.classList.remove('jw-modal-open');
						this.loading = false;
						formWrap.reset();
						this.toastr.success(data.status.message)

						// alertify.success(data.status.message)
					} else {
						document.body.classList.remove('jw-modal-open');
						this.loading = false;
						this.toastr.error(data.status.message)
						// alertify.error(data.status.message)
					}
				},
				error => {
					this.toastr.error(error)
					// alertify.error(error)
					document.body.classList.remove('jw-modal-open');
					this.loading = false;
				});
	}
	ngAfterViewInit() {
		setTimeout(() => {
			$('.username, .password').val('');
		}, 500);
	}
}
