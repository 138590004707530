import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class OrderService {
    constructor(private http: HttpClient) { }

    save(
      serial_no:string,
    driveline_id:string,
    manufacturing_date: string,
    sold_to: string,
    shipped_to: string,
    product_category: string,
    product_family: string,
    technical_platform: string,
    application: string,
    product_name: string,
    ship_date: string,
    manufacturing_plant: string,
    region: string,
    country: string,
    product_designation: string,
    sales_order_no: string,
    sales_order_type: string,
    part_no_on_product: string,
    scc_individual_status: string,
    exported_from_the_factory: string,
    reason: string,
    client: string,
      year: string,
      month: string,
      ) {
    console.log(serial_no);
    console.log(driveline_id);
        const formData: FormData = new FormData();

    formData.append('serial_no', serial_no);
    formData.append('driveline_id', driveline_id);
    formData.append('manufacturing_date', manufacturing_date);
    formData.append('sold_to', sold_to);
    formData.append('shipped_to', shipped_to);
    formData.append('product_category', product_category);
    formData.append('product_family', product_family);
    formData.append('technical_platform', technical_platform);
    formData.append('application', application);
    formData.append('product_name', product_name);
    formData.append('ship_date', ship_date);
    formData.append('manufacturing_plant', manufacturing_plant);
    formData.append('region', region);
    formData.append('country', country);
    formData.append('product_designation', product_designation);
    formData.append('sales_order_no', sales_order_no);
    formData.append('sales_order_type', sales_order_type);
    formData.append('part_no_on_product', part_no_on_product);
    formData.append('scc_individual_status', scc_individual_status);
    formData.append('exported_from_the_factory', exported_from_the_factory);
    formData.append('reason', reason);
    formData.append('client_id', JSON.stringify(client));
    formData.append('year', year);
        formData.append('month', month);
        /*if(state!=undefined){
          formData.append('state', state);
        }
       if(country!=undefined){
        formData.append('country', country);
       }*/

        /*formData.append('first_name1', first_name1);
        formData.append('middle_name1', middle_name1);
        formData.append('last_name1', last_name1);
        formData.append('first_name2', first_name2);
        formData.append('middle_name2', middle_name2);
        formData.append('last_name2', last_name2);*/

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/order`, formData).pipe(map(user => {
            return user;
        }));

    }
 updateToOrder(data:any){
         var headers = new HttpHeaders();
        var heads = headers.set("Content-Type","application/json");
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/order/postorder` , data,{headers:heads}).pipe(map(user => {
          return user;
        }));
    }

    update(
      id: string,
      serial_no: string,
      driveline_id: string,
      manufacturing_date: string,
      sold_to: string,
      shipped_to: string,
      product_category: string,
      product_family: string,
      technical_platform: string,
      application: string,
      product_name: string,
      ship_date: string,
      manufacturing_plant: string,
      region: string,
      country: string,
      product_designation: string,
      sales_order_no: string,
      sales_order_type: string,
      part_no_on_product: string,
      scc_individual_status: string,
      exported_from_the_factory: string,
      reason: string,
      client: string,
      year: string,
      month: string,
       ) {
        const formData: FormData = new FormData();


        formData.append('serial_no', serial_no);
        formData.append('driveline_id', driveline_id);
        formData.append('manufacturing_date', manufacturing_date);
        formData.append('sold_to', sold_to);
        formData.append('shipped_to', shipped_to);
        formData.append('product_category', product_category);
        formData.append('product_family', product_family);
        formData.append('technical_platform', technical_platform);
        formData.append('application', application);
        formData.append('product_name', product_name);
        formData.append('ship_date', ship_date);
        formData.append('manufacturing_plant', manufacturing_plant);
        formData.append('region', region);
        formData.append('country', country);
        formData.append('product_designation', product_designation);
        formData.append('sales_order_no', sales_order_no);
        formData.append('sales_order_type', sales_order_type);
        formData.append('part_no_on_product', part_no_on_product);
        formData.append('scc_individual_status', scc_individual_status);
        formData.append('exported_from_the_factory', exported_from_the_factory);
        formData.append('reason', reason);
        formData.append('invoice_date', '');
        formData.append('client_id', client);
        formData.append('year', year);
        formData.append('month', month);


        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/order/orderupdate/` + id, formData).pipe(map(user => {
          return user;
        }));

    }
    saveBulkOrder(client_name: string, file: any,   year: string,
      month: string){
      console.log(file);
      const formData: FormData = new FormData();

    formData.append('client_id', JSON.stringify(client_name));
    formData.append('upload', file);
      formData.append('year', year);
        formData.append('month', month);
      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/order/bulk-order`, formData).pipe(map(user => {
        return user;
    }));
    }
    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/order/` + id).pipe(map(user => {
            return user;
        }));

    }
    getProducts(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product`).pipe(map(user => user));

    }

    getOrders(): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/order/order_list`).pipe(map(user => user));

  }
  searchFilter(client: any, product: any) {


    // tslint:disable-next-line: max-line-length
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/dashboard?product_id[]=` + product + '&client_id[]=' + client ).pipe(map(user => {
        return user;
    }));

}
  changeStatus(id: string, status: string, link: any,link_update:any){
    // console.log(file);
    const formData: FormData = new FormData();
    formData.append('status', status);
    formData.append('link', link);
        formData.append('link_update', link_update);

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/order/order-update/` + id, formData).pipe(map(user => {
      return user;
  }));
  }
  download(client_id:any):  Promise<Blob> {
    const formData: FormData = new FormData();
    formData.append('download', '1');
   
        formData.append('client_id', client_id);

    const file =  this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/order/list`,formData,
    {responseType: 'blob' as 'json'}).toPromise();
    return file;
  }
  delete_order(id:string){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/order/delete/`+id).pipe(map(user => user));
  }
  orderEdit(id:string){
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/order/edit/`+id).pipe(map(user => user));
  }


  generateOrder(client_id){
    const formData: FormData = new FormData();
    formData.append('client_id', client_id);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/generate`,formData).pipe(map(data => data));
  }


  downloadInvoice(invoice_id): Promise<Blob> {
    console.log("asuhdtgsuyfd");
    const file = this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/download_xl/` + invoice_id,
      { responseType: 'blob' as 'json' }).toPromise();
    return file;
    //return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/download_xl/` + invoice_id, {headers:options}).pipe(map(data => { return data; }));
    //return file;
      /*{ responseType: 'blob' as 'json' }).toPromise();*/
    /*return file;*/
  }


  uploadInvoice(client_id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/generate-upload/`+client_id).pipe(map(user => { return user }));
  
    //http://13.233.153.48:82/api/v1/generate-upload?invoice_id=5f0d788a019a2d67142937e7
  }

  getInvoices(client_id): Observable<any> {
     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getInvoice/`+client_id).pipe(map(user => { return user}));
  }
 
  updateColoumn(coloumn_name){
    const formData: FormData = new FormData();
    formData.append('coloumn_name', JSON.stringify(coloumn_name));
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/updateColoumn`,formData).pipe(map(data => data));
  }

  download_template(client_id:any):  Promise<Blob> {
    const formData: FormData = new FormData();
    formData.append('download', '1');
   
        formData.append('client_id', client_id);

    const file =  this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/download-template/`+client_id,formData,
    {responseType: 'blob' as 'json'}).toPromise();
    return file;
  }
  invoiceUpdate(
    id:string,
    invoice_date:any,
    invoice_number:any,
    payment_date:any,
    payment_method:any,
    amount_paid:any,
    funds_forward:any,
    amount_forward:any

  ){
    const formData: FormData = new FormData();
    formData.append('invoice_date', invoice_date);
    formData.append('invoice_number', invoice_number);
    formData.append('payment_date', payment_date);
    formData.append('payment_method', payment_method);
    formData.append('amount_paid', amount_paid);
    formData.append('funds_forward', funds_forward);
    formData.append('amount_forward', amount_forward);
    
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/update-invoice/`+id,formData).pipe(map(data => data));
  }

  searchClient(id: string,search:any){
    // console.log(file);
    const formData: FormData = new FormData();
    formData.append('search', search);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/order/list/` + id,formData).pipe(map(user => {
      return user;
  }));
  }
}
