import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { BannersService, AlertService, Countryservice, ClientService, ProductService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-manage-products',
  templateUrl: './manage-products.component.html',
  styleUrls: ['./manage-products.component.css']
})
export class ManageProductsComponent implements OnInit, OnDestroy, AfterViewInit {
  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  companyName = localStorage.getItem(`${environment.appName}` + '_companyName');
   role :any;
       products$: any;
  productForm: FormGroup;
  submitted: boolean;
  cancelButton: boolean;
  loading: boolean;
  clientForm: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  curPage = 1;
  perPage = 10;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private countryService: Countryservice,
    private clientService: ClientService,
    private productService: ProductService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
  ) { }
  ngOnInit(): void {
    this.role = localStorage.getItem(`${environment.appName}` + '_role');

    const that = this
    that.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: this.perPage,
      // lengthChange: false,
      language: {
        searchPlaceholder: "Product Name"
      },
      searching: true,
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 1
      }],
      ordering: false,
    };

    that.productForm = that.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      product_name: ['', [Validators.required, Validators.maxLength(30)]],

    });

    that.productService.getProducts().subscribe(data=>{
      that.products$ = data.data.product;
      that.dtTrigger.next();
    })

  }

  get f() { return this.productForm.controls; }
  onSubmit(form, formWrap) {

var that = this
that.submitted = true;
        let id = form.getRawValue()._id;
        let index = form.getRawValue().index;


        // stop here if form is invalid
        if (that.productForm.invalid) {
          return;
        }


        if (id != null) {
          document.body.classList.add('jw-modal-open');
          alertify.confirm('Update Product','Are you sure want to update this record', function () {
          that.loading = true;
          that.productService.update(id,that.f.product_name.value, )
            .subscribe(
              data => {
                if (data.status.code == 0) {
                  that.submitted = false;
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.cancelButton = false;
                  // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                  // this.users$[index] = data.data.banner;
                  that.productForm.setValue({
                    index: null,
                    _id: null,
                  product_name:'',
                  })
                  formWrap.reset();



                  location.href = "/manage-products";

                } else {
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.toastr.error(data.status.message)
                  // alertify.error(data.status.message)
                  //this.alertService.error(data.status.message);
                }
              },
              error => {
                that.toastr.error(error)

                document.body.classList.remove('jw-modal-open');
                that.loading = false;
              });
            }
            , function () { //alertify.error('Delete action cancelled')
              that.toastr.error('Update action cancelled')

            });
        } else {
          document.body.classList.add('jw-modal-open');
          that.loading = true;
          alertify.confirm('Create Product','Are you sure want to create this record', function () {
            that.productService.save(that.f.product_name.value, )
            .subscribe(
              data => {
                if (data.status.code == 0) {
                  that.submitted = false;
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.cancelButton = false;
                  location.reload();



                  that.productForm.setValue({
                    index: null,
                    _id: null,
                    product_name: '',

                  })
                  formWrap.reset();


                  //this.router.navigate(['/banners']);
                  location.href = 'manage-products';

                } else {
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.toastr.error(data.status.message)
                  // alertify.error(data.status.message)
                  //this.alertService.error(data.status.message);
                }
              },
              error => {
                that.toastr.error(error)
                // alertify.error(error)
                //this.alertService.error(error);
                document.body.classList.remove('jw-modal-open');
                that.loading = false;
              });
            }
            , function () { //alertify.error('Delete action cancelled')
              that.toastr.error('Create action cancelled')

            });

        }
      }

      cancelSubmit(event) {

        this.cancelButton = false;
        document.getElementsByClassName('table-responsive')[0].classList.remove('disabledTable');
        this.productForm.setValue({
          index: null,
          _id: null,
          product_name : ''

        });
        this.submitted = false;
        location.reload();
      }
  editProduct(event,product,i){
    // document.getElementsByClassName("table-responsive")[0].classList.add('disabledTable');
    this.cancelButton = true;
    this.productForm.setValue({

      index: i,
      _id: product._id,
      product_name: product.product_name ? product.product_name : '',




    })
  }
   deleteProduct(event,product,i){
    var that = this;
    alertify.confirm('Delete Product','Are you sure want to delete this record', function () {
      that.productService.delete(product._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              that.toastr.success('Deleted Successfully')
              // alertify.success('Deleted Successfully')
              setTimeout(function () {
                location.reload();
              }, 1000);
              //that.users$.splice(i, 1);
            } else {
              that.toastr.error(data.status.message)
              // alertify.error(data.status.message)
              //this.alertService.error(data.status.message);
            }
          },
          error => {
            that.toastr.error(error)
            // alertify.error(error)
            //this.alertService.error(error);
          });
    }
      , function () { //alertify.error('Delete action cancelled')
        that.toastr.error('Delete action cancelled')

      });

   }

   ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        var table = $('#table').DataTable();
      }
    }, 500);
  }

}
