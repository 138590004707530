<site-header></site-header>
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">User Management</li>
          </ol>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item active text-uppercase">WELCOME <span *ngIf="adminName != 'undefined'">{{adminName}}</span> <span *ngIf="companyName && role ==1">{{companyName}}</span></li>
          </ol>
        </div>
        </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid new_div">
          <form [formGroup]="userForm" #myForm="ngForm" (ngSubmit)="onSubmit(userForm, myForm)"
            class="" >
            <div class="row">
              <div class="col-3">
                <div class="user_create">
                  <div class="user_create_form">
                    <div class="form-group">
                      <label for="name">First Name<span class="text-danger">*</span></label>
                      <input type="text" autocomplete="off" class="form-control" id="name" placeholder="First Name"  formControlName="firstName"
                      [ngClass]="{ 'is-invalid': (f.firstName.touched || submitted) && f.firstName.errors }">
                      <div *ngIf="(f.firstName.touched || submitted) && f.firstName.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.firstName.errors?.required">First Name is required</div>
                      </div>
                      <div *ngIf="(f.firstName.touched || submitted) && f.firstName.errors?.maxlength"
                        class="invalid-feedback">
                        <div *ngIf="f.firstName.errors?.maxlength">Maximum 20 characters are allowed
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="name">Last Name<span class="text-danger">*</span></label>
                      <input type="text" autocomplete="off" class="form-control" id="name" placeholder="Last Name" formControlName="lastName"
                      [ngClass]="{ 'is-invalid': (f.lastName.touched || submitted) && f.lastName.errors }">
                      <div *ngIf="(f.lastName.touched || submitted) && f.lastName.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.lastName.errors?.required">Last Name is required</div>
                      </div>
                      <div *ngIf="(f.lastName.touched || submitted) && f.lastName.errors?.maxlength"
                        class="invalid-feedback">
                        <div *ngIf="f.lastName.errors?.maxlength">Maximum 10 characters are allowed
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>User Type</label>
                      <select class="form-control" (change)="ChnageType($event)"   formControlName="userType" [ngClass]="{ 'is-invalid': (f.userType.touched || submitted) && f.userType.errors }">
                        <option value="">Select User Type </option>
                        <option value="1">Production</option>
                        <option value="0" >Client</option>
                      </select>
                      <div *ngIf="(f.userType.touched || submitted) && f.userType.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.userType.errors?.required">User Type is required</div>
                      </div>
                    </div>
                    <div class="form-group" id="singleClient" *ngIf="!mutiselect">
                      <label>Select Clients</label>
                      <ngx-select-dropdown #id
                      [config]="categoryCongfig"
                      [options]="clients$"
                      formControlName="client"
                      (change)="signleClient($event)"
                      [ngClass]="{ 'is-invalid': (f.client.touched || submitted) && f.client.errors }">
                      </ngx-select-dropdown>
                      <div *ngIf="(f.client.touched || submitted) && f.client.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.client.errors?.required">Client is required</div>
                      </div>
                    </div>
                    <div class="form-group" id="multiClient" *ngIf="mutiselect">
                      <label>Select Clients</label>
                      <ngx-select-dropdown #id
                      [config]="categoryCongfig"
                      [options]="clients$"
                      [multiple]="true"
                      formControlName="client"
                      (change)="multiClient($event)"
                      [ngClass]="{ 'is-invalid': (f.client.touched || submitted) && f.client.errors }">
                      </ngx-select-dropdown>
                      <div *ngIf="(f.client.touched || submitted) && f.client.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.client.errors?.required">Client is required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <span class="act_cls mobile_no"><input type="radio" id="us" name="whichCountry"  value="0" formControlName="whichCountry">&nbsp;&nbsp;<label for="active">US</label></span>&nbsp;&nbsp;&nbsp;&nbsp;
                      <span class="act_cls mobile_no"><input type="radio" id="india" name="whichCountry" value="1" class="act_cls"  formControlName="whichCountry">&nbsp;&nbsp;<label for="inactive">India</label></span>
                    </div>
                    <div class="form-group">
                      <label for="number">Phone Number<span class="text-danger">*</span></label>
                      <input type="text" autocomplete="off"  class="form-control" id="mobile_no" placeholder="Phone Number" formControlName="phoneNumber"
                      [ngClass]="{ 'is-invalid': (f.phoneNumber.touched || submitted) && f.phoneNumber.errors }">
                      <div *ngIf="(f.phoneNumber.touched || submitted) && f.phoneNumber.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.phoneNumber.errors?.required">Phone Number is required</div>
                      </div>
                      <div *ngIf="(f.phoneNumber.touched || submitted) && f.phoneNumber.errors?.pattern"
                                            class="invalid-feedback">
                                            <div *ngIf="f.phoneNumber.errors?.pattern">Invalid is required </div>
                                        </div>
                      <div *ngIf="(f.phoneNumber.touched || submitted) && f.phoneNumber.errors?.maxlength"
                        class="invalid-feedback">
                        <div *ngIf="f.phoneNumber.errors?.maxlength">Maximum 15 digits are allowed
                        </div>
                      </div>
                      <div *ngIf="(f.phoneNumber.touched || submitted) && f.phoneNumber.errors?.minlength"
                        class="invalid-feedback">
                        <div *ngIf="f.phoneNumber.errors?.minlength">Please enter minimum 10 digits
                        </div>
                      </div>
                      <div *ngIf="(f.phoneNumber.touched || submitted) && f.phoneNumber.errors?.pattern"
                        class="invalid-feedback">
                        <div *ngIf="f.phoneNumber.errors?.pattern">Please enter digits only
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <span class="act_cls"><input type="radio" id="active" name="status" value="1" formControlName="status">&nbsp;&nbsp;<label for="active">Active</label></span>&nbsp;&nbsp;&nbsp;&nbsp;
                      <span class="act_cls"><input type="radio" id="inactive" name="status" value="0" class="act_cls" formControlName="status">&nbsp;&nbsp;<label for="inactive">Inactive</label></span>
                    </div>
                    <label>Address</label>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <input type="text" autocomplete="off" class="form-control" placeholder="No." formControlName="addressNumber"
                          [ngClass]="{ 'is-invalid': (f.addressNumber.touched || submitted) && f.addressNumber.errors }">
                          <div *ngIf="(f.addressNumber.touched || submitted) && f.addressNumber.errors?.required"
                            class="invalid-feedback">
                            <div *ngIf="f.addressNumber.errors?.required">Address Number is required</div>
                          </div>
                          <div *ngIf="(f.addressNumber.touched || submitted) && f.addressNumber.errors?.maxlength"
                            class="invalid-feedback">
                            <div *ngIf="f.addressNumber.errors?.maxlength">Maximum 10 characters are allowed
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <input type="text" autocomplete="off" class="form-control" placeholder="Prefix"  formControlName="addressPrefix"
                          [ngClass]="{ 'is-invalid': (f.addressPrefix.touched || submitted) && f.addressPrefix.errors }">
                          <div *ngIf="(f.addressPrefix.touched || submitted) && f.addressPrefix.errors?.required"
                            class="invalid-feedback">
                            <div *ngIf="f.addressPrefix.errors?.required">Address prefix is required</div>
                          </div>
                          <div *ngIf="(f.addressPrefix.touched || submitted) && f.addressPrefix.errors?.maxlength"
                            class="invalid-feedback">
                            <div *ngIf="f.addressPrefix.errors?.maxlength">Maximum 20 characters are allowed
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <input type="text" autocomplete="off" class="form-control" placeholder="Street Name" formControlName="streetName"
                          [ngClass]="{ 'is-invalid': (f.streetName.touched || submitted) && f.streetName.errors }">
                          <div *ngIf="(f.streetName.touched || submitted) && f.streetName.errors?.required"
                            class="invalid-feedback">
                            <div *ngIf="f.streetName.errors?.required">Street Name is required</div>
                          </div>
                          <div *ngIf="(f.streetName.touched || submitted) && f.streetName.errors?.maxlength"
                            class="invalid-feedback">
                            <div *ngIf="f.streetName.errors?.maxlength">Maximum 100 characters are allowed
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <input type="text" autocomplete="off" class="form-control" placeholder="Type" formControlName="addressType"
                          [ngClass]="{ 'is-invalid': (f.addressType.touched || submitted) && f.addressType.errors }">
                          <div *ngIf="(f.addressType.touched || submitted) && f.addressType.errors?.required"
                            class="invalid-feedback">
                            <div *ngIf="f.addressType.errors?.required">Address type is required</div>
                          </div>
                          <div *ngIf="(f.addressType.touched || submitted) && f.addressType.errors?.maxlength"
                            class="invalid-feedback">
                            <div *ngIf="f.addressType.errors?.maxlength">Maximum 20 characters are allowed
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <input type="text" autocomplete="off" class="form-control" placeholder="Suffix" formControlName="addressSuffix"
                          [ngClass]="{ 'is-invalid': (f.addressSuffix.touched || submitted) && f.addressSuffix.errors }">
                          <div *ngIf="(f.addressSuffix.touched || submitted) && f.addressSuffix.errors?.required"
                            class="invalid-feedback">
                            <div *ngIf="f.addressSuffix.errors?.required">Address Suffix is required</div>
                          </div>
                          <div *ngIf="(f.addressSuffix.touched || submitted) && f.addressSuffix.errors?.maxlength"
                            class="invalid-feedback">
                            <div *ngIf="f.addressSuffix.errors?.maxlength">Maximum 20 characters are allowed
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <input type="text" autocomplete="off" class="form-control" placeholder="City" formControlName="city"
                          [ngClass]="{ 'is-invalid': (f.city.touched || submitted) && f.city.errors }">
                          <div *ngIf="(f.city.touched || submitted) && f.city.errors?.required"
                            class="invalid-feedback">
                            <div *ngIf="f.city.errors?.required">City is required</div>
                          </div>
                          <div *ngIf="(f.city.touched || submitted) && f.city.errors?.maxlength"
                            class="invalid-feedback">
                            <div *ngIf="f.city.errors?.maxlength">Maximum 20 characters are allowed
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <input type="text" autocomplete="off" class="form-control" placeholder="Postal/Zip code" formControlName="zip"
                          [ngClass]="{ 'is-invalid': (f.zip.touched || submitted) && f.zip.errors }">
                          <div *ngIf="(f.zip.touched || submitted) && f.zip.errors?.required"
                            class="invalid-feedback">
                            <div *ngIf="f.zip.errors?.required">Zip is required</div>
                          </div>
                          <div *ngIf="(f.zip.touched || submitted) && f.zip.errors?.maxlength"
                            class="invalid-feedback">
                            <div *ngIf="f.zip.errors?.maxlength">Maximum 15 characters are allowed
                            </div>
                          </div>
                          <div *ngIf="(f.zip.touched || submitted) && f.zip.errors?.pattern"
                            class="invalid-feedback">
                            <div *ngIf="f.zip.errors?.pattern">Only numeric values are allowed
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <ngx-select-dropdown #id
                      [config]="countryConfig"
                      [options]="countries$"
                      [(ngModel)]="countryModel"
                      formControlName="country"
                      (change)="onItemSelect($event)"
                      [ngClass]="{ 'is-invalid': (f.country.touched || submitted) && f.country.errors }">
                      </ngx-select-dropdown>
                      <div *ngIf="(f.country.touched || submitted) && f.country.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.country.errors?.required">Country is required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <ngx-select-dropdown #id
                      [config]="stateConfig"
                      [options]="states$"
                      [(ngModel)]="stateModel"
                      formControlName="state"
                      [ngClass]="{ 'is-invalid': (f.state.touched || submitted) && f.state.errors }">
                      </ngx-select-dropdown>
                      <div *ngIf="(f.state.touched || submitted) && f.state.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.state.errors?.required">State is required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <!-- <input #myInput *ngIf="!imageExist" type="file" class="form-control"
                      formControlName="idProof"
                      [ngClass]="{ 'is-invalid': (f.idProof.touched || submitted) && f.idProof.errors }"
                      placeholder="Image" (change)="handleFileInput($event, $event.target.files)">
                      <div *ngIf="(f.idProof.touched || submitted) && f.idProof.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.idProof.errors.required">Image is required</div>
                      </div>
                      <a *ngIf="imageExist" class="mr-10" href="{{imageExist}}" target="_blank">ID Proof</a><a *ngIf="imageExist" (click)="imageEdit($event)">| Change</a> -->
                      <label for="name" >ID proof</label><br>
                      <div class="show-image">
                        <img src="{{media_domain}}{{idImage}}" *ngIf="idImage" height="100" width="100">
                        <img [src]="imageExist" height="100" width="100"
                        *ngIf="imageExist" class="proofImage" >
                        <input class="delete" type="button" value="X" title="Delete" (click)="deleteImage()" *ngIf="imageExist || idImage"/>
                      </div>
                      <div class="input-group">
                        <div class="custom-file"  *ngIf="!idImage || !imageExist">
                          <input type="file" class="custom-file-input"  *ngIf="!imageExist || idImage != ''" id="exampleInputFile"  formControlName="idProof"
                          [ngClass]="{ 'is-invalid': (f.idProof.touched || submitted) && f.idProof.errors }" (change)="handleFileInput($event, $event.target.files)">
                          <label class="custom-file-label" for="exampleInputFile">Choose file</label>
                        </div>
                      </div>
                      <!-- <span class="text-danger">(ID Dimensions:{{widthLimit}}X{{heightLimit}})</span> -->
                    </div>
                    <div class="form-group">
                      <label for="name">Email ID<span class="text-danger">*</span></label>
                      <input type="text" autocomplete="off" class="form-control" id="name" placeholder="Email ID" formControlName="emailID"
                      [ngClass]="{ 'is-invalid': (f.emailID.touched || submitted) && f.emailID.errors }">
                      <div *ngIf="(f.emailID.touched || submitted) && f.emailID.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.emailID.errors?.required">Email ID is required</div>
                      </div>
                      <div *ngIf="(f.emailID.touched || submitted) && f.emailID.errors?.maxlength"
                        class="invalid-feedback">
                        <div *ngIf="f.emailID.errors?.maxlength">Maximum 20 characters are allowed
                        </div>
                      </div>
                      <div *ngIf="(f.emailID.touched || submitted) && f.emailID.errors?.pattern"
                        class="invalid-feedback">
                        <div *ngIf="f.emailID.errors?.pattern"> Invalid Email Id
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="name">Password</label>
                      <input type="password" autocomplete="off" class="form-control" id="name"  placeholder="Password" formControlName="password"
                      [ngClass]="{ 'is-invalid': (f.password.touched || submitted) && f.password.errors }">
                      <div *ngIf="(f.password.touched || submitted) && f.password.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.password.errors?.required">Password is required</div>
                      </div>
                      <div *ngIf="(f.password.touched || submitted) && f.password.errors?.maxlength"
                        class="invalid-feedback">
                        <div *ngIf="f.password.errors?.maxlength">Maximum 100 characters are allowed
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="name">Confirm Password</label>
                      <input type="password" autocomplete="off" class="form-control" id="name" placeholder="Confirm Password" formControlName="confirmPassword"
                      [ngClass]="{ 'is-invalid': (f.confirmPassword.touched || submitted) && f.confirmPassword.errors }">
                      <div *ngIf="(f.confirmPassword.touched || submitted) && f.confirmPassword.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors?.required">Confirm password is required</div>
                      </div>
                      <div *ngIf="(f.confirmPassword.touched || submitted) && f.confirmPassword.errors?.maxlength"
                        class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors?.maxlength">Maximum 100 characters are allowed
                        </div>
                      </div>
                      <div *ngIf="(f.confirmPassword.touched || submitted) && f.confirmPassword.errors?.ConfirmedValidator"
                        class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors?.ConfirmedValidator">Password must match
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="product_create_btn">
                    <button type="submit">
                    <div *ngIf="cancelButton;then update else add">here is ignored</div>
                    <ng-template #add>Create User</ng-template>
                    <ng-template #update>Update User</ng-template>
                    </button>
                    <a style="margin-left:5px" (click)="cancelSubmit($event)"
                      class="btn btn-danger cancel" *ngIf="cancelButton" >
                      <i class="icon md-close" aria-hidden="true"></i> Cancel
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-9">
                <div class="product_create">
                  <div class="table-responsive">
                    <table cellspacing="0" id="table" class="table table-hover dataTable table-striped w-full"
                      datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                      <thead>
                        <tr>
                          <th>User Name</th>
                          <!-- <th>Company Name</th> -->
                          <th>Phone</th>
                          <th>Email ID</th>
                          <th>Status</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody *ngIf ="users$" >
                        <tr *ngFor = "let user of users$; let i =index;">
                          <td *ngIf="user.first_name">{{user.first_name}} </td>
                          <!-- <td  *ngIf="user.company_name">{{user.company_name}}</td> -->
                          <td  *ngIf="user.mobile_no">{{user.mobile_no}}</td>
                          <td  *ngIf="user.email">{{user.email}}</td>
                          <td *ngIf="user.active == 1">Active</td>
                          <td *ngIf="user.active !=1">Inactive</td>
                          <td><i class="fas fa-pencil-alt" title="Edit" style="cursor: pointer;" (click)="userEdit($event,user,i)"></i></td>
                          <td><i class="fas fa-trash-alt" title="Delete" style="cursor: pointer;" (click)="userDelete($event,user,i)"></i></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </form>
          </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
      </div>