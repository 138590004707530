<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<!-- <script src="https://nightly.datatables.net/js/jquery.dataTables.js"></script> -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">Home</a></li>
            <li class="breadcrumb-item active">  Invoice Report</li>
          </ol>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item active text-uppercase">WELCOME <span *ngIf="adminName != 'undefined'">{{adminName}}</span> <span *ngIf="companyName && role ==1">{{companyName}}</span></li>
          </ol>
        </div>
        </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="serach_sec">
            <div class="row">
              <div class="col-sm-6">
                <div class="order-head">  Invoice Report</div>
              </div>
              <div class="col-sm-6 ">
                <div class="order-head download">
                  <p class="report_found" *ngIf="total$ && total$ > 1">Total {{total$}} reports found</p>
                  <p class="report_found" *ngIf="total$ && total$ == 1">Total {{total$}} report found</p>
                  <p class="report_found" *ngIf="total$ == 0">Total 0 report found</p>
                </div>
              </div>
            </div>
            <div class="search_cls">
              <form   [formGroup]="searchForm" #myForm="ngForm" (ngSubmit)="reportDown(searchForm, myForm)">
                <div class="row">
                  <div class="col-lg-2 ">
                    <ngx-select-dropdown #id
                    [config]="clientConfig"
                    [options]="clients$"
                    formControlName="client"
                    (change)="filter()"
                    class="clientSelect"
                    [ngClass]="{ 'is-invalid': (f.client.touched || submitted) && f.client.errors }">
                    ></ngx-select-dropdown>
                    <div *ngIf="(f.client.touched || submitted) && f.client.errors?.required"
                      class="invalid-feedback">
                      <div *ngIf="f.client.errors?.required">Client is required</div>
                    </div>
                  </div>
                  <div class="col-lg-2 ">
                    <ngx-select-dropdown #id
                    [config]="productConfig"
                    [options]="products$"
                    [multiple]=true
                    [(ngModel)]="productModel"
                    formControlName="product"
                    (change)="filter()"
                    ></ngx-select-dropdown>
                  </div>
                  <!-- <div class="col-lg-2 ">
                    <ngx-select-dropdown #id
                    [config]="statusConfig"
                    [options]="selectList"
                    formControlName="status"
                    (change)="filter()"
                    ></ngx-select-dropdown>
                  </div> -->
                  <div class="col-lg-2 ">
                    <!-- <input type="text" name="daterange" id="fini"  formControlName="daterange"    placeholder="date range" readonly> -->
                    <input type="text" class="form-control" formControlName="daterange" placeholder="Daterange" autocomplete="off" id="fini">
                  </div>
                </div>
                <div class="col-lg-12">
                  <button class="btn btn-primary waves-effect waves-classic down-link" [disabled]="total$==0 ? true : false"> <i class="site-menu-icon md-download" ></i>Download Report</button>
                  <a href="javascript::void(0)" style="display: none;"  #downloadZipLink class="btn btn-primary ml-auto download_template"  ><i class="fa fa-download" aria-hidden="true"></i>Download Report</a>
                </div>
              </form>
            </div>
          </div>
          <!-- <div class="order_tabs">
            <div class="row"> -->
              <!-- <div class="col-sm-12">
                <div class="order-head">Order Management</div>
              </div> -->
              <!-- <div class="col-sm-12">
                <div class="order_tabs">
                  <div class="tab-content">
                    <div class="tab-pane active" >
                      <div class="order_list">
                        <div class="table-responsive">
                          <table cellspacing="0" id="tb" class="table table-hover dataTable table-striped w-full" datatable [dtOptions]="dtOptions">
                            <thead>
                              <tr>
                                <th >Date</th>
                                <th >Number of Orders Received</th>
                                <th >Number of Orders Completed</th>
                                <th >Number of Orders Hold/cancelled</th>
                                <th >Number of Orders Exceptions</th>
                              </tr>
                            </thead>
                            <tbody >
                              <tr>
                                <td>06-07-2020</td>
                                <td>6</td>
                                <td>6</td>
                                <td>6</td>
                                <td>6</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- /.col -->
              <!-- </div> -->
              <!-- </div> -->
              </div><!-- /.container-fluid -->
            </section>
            <!-- /.content -->
          </div>
          <!-- /.content-wrapper -->