import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ReportService {
    constructor(private http: HttpClient) { }
    get(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/dashboard`).pipe(map(user => user));
    }

  abstractFilter(client: any, product: any,status:any,daterange:any) {

console.log(product)
var formData = {};
  formData['filter_date'] = daterange;
  formData['status'] = status;
  formData['client_id'] = client;
  formData['product_id'] = product;
  formData['download'] = 0;
// tslint:disable-next-line: max-line-length
return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/report/production-abstract?`+$.param(formData)).pipe(map(user => {

        return user;
    }));

  }

abstract_download(filter_date:string,  client_id:string,product_id:any,status:any,):  Promise<Blob> {
  var formData = {};
  formData['filter_date'] = filter_date;
  formData['status'] = status;
  formData['client_id'] = client_id;
  formData['product_id'] = product_id;
  formData['download'] = 1;

  const file =  this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/report/production-abstract?`+ $.param(formData),
  {responseType: 'blob' as 'json'}).toPromise();
  return file;
}

productionSummary(client: any, product: any,status:any,daterange:any) {

  console.log(product)
  var formData = {};
    formData['filter_date'] = daterange;
    formData['status'] = status;
    formData['client_id'] = client;
    formData['product_id'] = product;
    formData['download'] = 0;
  // tslint:disable-next-line: max-line-length
  return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/report/production-summary?`+$.param(formData)).pipe(map(user => {

          return user;
      }));

    }

    productionSummary_download(filter_date:string,  client_id:string,product_id:any,status:any,):  Promise<Blob> {
    var formData = {};
  formData['filter_date'] = filter_date;
    formData['status'] = status;
    formData['client_id'] = client_id;
    formData['product_id'] = product_id;
    formData['download'] = 1;

    const file =  this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/report/production-summary?`+ $.param(formData),
    {responseType: 'blob' as 'json'}).toPromise();
    return file;
  }

  invoice(client: any, product: any,daterange:any) {

  console.log(product)
    var formData = {};
      formData['filter_date'] = daterange;

      formData['client_id'] = client;
      formData['product_id'] = product;
      formData['download'] = 0;
    // tslint:disable-next-line: max-line-length
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/report/production-invoice?`+$.param(formData)).pipe(map(user => {

            return user;
        }));

      }

      invoice_download(filter_date:string,  client_id:string,product_id:any):  Promise<Blob> {
      var formData = {};
      formData['filter_date'] = filter_date;

      formData['client_id'] = client_id;
      formData['product_id'] = product_id;
      formData['download'] = 1;

      const file =  this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/report/production-invoice?`+ $.param(formData),
      {responseType: 'blob' as 'json'}).toPromise();
      return file;
    }
    getClients() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/client`).pipe(map(user => user));
    }
    getProducts() {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product`).pipe(map(user => user));
  }
}
