import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HomeService, ProductService } from '../_services';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

declare var jquery: any;
declare var moment: any;
declare var $: any;

declare var moment: any;
@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  // @ViewChild('gmap') gmapElement: ElementRef;
  stats$: any;
  clients$: any;
  products$: any;

  clientConfig = {
    displayKey: 'company_name', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Client', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No client found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Client', // label thats displayed in search input,
    searchOnKey: 'company_name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  productConfig = {
    displayKey: 'product_name', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Product', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No product found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Product', // label thats displayed in search input,
    searchOnKey: 'product_name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  role = localStorage.getItem(`${environment.appName}` + '_role');
  userType = localStorage.getItem(`${environment.appName}` + '_userType');
  companyName = localStorage.getItem(`${environment.appName}` + '_companyName');
  status: string;
  name: any;
  vendors: any;
  grounds: any;
  searchForm: FormGroup;
  user_type: any;
  constructor(
    private formBuilder: FormBuilder,
    private homeService: HomeService,
    private productService: ProductService,
    private http: HttpClient
  ) {
  }
  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }


  ngOnInit() {

   const that = this;



   console.log(that.companyName);
   that.searchForm = that.formBuilder.group({
      client: [],
      product: [],


    });

   this.homeService.searchFilter([], []).subscribe(data => {
      this.stats$ = data.data;
 });

   this.homeService.getClients().subscribe(data => {
        this.clients$ = data.data.product;
      });



  }



  get f() { return this.searchForm.controls; }

  // onItemSelect(event){
  //   console.log(event);
  //   const id = event.value._id;
  //   this.productService.getClientProducts(id).subscribe(data => {
  //     this.products$ = data.data.product;
  //   });
  //   console.log(this.products$);
  // }

  filter(){
    const that = this;
    const productArr = [];
    const clientArr = [];
    // console.log(event);
    // for (const client of event.value){
    //   clientArr.push(client._id);
    // }
    // var products_select= this.f.product.value;
    console.log(that.f.product.value);
    if (that.f.product.value){
 for (const product of this.f.product.value){
      productArr.push(product._id);
    }

    }

    if (that.f.client.value){
       for (const client of this.f.client.value){
      clientArr.push(client._id);

    }
       this.productService.getClientMultiProducts(clientArr).subscribe(data => {
      this.products$ = data.data.product;
      console.log('products are '+data.data.product);
    });
     }


    this.homeService.searchFilter(productArr, clientArr).subscribe(data => {
      this.stats$ = data.data;
     // console.log(this.stats$);
  //     // this.vendors = data.data.vendors;
    });
  //  console.log('products are '+this.products$);
  }







  ngAfterViewInit() {
  }




}
