<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<!-- <script src="https://nightly.datatables.net/js/jquery.dataTables.js"></script> -->
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/home">Home</a></li>
                        <li class="breadcrumb-item active">Onwer Listing Screen</li>
                    </ol>
                </div>
                
                </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <div class="">
                        
                        
                    </div>
                    
                    <div class="order_tabs"  >
                        <div class="row">
                            <div class="col-sm-12">
                                <button (click)="uploadData()" class="btn btn-primary ml-auto download_template float-right"><i class="fa fa-upload" aria-hidden="true"></i>Upload </button>
                                <br/>
                                <table cellspacing="0" id="tb" class="table table-hover dataTable table-striped w-full ">
                                    <!-- datatable [dtOptions]="dtOptions" -->
                                    <thead>
                                        <tr >
                                            <th *ngFor="let item of arrayOne(total); let i = index; " >
                                                <i class="fas fa-edit text-primary mr-1" style="cursor: pointer;"   (click)="openModal(i)"></i>
                                                <span>Select Column</span>
                                                
                                            </th>
                                            
                                            
                                        </tr>
                                    </thead>
                                    <tbody  >
                                        
                                        <tr *ngFor="let order of orders$" class="total_order">
                                            
                                            
                                            <td *ngIf="total>0" class="0"> {{order.b_0}} </td>
                                            <td *ngIf="total>1" class="2"> {{order.b_1}} </td>
                                            <td *ngIf="total>2"> {{order.b_2}} </td>
                                            <td *ngIf="total>3"> {{order.b_3}} </td>
                                            <td *ngIf="total>4"> {{order.b_4}} </td>
                                            <td *ngIf="total>5"> {{order.b_5}} </td>
                                            <td *ngIf="total>6"> {{order.b_6}} </td>
                                            <td *ngIf="total>7"> {{order.b_7}} </td>
                                            <td *ngIf="total>8"> {{order.b_8}} </td>
                                            <td *ngIf="total>9"> {{order.b_9}} </td>
                                            <td *ngIf="total>10"> {{order.b_10}}
                                            </td>
                                            <td *ngIf="total>11"> {{order.b_11}}
                                            </td>
                                            <td *ngIf="total>12"> {{order.b_12}} </td>
                                            <td *ngIf="total>13"> {{order.b_13}} </td>
                                            <td *ngIf="total>14"> {{order.b_14}} </td>
                                            <td *ngIf="total>15"> {{order.b_15}} </td>
                                            <td *ngIf="total>16"> {{order.b_16}} </td>
                                            <td *ngIf="total>17"> {{order.b_17}} </td>
                                            <td *ngIf="total>18"> {{order.b_18}} </td>
                                            <td *ngIf="total>19"> {{order.b_19}} </td>
                                            <td *ngIf="total>20"> {{order.b_20}} </td>
                                            <td *ngIf="total>21"> {{order.b_21}} </td>
                                            <td *ngIf="total>22"> {{order.b_22}} </td>
                                            <td *ngIf="total>23"> {{order.b_23}} </td>
                                            <td *ngIf="total>24"> {{order.b_24}} </td>
                                            <td *ngIf="total>25"> {{order.b_25}} </td>
                                            <td *ngIf="total>26"> {{order.b_26}} </td>
                                            <td *ngIf="total>27"> {{order.b_27}} </td>
                                            <td *ngIf="total>28"> {{order.b_28}} </td>
                                            <td *ngIf="total>29"> {{order.b_29}} </td>
                                            <td *ngIf="total>30"> {{order.b_30}} </td>
                                            
                                            <td *ngIf="total>31"> {{order.b_31}} </td>
                                            <td *ngIf="total>32"> {{order.b_32}} </td>
                                            <td *ngIf="total>33"> {{order.b_33}} </td>
                                            <td *ngIf="total>34"> {{order.b_34}} </td>
                                            <td *ngIf="total>35"> {{order.b_35}} </td>
                                            <td *ngIf="total>36"> {{order.b_36}} </td>
                                            <td *ngIf="total>37"> {{order.b_37}} </td>
                                            <td *ngIf="total>38"> {{order.b_38}} </td>
                                            <td *ngIf="total>39"> {{order.b_39}} </td>
                                            <td *ngIf="total>40"> {{order.b_40}} </td>
                                            <td *ngIf="total>40"> {{order.b_41}} </td>
                                            <td *ngIf="total>41"> {{order.b_42}} </td>
                                            <td *ngIf="total>42"> {{order.b_43}} </td>
                                            <td *ngIf="total>43"> {{order.b_43}} </td>
                                            <td *ngIf="total>44"> {{order.b_44}} </td>
                                            <td *ngIf="total>45"> {{order.b_45}} </td>
                                            <td *ngIf="total>46"> {{order.b_46}} </td>
                                            <td *ngIf="total>47"> {{order.b_47}} </td>
                                            <td *ngIf="total>48"> {{order.b_48}} </td>
                                            <td *ngIf="total>49"> {{order.b_49}} </td>
                                            <td *ngIf="total>50"> {{order.b_50}} </td>
                                            
                                            
                                            
                                        </tr>
                                    </tbody>
                                    
                                </table>
                                
                            </div>
                            <!-- /.col -->
                            
                        </div>
                    </div>
                    
                    
                    
                </div>
            </section>
            <!-- /.content -->
        </div>
        <div id="myModal" class="modal fade" role="dialog">
            <div class="modal-dialog">
                
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title mr-auto">Edit coloumn label</h6>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        
                    </div>
                    
                    <div class="modal-body">
                        <select class="form-control" #mySelect *ngIf="active_coloumns"  (change)="coloumnName(mySelect.value)">
                            <option>Select Option</option>
                            <option *ngFor="let value of coloumn_data " value="{{value}}">{{value}}</option>
                        </select>
                    </div>
                    
                    
                </div>
                
            </div>
        </div>