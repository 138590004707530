import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalService, OrderService, ClientService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';

declare var jquery: any;
declare var $: any;
declare var alertify: any;




@Component({
  selector: 'app-bulk-preview',
  templateUrl: './bulk-preview.component.html',
  styleUrls: ['./bulk-preview.component.css']
})

export class BulkPreviewComponent implements OnInit {
  @ViewChild('downloadZipLink',{static: false}) private downloadZipLink: ElementRef;
   adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  companyName = localStorage.getItem(`${environment.appName}` + '_companyName');
  apiurl=`${environment.apiUrl}`;
  api_prefix=`${environment.apiPrefix}`;

  role: any;

  clientConfig = {

    displayKey: 'client_name', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Clients', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No client found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Clients',
    padding:'15px', // label thats displayed in search input,


  selectAllBtnText: 'Select',
searchOnKey: 'client_name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  statusConfig = {

    displayKey: 'name', // if objects array passed which key to be displayed defaults to description
    search: false, // true/false for the search functionlity defaults to false,
    height: '300px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'All Status', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    // noResultsFound: 'No client found!', // text to be displayed when no items are found while searching
    // searchPlaceholder: 'Search Clients', // label thats displayed in search input,
class: 'select_status',

  selectAllBtnText: 'Select',
    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  media_domain = `${environment.media_domain}`;
  searchForm:FormGroup;
  coloumnForm:FormGroup
  previousRoute: string;
  creditForm: FormGroup;
 
  creditUser: any = [];
  changeClient = [];
  currentIndex = null;
  loading = false;
  previousUrl = '';
  submitted = false;
  selectStatus = '';
  status$ = [];
new_data:any;
coloumn_headers:any
coloumn_data: any;
  datas: any;
  bulkId:'';
  clients$: any;
  orders$: any;
  active_coloumns:any;
client_id :any;
serach_flag=false;
search:'';
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  coloumn_name:any;
  curPage = 1;
  perPage = 10;
  total=0;
  order_details: any;
  false_upload:false;
  userType:any;
       constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private orderService: OrderService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {
  }
  // get f() { return this.creditForm.controls; }
  public async reportDown(form, formWrap): Promise<void> {
     
    this.submitted = true;
     document.body.classList.add('jw-modal-open');
    this.loading = true;
    const blob = await this.orderService.download(
    this.client_id);
    let binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/ms-excel'}));
    const link = this.downloadZipLink.nativeElement;
    link.href = url;
    link.download = 'owners.xlsx';
    link.click();
    this.loading = false;
    document.body.classList.remove('jw-modal-open');
 
}
    check_col(a){
      var b = $("#" + a),
        c = $(b).find("thead"),
        d = $(b).find("tbody"),
        e = $(b).find("tbody>tr").length,
        f = [],
        g = [];
         
    $.each($(c).find("tr>th"), function(a, b,that) {
     
          f.push($(b).text());
      
       
    });
    return f;
    }

  makeJsonFromTable(a){
    var b = $("#" + a),
        c = $(b).find("thead"),
        d = $(b).find("tbody"),
        e = $(b).find("tbody>tr").length,
        f = [],
        g = [];
         
    $.each($(c).find("tr>th"), function(a, b,that) {
      
          f.push($(b).text());
        
    }),$.each($(d).find("tr"), function(a, b) {
        for (var c = {}, d = 0; d < f.length; d++) c[f[d]] = $(this).find("td").eq(d).text();
        g.push(c)
    });
    var h = {};
    return   h = g, h
  }


  uploadData(){
      const that = this

    var  f = [];
    for(var i=0;i<$('#tb').find("thead>tr>th").length;i++) {
       
      if (f.includes($('#tb').find("thead>tr>th").eq(i).text())) {  
         that.toastr.error("Duplicate column "+$('#tb').find("thead>tr>th").eq(i).text()+" found");
         return ;
      } else{
        if($('#tb').find("thead>tr>th").eq(i).text()!="Select Column"){
          f.push($('#tb').find("thead>tr>th").eq(i).text());
        }
       }
       
    };
 
   
      
 

  alertify.confirm('Update Invoice','Are you sure want to update this record', function () {
    that.loading = true;
    that.orderService.updateToOrder( JSON.stringify(that.makeJsonFromTable('tb'))).subscribe(data=>{
    that.loading = false;
        if(data.status.code==0 ){
          that.toastr.success(data.status.message);
          setTimeout(() => {
             location.href='/manage-owner';
          }, 1000);
        }else if(data.status.code==5){
           that.toastr.error(data.status.message);
          setTimeout(() => {
             location.href='/duplicate-owner';
          }, 1000);
        }else{
           that.toastr.error(data.status.message)
        }
      })
  }
  , function() {  
    that.toastr.error('Update action cancelled');

  });
  
  
  

  }
  arrayOne(n: number): any[] {
    return Array(n);
  }
  ngOnInit() {

    this.role = localStorage.getItem(`${environment.appName}` + '_role');

    this.userType = localStorage.getItem(`${environment.appName}` + '_userType');

 this.route.params.subscribe(params => {
      this.bulkId = params.id;
    });
    
    const that = this;
    that.searchForm = that.formBuilder.group({
      client: [''],
     search:[''],


    });
 
    if(that.orders$){
      console.log(that.orders$.length)
    }
    
    that.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: that.perPage,

      serverSide: true,
      processing: true,
      searching: false,
      ordering: true,
      order: [],
      columnDefs: [ {
        "targets": [],
        "orderable": false
        } ],
      lengthChange: false,


     
    };

    that.clientService.gettempAllClients(this.bulkId).subscribe( resp => {
      that.orders$ = resp.data.temp;
       that.total = resp.data.total;
      this.new_data = [];

 
  this.active_coloumns = resp.data.active;
  this.coloumn_headers = [];
  this.coloumn_data = [];
   if(this.active_coloumns && this.active_coloumns.checked_items){


    for(let value of this.active_coloumns.checked_items){
      
      if(value=='serial_no'){
        this.coloumn_headers.push('Serial No')
        this.coloumn_data.push('Serial No')
      }
       
      if(value=='driveline_id'){
        this.coloumn_headers.push('Driveline id')
        this.coloumn_data.push('Driveline id')
      }
      if(value=='manufacturing_date'){
        this.coloumn_headers.push('Manufacturing Date')
        this.coloumn_data.push('Manufacturing Date')
      }
      if(value=='sold_to'){
        this.coloumn_headers.push('Sold To')
        this.coloumn_data.push('Sold To')
      }
      if(value=='shipped_to'){
        this.coloumn_headers.push('Shipped To')
        this.coloumn_data.push('Shipped To')
      }
      if(value=='product_category'){
        this.coloumn_headers.push('Product Category')
        this.coloumn_data.push('Product Category')
        
      }
      if(value=='product_family'){
        this.coloumn_headers.push('Product Family')
        this.coloumn_data.push('Product Family')
      }

      if(value=='technical_platform'){
        this.coloumn_headers.push('Technical Platform')
        this.coloumn_data.push('Technical Platform')
      }
      if(value=='application'){
        this.coloumn_headers.push('Application')
        this.coloumn_data.push('Application')
      }
      if(value=='product_name'){
        this.coloumn_headers.push('Product Name')
        this.coloumn_data.push('Product Name')
      }
      if(value=='ship_date'){
        this.coloumn_headers.push('Ship Date')
        this.coloumn_data.push('Ship Date')
      }
      if(value=='manufacturing_plant'){
        this.coloumn_headers.push('Manufacturing Plant')
        this.coloumn_data.push('Manufacturing Plant')
      }
      if(value=='region'){
        this.coloumn_headers.push('Region')
        this.coloumn_data.push('Region')
      }
      if(value=='country'){
        this.coloumn_headers.push('Country')
        this.coloumn_data.push('Country')
      }
      if(value=='product_designation'){
        this.coloumn_headers.push('Product Designation')
        this.coloumn_data.push('Product Designation')
      }

      if(value=='sales_order_no'){
        this.coloumn_headers.push('Sales Order No')
        this.coloumn_data.push('Sales Order No')
      }

      if(value=='sales_order_type'){
        this.coloumn_headers.push('Sales Order Type')
        this.coloumn_data.push('Sales Order Type')
      }

      if(value=='part_no_on_product'){
        this.coloumn_headers.push('Part No On Product')
        this.coloumn_data.push('Part No On Product')
      }

      if(value=='scc_individual_status'){
        this.coloumn_headers.push('Sales Order No')
        this.coloumn_data.push('Sales Order No')
      }

      if(value=='sales_order_type'){
        this.coloumn_headers.push('SCC Individual Status')
        this.coloumn_data.push('SCC Individual Status')
      }

      if(value=='exported_from_the_factory'){
        this.coloumn_headers.push('Exported From The Factory')
        this.coloumn_data.push('Exported From The Factory')
      }

      if(value=='reason'){
        this.coloumn_headers.push('Reason')
        this.coloumn_data.push('Reason')
      }
      
     
    }
     
   }
  
   console.log(this.coloumn_headers)
    });
      

  }
 
 
openModal(i){

  $('#myModal').modal('show');
 
this.coloumn_name = i


}


updateColoumn(){
const that =this
that.orderService.updateColoumn(that.coloumn_headers).subscribe(data=>{
  if(data.status.code==0){
    
    that.toastr.success(data.status.message)
  }else{
    that.toastr.error(data.status.message)
  }
  $('#myModal').modal('hide');
})


}

coloumnName(value:string){
   $( "th:eq("+this.coloumn_name+") span").html(value);
 
}

 searchFilter(data){
 
   this.orderService.searchClient(this.client_id,data).subscribe(data=>{
    this.orders$ = data.data.orders.data;
  this.total = data.data.orders.total
   
  })

 }
  // tslint:disable-next-line: use-lifecycle-interface
 
  generate(event){
    const that = this
    alertify.confirm('Generate Invoice', 'Are you sure want to generate invoice ?', function() {
    that.orderService.generateOrder(that.client_id).subscribe(data => {
      console.log(data);
      if(data.status.code==1){
      that.loading = false;
      document.body.classList.remove('jw-modal-open');
      that.toastr.error(data.message);
      return;
     }else{
      that.toastr.success("Invoice generated");
      location.href='/invoices';
     }
   }, error => { console.error(error);
      that.loading = false;
      document.body.classList.remove('jw-modal-open');

    });
  }, function() {

    that.toastr.error('Genrate invoice action cancelled');

  });
  }


 


  public async generateXls(invoice_id): Promise<void> {
    const blob = await this.orderService.downloadInvoice(invoice_id);
    let binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
    // const url = window.URL.createObjectURL(blob);

    const link = this.downloadZipLink.nativeElement;
    link.href = url;
    link.download = 'invoice_report.xlsx';
    link.click();
    
  }


  // tslint:disable-next-line: semicolon
  // tslint:disable-next-line: use-lifecycle-interface


}
