import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { OrderService, ClientService } from '../_services';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { Toast, ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

declare var jquery: any;
declare var $: any;
declare var alertify: any;
declare var moment: any;



@Component({
  selector: 'app-manage-details',
  templateUrl: './manage-details.component.html',
  styleUrls: ['./manage-details.component.css']
})
export class ManageDetailsComponent implements OnInit,AfterViewInit {
	@ViewChild('downloadZipLinks', { static: false }) private downloadZipLink: ElementRef;
	invoiceForm:FormGroup;
	loading = false;
	apiurl = `${environment.apiUrl}`;
	api_prefix = `${environment.apiPrefix}`;
	dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
	length = null;
	invoice_date:any;
	invoice_number:any;
	payment_date:any;
	payment_method:any;
	amount_due:any;
	funds_forward:any;
	amount_forward:any;
	cancelButton = false;
	rowNumber: any;
	perPage = 10;
	clients$ : any;
	curPage=1;
	client_id:any;

	clientConfig = {

		displayKey: 'client_name', // if objects array passed which key to be displayed defaults to description
		search: true, // true/false for the search functionlity defaults to false,
		height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
		placeholder: 'Select Clients', // text to be displayed when no item is selected defaults to Select,
		customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
		// moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
		noResultsFound: 'No client found!', // text to be displayed when no items are found while searching
		searchPlaceholder: 'Search Clients',
		padding:'15px', // label thats displayed in search input,
	
	
	  selectAllBtnText: 'Select',
	searchOnKey: 'client_name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
	
		// key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
	  };
	constructor(
		private orderService: OrderService,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private clientService: ClientService) { }

	

	
	async generateXlss(invoice_id): Promise<void> {
		
			console.error(invoice_id);
			const blob = await this.orderService.downloadInvoice(invoice_id);
			let binaryData = [];
			binaryData.push(blob);
			const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
			// const url = window.URL.createObjectURL(blob);

			const link = this.downloadZipLink.nativeElement;
			link.href = url;
			link.download = 'invoice_report.xlsx';
			link.click();
	}


	/*upload_invoice(id){
		this.orderService.uploadInvoice(id).subscribe(data => { console.log(data); location.reload(); }, error => { console.error(error); });
	}*/


	invoices$: any;
  ngOnInit() {

	
    const that = this
    that.dtOptions = {
      pagingType: 'Invoice Number',
      processing: true,
      pageLength: this.perPage,
      // lengthChange: false,
      language: {
        searchPlaceholder: "Product Name"
      },
      searching: true,
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 1
      }],
      ordering: false,
    };
	
	  
	  that.orderService.getInvoices(that.client_id).subscribe(data => { 
		  console.log(data); 
		  that.invoices$ = data.data; 
		 
		
		that.length = data.data.length;
		
	  }, error => { console.error(error); });
	  
	  that.clientService.getAllClients().subscribe( resp => {
		that.clients$ = resp.data.clients;
		console.log(that.clients$)
	  });
  
	
  }

  editInvoice(invoice,index){
	this.invoiceForm = this.formBuilder.group({
		index:index,
		_id: [{ value: null, disabled: true }],
		invoice_date: ['', [Validators.required]],
	//	invoice_number: ['', [Validators.required]],
		payment_date: ['', [Validators.required]],
		payment_method: ['', [Validators.required]],
		
		funds_forward: ['', [Validators.required]],
		amount_forward: ['', [Validators.required]],
		amount_paid: ['0', [Validators.required]],
	

	  });
	  
	  this.cancelButton = true;
	  this.rowNumber = index;
  setTimeout(() => {
	   $('.payment_date').datepicker({
    autoclose: true,  
    format: "mm/dd/yyyy"
});

    }, 500);
	  	  this.invoiceForm.setValue({
		  _id:invoice._id,
		  index:index,
		  invoice_date:invoice.invoice_date,
		  //invoice_number:invoice.invoice_number,
		  payment_date:invoice.payment_date,
		  payment_method:invoice.payment_method,
		  amount_paid:invoice.amount_paid,
		  funds_forward:invoice.funds_forward,
		  amount_forward:invoice.amount_forward,



	  });

 
  }

  selectClients(data){
  	const that = this
  	console.log(data);
  	if(data.value){
  		that.client_id = data.value._id;
  	console.log(data.value._id)
  	 that.orderService.getInvoices(that.client_id).subscribe(data => { 
		  console.log(data); 
		  that.invoices$ = data.data; 
		 
		
		that.length = data.data.length;
		
	  }, error => { console.error(error); });
	  
	  that.clientService.getAllClients().subscribe( resp => {
		that.clients$ = resp.data.clients;
		console.log(that.clients$)
	  });
  	}else{
  		 that.invoices$ = []; 
		 
		
		that.length = '';
  	}
  	
  }
  get f() { return this.invoiceForm.controls; }
  invoiceUpdate(invoice){

	  const that = this
	alertify.confirm('Update Invoice','Are you sure want to update this record', function () {
	
		that.orderService.invoiceUpdate(
			invoice._id,
			that.f.invoice_date.value,
			"",
			that.f.payment_date.value,
			that.f.payment_method.value,
			that.f.amount_paid.value,
			that.f.funds_forward.value,
			that.f.amount_forward.value,
			).subscribe(data=>{
				if(data.status.code==0){
					that.toastr.success('Updated successfully')
					that.cancelButton = false;
				//	var table = $('#tb').DataTable();
                  //   table.draw(); 
				 	location.reload();
				}else{
					that.toastr.error(data.status.message)
				}
			})
	}
	, function() { // alertify.error('Delete action cancelled')
	  that.toastr.error('Update action cancelled');

	});
  }

  ngAfterViewInit() {
  	 var that = this;
  	   
 $(document).ready(function() {
    // This WILL work because we are listening on the 'document', 
    // for a click on an element with an ID of #test-element
    $(document).on("change",".payment_date",function() {
       that.invoiceForm.patchValue({ 'payment_date': $(this).val() });


    });

    
        });
}

}
