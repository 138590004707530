import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }

    login(email: string, password: string) {

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/login`, { email: email, password: password }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user.status.code == 0) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                if(user.user.company_name){

                    localStorage.setItem(`${environment.appName}` + '_companyName', user.user.company_name);
                }else{
                    localStorage.setItem(`${environment.appName}` + '_companyName', '');
                }
                localStorage.setItem(`${environment.appName}` + '_adminName', user.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.user.status);
                localStorage.setItem(`${environment.appName}` + '_unit_price', user.user.unit_price);
                if(user.user.user_type){
                  localStorage.setItem(`${environment.appName}` + '_userType', user.user.user_type);
                  console.log(localStorage.setItem(`${environment.appName}` + '_userType', user.user.user_type))
                }else{
                  localStorage.setItem(`${environment.appName}` + '_userType', '');
                }

            }


            return user;
        }));

    }




    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(`${environment.appName}` + 'vendor_user');
        localStorage.removeItem(`${environment.appName}` + '_role');
        localStorage.removeItem(`${environment.appName}` + '_user');
        localStorage.removeItem(`${environment.appName}` + '_adminName');
        localStorage.removeItem(`${environment.appName}` + '_adminId');
        localStorage.removeItem(`${environment.appName}` + '_status');
    }
}
