<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading><div class="login-box">
  <div class="login-logo">
    <a href="home.html"><img src="assets/images/logo.png" class="img-responsive" alt="logo"></a>
  </div>
  <!-- /.login-logo -->
  <div class="card">
    <div class="card-body login-card-body">
      <p class="login-box-msg">Welcome to TITLE HUNTER, please enter the password for future use</p>

      <form [formGroup]="resetForm" #myForm="ngForm" (ngSubmit)="onSubmit()"
                class="" >
        <div class="input-group mb-3">
          <input type="password" class="form-control" placeholder="Password" formControlName="password"
          [ngClass]="{ 'is-invalid': (f.password.touched || submitted) && f.password.errors }">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
          <div *ngIf="(f.password.touched || submitted) && f.password.errors?.required" class="invalid-feedback">
            <div *ngIf="f.password.errors?.required"> Password is required</div>
        </div>
        <div *ngIf="(f.passwords['controls'].password.touched || submitted) && f.passwords['controls'].password.errors?.maxlength" class="invalid-feedback">
            <div *ngIf="f.passwords['controls'].password.errors?.maxlength">Maximum 100 characters are allowed</div>
        </div>
        </div>
        <div class="input-group mb-3">
          <input type="password" class="form-control" placeholder="Confirm Password"  formControlName="confirm_password"
          [ngClass]="{ 'is-invalid': (f.confirm_password.touched || submitted) && f.confirm_password.errors }">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
          <div *ngIf="(f.confirm_password.touched || submitted) && f.confirm_password.errors?.required " class="invalid-feedback">
            <div *ngIf="f.confirm_password.errors?.required">
            Confirm Password is required</div>
        </div>
        <div *ngIf="(f.passwords['controls'].confirm_password.touched || submitted) && f.passwords['controls'].confirm_password.errors?.maxlength " class="invalid-feedback">
            <div *ngIf="f.passwords['controls'].confirm_password.errors?.maxlength">
            Maximum 100 characters are allowed</div>
        </div>
        <div *ngIf="(f.passwords['controls'].confirm_password.touched || submitted) && (f.passwords.errors?.areEqual)" class="invalid-feedback">Passwords do not match
        </div>

        </div>
        <div class="row">
          <div class="col-12">
            <button type="submit" class="btn btn-primary btn-block">Change password</button>
          </div>
          <!-- /.col -->
        </div>
      </form>

      <p class="mt-3 mb-1">
        <a href="/login">Login</a>
      </p>
    </div>
    <!-- /.login-card-body -->
  </div>
</div>
