import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class BannersService {
    constructor(private http: HttpClient) { }

    save(location: string, status: string, image: File) {
        const formData: FormData = new FormData();
        formData.append('large_image', image, image.name);
        status = status ? '1' : '0';
        formData.append('status', status);
        formData.append('link', location);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/banners`, formData).pipe(map(user => {
            return user;
        }));

    }

    update(id: string, location: string, status: string, image: File) {
        const formData: FormData = new FormData();
        if (image) {
            formData.append('large_image', image, image.name);
        }
        status = status ? '1' : '0';
        formData.append('status', status);
        formData.append('link', location);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/banners/` + id, formData).pipe(map(user => {
            return user;
        }));

    }
    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/banners/` + id).pipe(map(user => {
            return user;
        }));

    }
    getBanners(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/banners`).pipe(map(user => user));

    }
}