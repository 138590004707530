<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="login-box">
  <div class="login-logo">
    <a href="/forgot-password"><img src="assets/images/logo.png" class="img-responsive" alt="logo"></a>
  </div>
  <!-- /.login-logo -->
  <div class="card">
    <div class="card-body login-card-body">
      <p class="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>

      <form [formGroup]="passwordForm" #myForm="ngForm" (ngSubmit)="onSubmit(myForm)"
      class="" >
        <div class="input-group mb-3">
          <input type="email" class="form-control" placeholder="Email"  formControlName="email"
          [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors }">
          <div class="input-group-append">
              <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
              </div>
          </div>
          <div *ngIf="(f.email.touched || submitted) && f.email.errors?.required"
              class="invalid-feedback">
              <div *ngIf="f.email.errors?.required">Email is required</div>
          </div>

          <div *ngIf="(f.email.touched || submitted) && f.email.errors?.email"
              class="invalid-feedback">
              <div *ngIf="f.email.errors?.email"  >Please enter
              valid email</div>
          </div>
          <div *ngIf="(f.email.touched || submitted) && f.email.errors?.maxlength"
              class="invalid-feedback">
              <div *ngIf="f.email.errors?.maxlength">Maximum 50 characters are allowed
              </div>
          </div>

        </div>
        <div class="row">
          <div class="col-12">
            <button type="submit" class="btn btn-primary btn-block">Request new password</button>
          </div>
          <!-- /.col -->
        </div>
      </form>

      <p class="mt-3 mb-1">
        <a href="/login">Login</a>
      </p>
      <!-- <p class="mb-0">
        <a href="register.html" class="text-center">Register a new membership</a>
      </p> -->
    </div>
    <!-- /.login-card-body -->
  </div>
</div>
