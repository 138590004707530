import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class HomeService {
    constructor(private http: HttpClient) { }
    get(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/dashboard`).pipe(map(user => user));
    }

  searchFilter(product: any, client: any) {

console.log(product)
var products = product

return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/dashboard`, { product_id: product, client_id: client }).pipe(map(user => {
        return user;
    }));

}
    getClients() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/client`).pipe(map(user => user));
    }
    getProducts() {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product`).pipe(map(user => user));
  }

}
