import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalService, OrderService, ClientService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';

declare var jquery: any;
declare var $: any;
declare var alertify: any;



@Component({
  selector: 'app-manage-orders',
  templateUrl: './duplicate.component.html',
  styleUrls: ['./duplicate.component.css']
})

export class DuplicateComponent implements OnInit {
  @ViewChild('downloadZipLink',{static: false}) private downloadZipLink: ElementRef;
   adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  companyName = localStorage.getItem(`${environment.appName}` + '_companyName');
  apiurl=`${environment.apiUrl}`;
  api_prefix=`${environment.apiPrefix}`;

  role: any;
    datatableParams:any;
  clientConfig = {

    displayKey: 'client_name', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Clients', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No client found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Clients',
    padding:'15px', // label thats displayed in search input,


  selectAllBtnText: 'Select',
searchOnKey: 'client_name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  statusConfig = {

    displayKey: 'name', // if objects array passed which key to be displayed defaults to description
    search: false, // true/false for the search functionlity defaults to false,
    height: '300px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'All Status', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    // noResultsFound: 'No client found!', // text to be displayed when no items are found while searching
    // searchPlaceholder: 'Search Clients', // label thats displayed in search input,
class: 'select_status',

  selectAllBtnText: 'Select',
    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  media_domain = `${environment.media_domain}`;
  searchForm:FormGroup;
  previousRoute: string;
  creditForm: FormGroup;
 
  creditUser: any = [];
  changeClient = [];
  currentIndex = null;
  loading = false;
  previousUrl = '';
  submitted = false;
  selectStatus = '';
  status$ = [];
  datas: any;
  clients$: any;
  orders$: any;
  active_coloumns:any;
client_id :'';
serach_flag=false;
search:'';
dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();  
  curPage = 1;
  perPage = 10;
  total=0;
  order_details: any;
  userType:any;
       constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private orderService: OrderService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {
  }
   
  public async reportDown(form, formWrap): Promise<void> {
    this.submitted = true;
    document.body.classList.add('jw-modal-open');
    this.loading = true;
    const blob = await this.orderService.download(
    this.client_id );
    let binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/ms-excel'}));
    const link = this.downloadZipLink.nativeElement;
    link.href = url;
    link.download = 'owners.xlsx';
    link.click();
    this.loading = false;
    document.body.classList.remove('jw-modal-open');
 
}
  ngOnInit() {

    this.role = localStorage.getItem(`${environment.appName}` + '_role');
    this.userType = localStorage.getItem(`${environment.appName}` + '_userType');
    const that = this;
    that.searchForm = that.formBuilder.group({
      client: [''],
     search:[''],
    });
    
this.active_coloumns={
           "serial_no" : 1, 
    "driveline_id" : 1, 
    "manufacturing_date" : 1, 
    "shipped_to" : 1, 
    "product_category" : 1, 
    "product_family" : 1, 
    "technical_platform" : 1, 
    "product_name" : 1, 
    "ship_date" : 1, 
    "manufacturing_plant" : 1, 
    "region" : 1, 
    "product_desgnation" : 1, 
    "sales_order_type" : 1, 
    "scc_status" : 1, 
    "exported_from_the_factory" : 1, 
    "reason" : 1, 
    "country" : 1, 
    "part_no_on_product" : 1,

    "sold_to":1,
    "application":1,
    "sales_order_no":1,

};
     //console.log(this.active_coloumns.serial_no);

      
    that.clientService.getAllClients().subscribe( resp => {
      that.clients$ = resp.data.clients;
     
    });
 
  this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: that.perPage,
        order:[],
        searching:false,
        serverSide: true,
    
        language: {
            searchPlaceholder: "Search"
        } , 
        ajax: (dataTablesParameters: any, callback) => {
          dataTablesParameters['search'] = that.f.search.value;
          that.http
            .post<any>(
                `${environment.apiUrl}${environment.apiPrefix}/order/duplicate/`+that.client_id,
             dataTablesParameters, {}
            ).subscribe(resp => {
             that.orders$= resp.data.orders;
              that.curPage = resp.data.cur_page;
               that.active_coloumns = resp.data.active;

              callback({
                recordsTotal: resp.data.recordsTotal,
                recordsFiltered: resp.data.recordsFiltered,
                data: []
              });
            });
        }
      };
 
  
   
 
  }
   get f() { return this.searchForm.controls; } 
selectClients(data){
  this.serach_flag = true;
   
  this.client_id = data.value._id;
  
 
   var table = $('#tb').DataTable();
    table.draw();
  
}

searchFilter(data){
    var table = $('#tb').DataTable();
    table.draw();
}

  
  generate(event){
    const that = this
    alertify.confirm('Generate Invoice', 'Are you sure want to generate invoice ?', function() {
    that.orderService.generateOrder(that.client_id).subscribe(data => {
      console.log(data);
      if(data.status.code==1){
      that.loading = false;
      document.body.classList.remove('jw-modal-open');
      that.toastr.error(data.message);
      return;
     }else{
      that.toastr.success('Invoice generated');
      location.href='/invoices';
     }
   }, error => { console.error(error);
      that.loading = false;
      document.body.classList.remove('jw-modal-open');

    });
  }, function() {

    that.toastr.error('Genrate invoice action cancelled');

  });
  }



  

  public async generateXls(invoice_id): Promise<void> {
    const blob = await this.orderService.downloadInvoice(invoice_id);
    let binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/ms-excel' }));
    // const url = window.URL.createObjectURL(blob);

    const link = this.downloadZipLink.nativeElement;
    link.href = url;
    link.download = 'invoice_report.xlsx';
    link.click();
    
  }


  // tslint:disable-next-line: semicolon
  // tslint:disable-next-line: use-lifecycle-interface


}
