import { Component, OnInit, OnDestroy, AfterViewInit,ElementRef, ViewChild } from '@angular/core';
import { FileHandle } from '../dnd.directive';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { BannersService, AlertService, Countryservice, ClientService, ProductService, OrderService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { typeWithParameters } from '@angular/compiler/src/render3/util';


declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.css']
})
export class CreateOrderComponent implements OnInit {
  @ViewChild('downloadZipLink',{static: false}) private downloadZipLink: ElementRef;
  
  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  companyName = localStorage.getItem(`${environment.appName}` + '_companyName');

  role: any;
  clientConfig = {

    displayKey: 'client_name', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '300px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select client', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No client found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Client', // label thats displayed in search input,


  selectAllBtnText: 'Select',
searchOnKey: 'client_name'
    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  productConfig = {

    displayKey: 'product_name', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Product', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No product found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Product', // label thats displayed in search input,


  selectAllBtnText: 'Select',
searchOnKey: 'product_name'

    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  countryConfig = {

    displayKey: 'states', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select State', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No state found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search state', // label thats displayed in search input,
    searchOnKey:"states",

  selectAllBtnText: 'Select',
    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  

  stateConfig = {

    displayKey: 'county', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    // tslint:disable-next-line: max-line-length
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select County', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No county found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search County', // label thats displayed in search input,

  selectAllBtnText: 'Select',
    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  imageExist = false;
  orderFile: any;
  fileToUpload: File = null;
  countries$: any;
  states$: any;
  clients$: any;
  products$: any;
  client_id:any;
  files: FileHandle[] = [];
  filename: any;
  countryModel: any;
  orderForm: FormGroup;
  searchForm:FormGroup;
  bulkOrderForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  cancelButton: boolean;
  active_items:any;
  widthLimit = `${environment.IdWidth}`;
  heightLimit = `${environment.IdHeight}`;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private countryService: Countryservice,
    private clientService: ClientService,
    private productService: ProductService,
    private orderService: OrderService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
  ) { }

  public async reportDown(form, formWrap): Promise<void> {
    console.log('here')
    this.submitted = true;
     document.body.classList.add('jw-modal-open');
    this.loading = true;




    const blob = await this.orderService.download_template(
this.client_id._id




        );
    let binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/ms-excel'}));
// const url = window.URL.createObjectURL(blob);

    const link = this.downloadZipLink.nativeElement;
    link.href = url;
    link.download = 'owners.xlsx';
    link.click();

// window.URL.revokeObjectURL(url);
    this.loading = false;
    document.body.classList.remove('jw-modal-open');
// link.stop();
}

  ngOnInit(): void {
    this.role = localStorage.getItem(`${environment.appName}` + '_role');

    this.orderForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      serial_no: ['', ],
        year: ['',  ],
          month: ['',  ],
      driveline_id: ['', ],
      manufacturing_date: ['', ],
      sold_to: ['', ],
      shipped_to: ['', ],
      product_category: ['', ],
      product_family: ['', ],
      technical_platform: ['', ],
      application: ['', ],
      product_name: ['', ],
      ship_date: ['', ],
      manufacturing_plant: ['', ],
      region: ['', ],
      country: ['', ],
      product_designation: ['', ],
      sales_order_no: ['', ],
      sales_order_type: ['', ],
      part_no_on_product: ['', ],
      scc_individual_status: ['', ],
      exported_from_the_factory: ['', ],
      reason: ['', ],
      client: ['', [Validators.required,]],



    });
    this.bulkOrderForm = this.formBuilder.group({
        orderFile: ['', [Validators.required]],
        client:['',[Validators.required]],
        year: ['', ],
          month: ['',  ],
   });


    this.clientService.getAllClients().subscribe(res => {
      this.clients$ = res.data.clients;
      
    });

    this.countryService.getCountries().subscribe(data => {
      this.countries$ = data.data.countries;
      console.log(this.countries$);
      console.log(data.data.countries);
     

    });
   
  }

  onItemSelect(event){
   
    const id = event.value._id;
    this.client_id =  event.value
    console.log(this.client_id)
    if(this.client_id.serial_no){
      this.orderForm.controls['serial_no'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['serial_no'].updateValueAndValidity();
    }

    if(this.client_id.driveline_id){
      this.orderForm.controls['driveline_id'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['driveline_id'].updateValueAndValidity();
    }

    if(this.client_id.manufacturing_date){
      this.orderForm.controls['manufacturing_date'].setValidators([Validators.required,]);
      this.orderForm.controls['manufacturing_date'].updateValueAndValidity();
    }

    if(this.client_id.sold_to){
      this.orderForm.controls['sold_to'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['sold_to'].updateValueAndValidity();
    }

    if(this.client_id.shipped_to){
      this.orderForm.controls['shipped_to'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['shipped_to'].updateValueAndValidity();
    }

    if(this.client_id.product_category){
      this.orderForm.controls['product_category'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['product_category'].updateValueAndValidity();
    }
  
    if(this.client_id.product_family){
      this.orderForm.controls['product_family'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['product_family'].updateValueAndValidity();
    }

    if(this.client_id.technical_platform){
      this.orderForm.controls['technical_platform'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['technical_platform'].updateValueAndValidity();
    }

    if(this.client_id.application){
      this.orderForm.controls['application'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['application'].updateValueAndValidity();
    }

    if(this.client_id.product_name){
      this.orderForm.controls['product_name'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['product_name'].updateValueAndValidity();
    }

    if(this.client_id.ship_date){
      this.orderForm.controls['ship_date'].setValidators([Validators.required,]);
      this.orderForm.controls['ship_date'].updateValueAndValidity();
    }

    if(this.client_id.manufacturing_plant){
      this.orderForm.controls['manufacturing_plant'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['manufacturing_plant'].updateValueAndValidity();
    }

    if(this.client_id.region){
      this.orderForm.controls['region'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['region'].updateValueAndValidity();
    }

    if(this.client_id.country){
      this.orderForm.controls['country'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['country'].updateValueAndValidity();
    }

    if(this.client_id.product_desgnation){
      this.orderForm.controls['product_designation'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['product_designation'].updateValueAndValidity();
    }

    if(this.client_id.sales_order_no){
      this.orderForm.controls['sales_order_no'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['sales_order_no'].updateValueAndValidity();
    }

    if(this.client_id.sales_order_type){
      this.orderForm.controls['sales_order_type'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['sales_order_type'].updateValueAndValidity();
    }

    if(this.client_id.part_no_on_product){
      this.orderForm.controls['part_no_on_product'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['part_no_on_product'].updateValueAndValidity();
    }
    if(this.client_id.scc_individual_status){
      this.orderForm.controls['scc_individual_status'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['scc_individual_status'].updateValueAndValidity();
    }

    if(this.client_id.exported_from_the_factory){
      this.orderForm.controls['exported_from_the_factory'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['exported_from_the_factory'].updateValueAndValidity();
    }

    if(this.client_id.reason){
      this.orderForm.controls['reason'].setValidators([Validators.required,Validators.maxLength(500)]);
      this.orderForm.controls['reason'].updateValueAndValidity();
    }
   


  }
   onCountry(event){
    this.states$ = event.value.county;
    // console.log(event.value.country);
    this.countryModel = event.value.states;
    // console.log(event.value.states);
    }






    handleFileInput(event: any, files: FileList) {
      const that = this;
      const reader = new FileReader();
      console.log(files);
      reader.onload = function(e) {
        const img = new Image;
        img.onload = function() {
          // tslint:disable-next-line: radix

          if (files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            that.toastr.error('Please upload excel sheet only');
            // alertify.error('Please upload image of '+imgWidth+'*'+imgHeight);
            that.bulkOrderForm.get('orderFile').setValue('');

            return false;
          } else {

            that.fileToUpload = files.item(0);

  // alert(that.fileToUpload);
            that.imageExist = true;
          }
        };
        that.orderFile = reader.result;
        that.files = [];
        that.bulkOrderForm.get('orderFile').setValue(files.item(0));
        img.src = reader.result as string;
      };

      reader.readAsDataURL(files.item(0));
      that.filename = files.item(0).name;
    }
  get f() { return this.orderForm.controls; }
  get b() { return this.bulkOrderForm.controls; }


bulkSubmit(form, formWrap){
  const that = this;
  that.submitted = true;
  const id = form.getRawValue()._id;
  const index = form.getRawValue().index;


         // stop here if form is invalid
  if (that.bulkOrderForm.invalid) {
           return;
         }

  alertify.confirm('Bulk Order', 'Are you sure want to create the bulk Owner ?', function() {
       that.loading = true;
    that.orderService.saveBulkOrder(
     that.client_id,
      that.b.orderFile.value,
      that.b.year.value,
      that.b.month.value,

       )
      .subscribe(
        data => {
          if (data.status.code == 0) {
            that.submitted = false;
            document.body.classList.remove('jw-modal-open');
            that.loading = false;
            that.cancelButton = false;

            if (data.status.message){
              that.toastr.success(data.status.message);
            }
            // location.reload();
location.href = '/bulk-preview/'+data.data.bulkId;


            formWrap.reset();


            // this.router.navigate(['/banners']);
            // location.href = '/manage-owners';

          } else {
            document.body.classList.remove('jw-modal-open');
            that.loading = false;

            that.toastr.error(data.status.message);
            // alertify.error(data.status.message)
            // this.alertService.error(data.status.message);
          }
        },
        error => {
          if(error=='Unknown Error'){
            formWrap.reset();
            location.reload();

          } else {
            that.toastr.error(error);
          }


          // alertify.error(error)
          // this.alertService.error(error);
          document.body.classList.remove('jw-modal-open');
          that.loading = false;
        });
         }, function() {

    that.toastr.error('Create owner action cancelled');

  });
  }
  onSubmit(form, formWrap) {
    console.log(this.orderForm);
 const that = this;
 that.submitted = true;
 const id = form.getRawValue()._id;
 const index = form.getRawValue().index;


        // stop here if form is invalid
 if (that.orderForm.invalid) {
     return;
    }


 if (id != null) {
          document.body.classList.add('jw-modal-open');
          that.loading = true;
          that.orderService.update(
            id,
            that.f.serial_no.value,
            that.f.driveline_id.value,
            that.f.manufacturing_date.value,
            that.f.sold_to.value,
            that.f.shipped_to.value,
            that.f.product_category.value,
            that.f.product_family.value,
            that.f.technical_platform.value,
            that.f.application.value,
            that.f.product_name.value,
            that.f.ship_date.value,
            that.f.manufacturing_plant.value,
            that.f.region.value,
            that.f.country.value,
            that.f.product_designation.value,
            that.f.sales_order_no.value,
            that.f.sales_order_type.value,
            that.f.part_no_on_product.value,
            that.f.scc_individual_status.value,
            that.f.exported_from_the_factory.value,
            that.f.reason.value,
            that.client_id,
            that.f.year.value,
            that.f.month.value
             )
            .subscribe(
              data => {
                if (data.status.code == 0) {
                  this.submitted = false;
                  document.body.classList.remove('jw-modal-open');
                  this.loading = false;
                  this.cancelButton = false;
                  this.orderForm.setValue({
                    index: null,
                    _id: null,
                  product_name: '',
                  });
                  formWrap.reset();



                  location.href = '/manage-owners';

                } else {
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.toastr.error(data.status.message);
                 
                }
              },
              error => {
                that.toastr.error(error);

                document.body.classList.remove('jw-modal-open');
                that.loading = false;
              });
        } else {

        alertify.confirm('Create Order', 'Are you sure want to create the owner ?', function() {
          document.body.classList.add('jw-modal-open');
          that.loading = true;

          that.orderService.save(
          that.f.serial_no.value,
          that.f.driveline_id.value,
          that.f.manufacturing_date.value,
          that.f.sold_to.value,
          that.f.shipped_to.value,
          that.f.product_category.value,
          that.f.product_family.value,
          that.f.technical_platform.value,
          that.f.application.value,
          that.f.product_name.value,
          that.f.ship_date.value,
          that.f.manufacturing_plant.value,
          that.f.region.value,
          that.f.country.value,
          that.f.product_designation.value,
          that.f.sales_order_no.value,
          that.f.sales_order_type.value,
          that.f.part_no_on_product.value,
          that.f.scc_individual_status.value,
          that.f.exported_from_the_factory.value,
          that.f.reason.value,
          that.client_id,
            that.f.year.value,
            that.f.month.value
             )
            .subscribe(
              data => {
                if(data.status.code==0){
                  console.log(data);
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.toastr.success('Successfully added')
                  location.reload();
                }else{
                  console.log(data);
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.toastr.error(data.status.message)
                }
         
          location.reload();
          /*that.orderForm.setValue({
            index: '',
            _id: '',
            serial_no: '',
            driveline_id: '',
            manufacturing_date: '',
            sold_to: '',
            shipped_to: '',
            product_category: '',
            product_family: '',
            technical_platform: '',
            application: '',
            product_name: '',
            ship_date: '',
            manufacturing_plant: '',
            region: '',
            country: '',
            product_designation: '',
            sales_order_no: '',
            sales_order_type: '',
            part_no_on_product: '',
            scc_individual_status: '',
            exported_from_the_factory: '',
            reason: ''



      });*/
                /*if (data.status.code == 0) {
                  that.submitted = false;
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.cancelButton = false;

                  location.href = '/manage-orders';


                  that.orderForm.setValue({
                    index: null,
                    _id: null,
                    product_name: '',

                  });
                  formWrap.reset();



                } else {
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.toastr.error(data.status.message);
                }*/
              },
              error => {
                that.toastr.error(error);
                document.body.classList.remove('jw-modal-open');
                that.loading = false;
              });
            }, function() {
          that.toastr.error('Create owner action cancelled');
        });
        }
      }




  filesDropped(files: FileHandle[]): void {
    this.bulkOrderForm.controls['orderFile'].clearValidators();
this.bulkOrderForm.controls['orderFile'].updateValueAndValidity();
    this.filename = '';
    this.filename = files[0].file.name;
    console.log('name      '+this.filename);
    if (files[0].file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
    this.files = files;

    this.bulkOrderForm.get('orderFile').setValue(this.files[0].file);

}else{
  this.toastr.error('Please upload excel file only');
}

  }
  ngAfterViewInit() {
   
  
  }

  upload(): void {
    // get image upload file obj;
  }


}
