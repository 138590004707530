import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,AbstractControl,ValidationErrors,FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService,SettingsService } from '../_services';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {


  resetForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
       hide=1;
    mobile_no = '';
    random = '';
    constructor(
            private setting: SettingsService,
            private formBuilder: FormBuilder,
            private authenticationService: AuthenticationService,
            private modalService: ModalService,
            private toastr: ToastrService,
            private route: ActivatedRoute,
            private router: Router) { }
static areEqual(c: AbstractControl): ValidationErrors | null {
        const keys: string[] = Object.keys(c.value);
        for (const i in keys) {
            if (i !== '0' && c.value['password'] !== c.value['confirm_password']) {
                return { areEqual: true };
            }
        }

    }
    ngOnInit() {
        this.resetForm = this.formBuilder.group({
          password: ['', Validators.required],
          confirm_password: ['', Validators.required],

          passwords: new FormGroup({
            password: new FormControl(),
            confirm_password: new FormControl()
          }, ResetPasswordComponent.areEqual)

        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.route.params.subscribe(params => {
          this.random = params.id;
        });

        
    }

    // convenience getter for easy access to form fields
    get f() { return this.resetForm.controls; }
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.resetForm.invalid) {
            return;
        }

        document.body.classList.add('jw-modal-open');
        this.loading = true;

        this.setting.updatePassword(
            this.f.password.value,this.random)
            .subscribe(
                data => {
                    if (data.status.code == 0) {
                        this.submitted = false;
                        document.body.classList.remove('jw-modal-open');
                        this.loading = false;
                        this.toastr.success(data.status.message);
                          //  location.href = this.returnUrl;

                         } else {
                        document.body.classList.remove('jw-modal-open');
                        this.loading = false;
                        this.toastr.error(data.status.message)
                        }
                },
                error => {
                    this.toastr.error(error)
                    document.body.classList.remove('jw-modal-open');
                    this.loading = false;
                });

    }

}
