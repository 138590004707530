<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="javascript:void(0)" role="button"><i class="fas fa-bars"></i></a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a href="/home" class="nav-link">Home</a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a href="/create-owner" class="nav-link"  >Create Owners</a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a href="/manage-owner" class="nav-link">Manage Owners</a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a href="/duplicate-owner" class="nav-link">Duplicate Owners</a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a href="/invoices" class="nav-link">Invoice</a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a href="/manage-clients" class="nav-link">Manage Clients</a>
        </li>
        
        
        
        <li class="nav-item d-none d-sm-inline-block more_menu">
            <a href="javascript:void(0)" class="nav-link">My Settings <i class="fas fa-cog"></i></a>
            <ul class="drop_menu">
                <li><a href="/settings">Change Password</a></li>
                <!-- <li><a href="/edit_data">Settings</a></li> -->
                <!-- <li><a href="/invoices">Invoice</a></li> -->
                <li><a href="/login">Logout</a></li>
            </ul>
        </li>
    </ul>
</nav>
<!-- /.navbar -->
<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <div class="logo">
        <!--   <a href="/home"><img src="assets/images/logo_small.png" class="img-responsive img" alt="logo"></a> -->
    </div>
    <!-- Sidebar -->
    <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-item">
                    <a href="/home" class="nav-link">
                        <i class="nav-icon fas fa-home"></i>
                        <p>
                            Home
                        </p>
                    </a>
                </li>
                <li class="nav-item" >
                    <a href="/create-orders" class="nav-link">
                        <i class="nav-icon far fa-plus-square"></i>
                        <p>
                           Create Owners
                        </p>
                    </a>
                </li>
                
                
                
                <li class="nav-item">
                    <a href="/manage-orders" class="nav-link">
                        <i class="nav-icon fas fa-tasks"></i>
                        <p>
                            Manage Owners
                        </p>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="/invoices" class="nav-link">
                        <i class="nav-icon fas fa-indent"></i>
                        <p>
                            Invoice
                        </p>
                    </a>
                </li>
                <li class="nav-item has-treeview">
                    <a href="javascript:void(0)" class="nav-link">
                        <i class="nav-icon fas fa-cog"></i>
                        <p>
                            My Settings
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a href="/settings" class="nav-link">
                                <i class="nav-icon far fa-circle nav-icon"></i>
                                <p>Change Password</p>
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a href="/edit_data" class="nav-link">
                                <i class="nav-icon far fa-circle nav-icon"></i>
                                <p>Settings</p>
                            </a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a href="/invoices" class="nav-link">
                                <i class="nav-icon far fa-circle nav-icon"></i>
                                <p>Invoice</p>
                            </a>
                        </li> -->
                        <li class="nav-item">
                            <a href="/login" class="nav-link">
                                <i class="nav-icon far fa-circle nav-icon"></i>
                                <p>Logout</p>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>