import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { SettingsService, ClientService } from '../_services';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { SettingsComponent } from '../settings/settings.component';
import { Subject } from 'rxjs';
declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-manage-client',
  templateUrl: './manage-client.component.html',
  styleUrls: ['./manage-client.component.css']
})
export class ManageClientComponent implements OnInit {
  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
	companyName = localStorage.getItem(`${environment.appName}` + '_companyName');
	unit_price = localStorage.getItem(`${environment.appName}` + '_unit_price');

	role: any;
	settingForm: FormGroup;
	unitForm: FormGroup;
	loading = false;
	submitted = false;
  isResetFlag: boolean = false;
  clients$:any;
  perPage = 10;
  curPage:1;
  cancelButton=false;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
	constructor(
    private setting: SettingsService,
    private client: ClientService,
		private toastr: ToastrService,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router
	) { }

	static areEqual(c: AbstractControl): ValidationErrors | null {
		const keys: string[] = Object.keys(c.value);
		for (const i in keys) {
			if (i !== '0' && c.value['new_password'] !== c.value['confirm_password']) {
				return { areEqual: true };
			}
		}
	}

	ngOnInit() {

	// const table = $('#table').DataTable();
	// console.log(table)
		this.unitForm = this.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
			qty_price: ['', [Validators.required, Validators.maxLength(50),]],
			unit_commission: ['', [Validators.required, Validators.maxLength(50),]],
			unit_commission_price: ['', [Validators.required, Validators.maxLength(50),]],
			carrier_price: ['', [Validators.required, Validators.maxLength(50),]],
			product_abrevation: ['', [Validators.required, Validators.maxLength(50),]],
			default_state: ['', [Validators.required, Validators.maxLength(50),]],
			aggreement_terms: ['', [Validators.required, Validators.maxLength(50),]],
			mfg_labour_war: ['', [Validators.required, Validators.maxLength(50),]],
			mfg_part_war: ['', [Validators.required, Validators.maxLength(50),]],
			product_manufacture: ['', [Validators.required, Validators.maxLength(50),]],
			email_invoice_to: ['', [Validators.required, Validators.maxLength(50), Validators.email]],
			email_remit: ['', [Validators.required, Validators.maxLength(50), Validators.email]],
			email_history: ['', [Validators.required, Validators.maxLength(50), Validators.email]],
			supplier_code: ['', [ Validators.maxLength(50),]],
			purchase_order_code: ['', [Validators.maxLength(50)]],
			client_name: ['', [Validators.required,Validators.maxLength(50)]],
			serial_no: ['', ],
			driveline_id:[''],
			manufacturing_date:[''],
			shipped_to:[''],
			product_category:[''],
			product_family:[''],
			technical_platform:[''],
			product_name:[''],
			ship_date:[''],
			manufacturing_plant:[''],
			region:[''],
			country:[''],
			product_desgnation:[''],
			sales_order_type:[''],
			scc_status:[''],
			exported_factory:[''],
			reason:[''],
			part_no_on_product:['']













		});

    this.client.getAllClients().subscribe(data=>{
	  this.clients$ = data.data.clients;

    })
		
		if (this.router.url == '/edit_data') {
			$('#content').hide();
		}

		if (this.router.url == '/settings') {
			$('#quantity').hide();
		}

		this.role = localStorage.getItem(`${environment.appName}` + '_role');

	
	}

	get u() { return this.unitForm.controls; }
	quantitySubmit(form, formWrap){
		console.log(this.u.serial_no.value)
		const table = $('#table').DataTable();
		// console.log(this.unitForm);
		this.submitted = true;
		if(this.unitForm.invalid){
			return false;
    }
    const id = form.getRawValue()._id;
    const index = form.getRawValue().index;
		document.body.classList.add('jw-modal-open');
		const that = this
    if (id != null) {
		alertify.confirm('Update Client', 'Are you sure want to update this record', function() {
			that.loading = true;
		 
		that.client.updateUnit(id,that.u.qty_price.value,
			that.u.unit_commission.value,
			that.u.unit_commission_price.value,
			that.u.carrier_price.value,
			that.u.product_abrevation.value,
			that.u.default_state.value,
			that.u.aggreement_terms.value,
			that.u.mfg_labour_war.value,
			that.u.mfg_part_war.value,
			that.u.product_manufacture.value,
			that.u.email_invoice_to.value,
			that.u.email_remit.value,
			that.u.email_history.value,
			that.u.supplier_code.value,
			that.u.purchase_order_code.value,
			that.u.client_name.value,
			that.u.serial_no.value?1:"",
			that.u.driveline_id.value?1:"",
			that.u.manufacturing_date.value?1:"",
			that.u.shipped_to.value?1:"",
			that.u.product_category.value?1:"",
			that.u.product_family.value?1:"",
			that.u.technical_platform.value?1:"",
			that.u.product_name.value?1:"",
			that.u.ship_date.value?1:"",
			that.u.manufacturing_plant.value?1:"",
			that.u.region.value?1:"",
			
			that.u.product_desgnation.value?1:"",
			that.u.sales_order_type.value?1:"",
			that.u.scc_status.value?1:"",
			that.u.exported_factory.value?1:"",
			that.u.reason.value?1:"",
			that.u.country.value?1:"",
			that.u.part_no_on_product.value?1:"",
		

			).subscribe(data => { 
				if(data.status.code==0){
					location.reload();
					document.body.classList.remove('jw-modal-open');
				that.loading = false;
				that.submitted = false;
				that.toastr.success('Successfully updated');
				table.draw();
				that.clients$ = data.data.clients
				}else{
					document.body.classList.remove('jw-modal-open');
				that.loading = false;
				that.submitted = false;
				that.toastr.error(data.status.message);
				}
				
			
				
				

			}, error => { 
				
				
				console.error(error);
			
			
			});
		}
		, function() { // alertify.error('Delete action cancelled')
		  that.toastr.error('Update action cancelled');
	
		});
			
			
	}
	else{
		alertify.confirm('Save Client', 'Are you sure want to save this record', function() {
     
      that.client.saveUnit(
      	that.u.qty_price.value,
		that.u.unit_commission.value,
		that.u.unit_commission_price.value,
		that.u.carrier_price.value,
		that.u.product_abrevation.value,
		that.u.default_state.value,
		that.u.aggreement_terms.value,
		that.u.mfg_labour_war.value,
		that.u.mfg_part_war.value,
		that.u.product_manufacture.value,
		that.u.email_invoice_to.value,
		that.u.email_remit.value,
		that.u.email_history.value,
		that.u.supplier_code.value,
		that.u.purchase_order_code.value,
		that.u.client_name.value,
		that.u.serial_no.value?1:"",
		that.u.driveline_id.value?1:"",
		that.u.manufacturing_date.value?1:"",
		that.u.shipped_to.value?1:"",
		that.u.product_category.value?1:"",
		that.u.product_family.value?1:"",
		that.u.technical_platform.value?1:"",
		that.u.product_name.value?1:"",
		that.u.ship_date.value?1:"",
		that.u.manufacturing_plant.value?1:"",
		that.u.region.value?1:"",
		that.u.product_desgnation.value?1:"",
		that.u.sales_order_type.value?1:"",
		that.u.scc_status.value?1:"",
		that.u.exported_factory.value?1:"",
		that.u.reason.value?1:"",
		that.u.country.value?1:"",
		that.u.part_no_on_product.value?1:"" 
        ).subscribe(data => { 
			if(data.status.code==0){
				document.body.classList.remove('jw-modal-open');
				that.loading = false;
				that.submitted = false;
				that.toastr.success('Successfully Added');
				table.draw();
				location.reload();
				// console.log(data); 
			}else{
				document.body.classList.remove('jw-modal-open');
				that.loading = false;
				that.submitted = false;
				that.toastr.error(data.status.message);
				// location.reload();
				console.log(data); 
			}
         
  
		}, error => { console.error(error); });
	}
	, function() { // alertify.error('Delete action cancelled')
	  that.toastr.error('Save action cancelled');

	});
    }

	}

  cancelSubmit(data){
    this.cancelButton = false;
    this.unitForm.patchValue({
      _id:'',
      index:'',
      qty_price: '',
			unit_commission: '',
			unit_commission_price: '',
			carrier_price: '',
			product_abrevation: '',
			default_state: '',
			aggreement_terms:'',
			mfg_labour_war:'',
			mfg_part_war: '',
			product_manufacture: '',
			email_invoice_to: '',
			email_remit: '',
			email_history: '',
			client_name:'',
			supplier_code:'',
			purchase_order_code:'',
			serial_no:'',
			driveline_id:'',
			manufacturing_date:'',
			shipped_to:'',
			product_category:'',
			product_family:'',
			technical_platform:'',
			product_name:'',
			ship_date:'',
			manufacturing_plant:'',
			region:'',
			product_desgnation:'',
			sales_order_type:'',
			scc_status:'',
			exported_factory:'',
			reason:'',
			country:'',
			part_no_on_product:'',




    })
  }
  clientEdit(event,client,i){
	// document.getElementsByClassName('table-responsive')[0].classList.add('disabledTable');
    this.cancelButton = true;
    console.log(client)
    this.unitForm.setValue({
      _id:client._id,
      index:i,
      qty_price: client.unit_price,
			unit_commission: client.unit_commission,
			unit_commission_price: client.unit_commission_price,
			carrier_price: client.carrier_price,
			product_abrevation: client.product_abrevation,
			default_state: client.default_state,
			aggreement_terms: client.aggreement_terms,
			mfg_labour_war: client.mfg_labour_war,
			mfg_part_war: client.mfg_part_war,
			product_manufacture: client.product_manufacture,
			email_invoice_to: client.email_invoice_to,
			email_remit: client.email_remit,
			email_history: client.email_history,
			client_name:client.client_name?client.client_name:'',
			supplier_code:client.supplier_code?client.supplier_code:'',
			purchase_order_code:client.purchase_order_code?client.purchase_order_code:'',
			serial_no:client.serial_no?client.serial_no:'',
			driveline_id:client.driveline_id?client.driveline_id:'',
			manufacturing_date:client.manufacturing_date?client.manufacturing_date:'',
			shipped_to:client.shipped_to?client.shipped_to:'',
			product_category:client.product_category?client.product_category:'',
			product_family:client.product_family?client.product_family:'',
			technical_platform:client.technical_platform?client.technical_platform:'',
			product_name:client.product_name?client.product_name:'',
			ship_date:client.ship_date?client.ship_date:'',
			manufacturing_plant:client.manufacturing_plant?client.manufacturing_plant:'',
			region:client.region?client.region:'',
			product_desgnation:client.product_desgnation?client.product_desgnation:'',
			sales_order_type:client.sales_order_type?client.sales_order_type:'',
			scc_status:client.scc_status?client.scc_status:'',
			exported_factory:client.exported_from_the_factory?client.exported_from_the_factory:'',
			reason:client.reason?client.reason:'',
			country:client.country?client.country:'',
			part_no_on_product:client.part_no_on_product?client.part_no_on_product:'',
          })
  }

  clientDelete(event,client,i){
	  const that = this
	alertify.confirm('Delete Client', 'Are you sure want to delete this record', function() {
		that.client.deleteClient(client._id).subscribe(data=>{
			that.clients$ = data.data.client;
			if(data.status.code==0){
				that.toastr.success('Client deleted successfully');
				location.reload();
			}else{
				that.toastr.error(data.status.message);
				// location.reload();
			}
	  })
	}
	, function() { // alertify.error('Delete action cancelled')
	  that.toastr.error('Delete action cancelled');

	});
  }
  ngOnDestroy(): void {
	// this.dtTrigger.unsubscribe();
	this.clients$.unsubscribe();
  }
	
	ngAfterViewInit() {
		setTimeout(() => {
			if ($.fn.dataTable.isDataTable('#table')) {
			  const table = $('#table').DataTable();
			  console.log(table);
			}
		  }, 500);
	}
}
