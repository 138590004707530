<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <ol class="breadcrumb">
            <li class="breadcrumb-item "><a href="/home">Home</a></li>
            <li class="breadcrumb-item ">Create Owner</li>
          </ol>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item active text-uppercase">WELCOME <span *ngIf="adminName != 'undefined'">{{adminName}}</span> <span *ngIf="companyName && role ==1">{{companyName}}</span></li>
          </ol>
        </div>
        </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="create_order_main">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs">
              
              <li class="nav-item">
                <a class="nav-link active " data-toggle="tab" href="#menu1">Bulk Owner</a>
              </li>
              <li class="nav-item">
                <a class="nav-link " data-toggle="tab" href="#home">Single Owner</a>
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content">
              <div class="tab-pane" id="home">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Single Owner</h3>
                  </div>
                  <!-- /.card-header -->
                  <form [formGroup]="orderForm" #myForm="ngForm" (ngSubmit)="onSubmit(orderForm, myForm)"
                    class="" >
                    <div class="card-body">
                      <div class="col-md-8">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Select Client</label>
                              <ngx-select-dropdown #id
                              [config]="clientConfig"
                              [options]="clients$"
                             
                              formControlName="client"
                              (change)="onItemSelect($event)"
                              [ngClass]="{ 'is-invalid': (f.client.touched || submitted) && f.client.errors }">
                              </ngx-select-dropdown>
                              <div *ngIf="(f.client.touched || submitted) && f.client.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.client.errors?.required">Client is required</div>
                              </div>
                            </div>
                          </div>

                           <div class="col-md-4"  >
                            <div class="form-group">
                              <label>Month</label>
                               <select  [ngClass]="{ 'is-invalid': (f.month.touched || submitted) && f.month.errors?.required  || f.month.errors?.maxlength}" id="number" formControlName="month" class="form-control">
<option value="">Select Month</option>
                                <option value="01">Jan</option>
                                <option value="02">Feb</option>
                                <option value="03">Mar</option>
                                <option value="04">Apr</option>
                                <option value="05">May</option>
                                <option value="06">Jun</option>
                                <option value="07">Jul</option>
                                <option value="08">Aug</option>
                                <option value="09">Sept</option>
                                <option value="10">Oct</option>
                                 <option value="11">Nov</option>
                                <option value="12">Dec</option>

                               </select>
                              <div *ngIf="(f.month.touched || submitted) && f.month.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.month.errors?.required">Month number is required</div>
                              </div>
                              
                            </div>
                          </div>


       <div class="col-md-4" >
                            <div class="form-group">
                              <label>Year</label>
                               <select  [ngClass]="{ 'is-invalid': (f.year.touched || submitted) && f.year.errors?.required  || f.year.errors?.maxlength}" id="number" formControlName="year" class="form-control">
  <option value="">Select Year</option>
                               <option value="15">2015</option>
                                <option value="16">2016</option>
                                <option value="17">2017</option>
                                <option value="18">2018</option>
                                <option value="19">2019</option>
                                <option value="20">2020</option>
                                <option value="21">2021</option>
                                <option value="22">2022</option>
                                <option value="23">2023</option>
                                <option value="24">2024</option>
                                <option value="25">2025</option>
                             

                               </select>
                              <div *ngIf="(f.year.touched || submitted) && f.year.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.year.errors?.required">Year number is required</div>
                              </div>
                              
                            </div>
                          </div>

                           


                          <div class="col-md-4" *ngIf="client_id && client_id.serial_no">
                            <div class="form-group">
                              <label>Serial no</label>
                              <input type="number" placeholder="Serial no"  [ngClass]="{ 'is-invalid': (f.serial_no.touched || submitted) && f.serial_no.errors?.required  || f.serial_no.errors?.maxlength}" id="number" formControlName="serial_no" class="form-control" name="">
                              <div *ngIf="(f.serial_no.touched || submitted) && f.serial_no.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.serial_no.errors?.required">Serial number is required</div>
                              </div>
                              
                            </div>
                          </div>
                         
                          <div class="col-md-4" *ngIf="client_id && client_id.driveline_id">
                            <div class="form-group">
                              <label>Drive line Id</label>
                              <input type="text" placeholder="Enter drive line Id" id="number" [ngClass]="{ 'is-invalid': (f.driveline_id.touched || submitted) && f.driveline_id.errors }" formControlName="driveline_id" class="form-control" name="">
                              <div *ngIf="(f.driveline_id.touched || submitted) && f.driveline_id.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.driveline_id.errors?.required">Drive line is required</div>
                              </div>
                              <!-- <div *ngIf="(f.client_name.touched || submitted) && f.client_name.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.client_name.errors?.required">Client Name is required</div>
                              </div>
                              <div *ngIf="(f.client_name.touched || submitted) && f.client_name.errors?.maxlength"
                                class="invalid-feedback">
                                <div *ngIf="f.client_name.errors?.maxlength">Maximum 100 characters are allowed
                                </div>
                              </div> -->
                            </div>
                          </div>
                         
                          
                       

                          <div class="col-md-4" *ngIf="client_id && client_id.manufacturing_date">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Manufacturing date </label>
                              <input type="date" class="form-control" id="exampleInputEmail1"  [ngClass]="{ 'is-invalid': (f.manufacturing_date.touched || submitted) && f.manufacturing_date.errors }" formControlName="manufacturing_date" placeholder="Enetr manufacturing_date"
                              >
                              <div *ngIf="(f.manufacturing_date.touched || submitted) && f.manufacturing_date.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.manufacturing_date.errors?.required">Manufacturing date is required</div>
                              </div>
                              <!-- [ngClass]="{ 'is-invalid': (f.client_order.touched || submitted) && f.client_order.errors }" -->
                              <!--  <div *ngIf="(f.client_order.touched || submitted) && f.client_order.errors?.maxlength"
                                class="invalid-feedback">
                                <div *ngIf="f.client_order.errors?.maxlength">Maximum 30 characters are allowed
                                </div>
                              </div> -->
                            </div>
                          </div>
                          <div class="col-md-4" *ngIf="client_id && client_id.sold_to">
                            <div class="form-group">
                              <label>Sold to</label>
                              <!-- <ngx-select-dropdown #id
                              [config]="productConfig"
                              [options]="products$"
                              formControlName="product"
                              [ngClass]="{ 'is-invalid': (f.product.touched || submitted) && f.product.errors }">
                              </ngx-select-dropdown> -->
                              <input type="text" name="" [ngClass]="{ 'is-invalid': (f.sold_to.touched || submitted) && f.sold_to.errors }" class="form-control" formControlName="sold_to" placeholder="Sold to">
                              <div *ngIf="(f.sold_to.touched || submitted) && f.sold_to.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.sold_to.errors?.required">Sold is required</div>
                              </div>
                              <!-- <div *ngIf="(f.product.touched || submitted) && f.product.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.product.errors?.required">Product is required</div>
                              </div> -->
                            </div>
                          </div>
                          <div class="col-md-4" *ngIf="client_id && client_id.shipped_to">
                            <div class="form-group">
                              <label>Shipped to</label>
                              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': (f.shipped_to.touched || submitted) && f.shipped_to.errors }" formControlName="shipped_to" placeholder="Shipped to"
                              >
                              <div *ngIf="(f.shipped_to.touched || submitted) && f.shipped_to.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.shipped_to.errors?.required">Shipped is required</div>
                              </div>
                              <!-- [ngClass]="{ 'is-invalid': (f.address_no.touched || submitted) && f.address_no.errors }" -->
                              <!-- <div *ngIf="(f.address_no.touched || submitted) && f.address_no.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.address_no.errors?.required">Address number is required</div>
                              </div>
                              <div *ngIf="(f.address_no.touched || submitted) && f.address_no.errors?.maxlength"
                                class="invalid-feedback">
                                <div *ngIf="f.address_no.errors?.maxlength">Maximum 10 characters are allowed
                                </div>
                              </div> -->
                            </div>
                          </div>
                          
                          
                     
                            <div class="col-md-4"  *ngIf="client_id && client_id.product_category">
                              <div class="form-group">
                                <label>Product category</label>
                                <input type="text" class="form-control" [ngClass]="{ 'is-invalid': (f.product_category.touched || submitted) && f.product_category.errors }"  formControlName="product_category" placeholder="Product category">
                                <div *ngIf="(f.product_category.touched || submitted) && f.product_category.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.product_category.errors?.required">Product category is required</div>
                                </div>
                                <!-- [ngClass]="{ 'is-invalid': (f.address_prefix.touched || submitted) && f.address_prefix.errors }" -->
                                <!-- <div *ngIf="(f.address_prefix.touched || submitted) && f.address_prefix.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.address_prefix.errors?.required">Address Prefix is required</div>
                                </div>
                                <div *ngIf="(f.address_prefix.touched || submitted) && f.address_prefix.errors?.maxlength"
                                  class="invalid-feedback">
                                  <div *ngIf="f.address_prefix.errors?.maxlength">Maximum 20 characters are allowed
                                  </div>
                                </div> -->
                              </div>
                            </div>
                            <div class="col-md-4" *ngIf="client_id && client_id.product_family">
                              <div class="form-group">
                                <label>Product family</label>
                                <input type="text" class="form-control" [ngClass]="{ 'is-invalid': (f.product_family.touched || submitted) && f.product_family.errors }" formControlName="product_family" placeholder="Product family">
                                <div *ngIf="(f.product_family.touched || submitted) && f.product_family.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.product_family.errors?.required">Product family is required</div>
                                </div>
                                <!-- formControlName="street_name" -->
                                <!-- [ngClass]="{ 'is-invalid': (f.street_name.touched || submitted) && f.street_name.errors }" -->
                                <!-- <div *ngIf="(f.street_name.touched || submitted) && f.street_name.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.street_name.errors?.required">Street Name is required</div>
                                </div>
                                <div *ngIf="(f.street_name.touched || submitted) && f.street_name.errors?.maxlength"
                                  class="invalid-feedback">
                                  <div *ngIf="f.street_name.errors?.maxlength">Maximum 60 characters are allowed
                                  </div>
                                </div> -->
                              </div>
                            </div>
                            <div class="col-md-4" *ngIf="client_id && client_id.technical_platform">
                              <div class="form-group">
                                <label>Technical platform</label>
                                <input type="text" name="" formControlName="technical_platform" [ngClass]="{ 'is-invalid': (f.technical_platform.touched || submitted) && f.technical_platform.errors }"  placeholder="Technical platform" class="form-control">
                                <div *ngIf="(f.technical_platform.touched || submitted) && f.technical_platform.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.technical_platform.errors?.required">Technical platform is required</div>
                                </div>
                                <!-- <ngx-select-dropdown #id
                                [config]="countryConfig"
                                [options]="countries$"
                                (change)="onCountry($event)"
                                formControlName="country"   [ngClass]="{ 'is-invalid': (f.country.touched || submitted) && f.country.errors }">
                                </ngx-select-dropdown> -->
                                <!-- <div *ngIf="(f.country.touched || submitted) && f.country.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.country.errors?.required">State is required</div>
                                </div> -->
                              </div>
                            </div>
                         
                            <div class="col-md-4" *ngIf="client_id && client_id.application">
                              <div class="form-group">
                                <label>Application</label>
                                <input type="text" name="" formControlName="application" [ngClass]="{ 'is-invalid': (f.application.touched || submitted) && f.application.errors }" placeholder="Application" class="form-control">
                                <div *ngIf="(f.application.touched || submitted) && f.application.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.application.errors?.required">Application is required</div>
                                </div>
                                <!--  <div *ngIf="(f.state.touched || submitted) && f.state.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.state.errors?.required">County is required</div>
                                </div> -->
                              </div>
                            </div>
                            <div class="col-md-4" *ngIf="client_id && client_id.product_name">
                              <div class="form-group">
                                <label>Product Name</label>
                                <input type="text" class="form-control" [ngClass]="{ 'is-invalid': (f.product_name.touched || submitted) && f.product_name.errors }"  formControlName="product_name" placeholder="Enter Product Name" >
                                <div *ngIf="(f.product_name.touched || submitted) && f.product_name.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.product_name.errors?.required">Product name is required</div>
                                </div>
                                <!-- formControlName="city"
                                [ngClass]="{ 'is-invalid': (f.city.touched || submitted) && f.city.errors }" -->
                                <!-- <div *ngIf="(f.city.touched || submitted) && f.city.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.city.errors?.required">City is required</div>
                                </div>
                                <div *ngIf="(f.city.touched || submitted) && f.city.errors?.maxlength"
                                  class="invalid-feedback">
                                  <div *ngIf="f.city.errors?.maxlength">Maximum 20 characters are allowed
                                  </div>
                                </div> -->
                              </div>
                            </div>
                            <div class="col-md-4" *ngIf="client_id && client_id.ship_date">
                              <div class="form-group">
                                <label>Ship date</label>
                                <input type="date" id="datepicker" class="form-control" [ngClass]="{ 'is-invalid': (f.ship_date.touched || submitted) && f.ship_date.errors }" formControlName="ship_date" id="exampleInputEmail1" placeholder="Ship Date">
                                <div *ngIf="(f.ship_date.touched || submitted) && f.ship_date.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.ship_date.errors?.required">Ship date is required</div>
                                </div>
                                <!-- formControlName="first_name1"   [ngClass]="{ 'is-invalid': (f.first_name1.touched || submitted) && f.first_name1.errors }" -->
                                <!-- <div *ngIf="(f.first_name1.touched || submitted) && f.first_name1.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.first_name1.errors?.required">First Name is required</div>
                                </div>
                                <div *ngIf="(f.first_name1.touched || submitted) && f.first_name1.errors?.maxlength"
                                  class="invalid-feedback">
                                  <div *ngIf="f.first_name1.errors?.maxlength">Maximum 20 characters are allowed
                                  </div>
                                </div> -->
                              </div>
                            </div>
                           
                         
                            <div class="col-md-4" *ngIf="client_id && client_id.manufacturing_plant">
                              <div class="form-group">
                                <label for="exampleInputEmail1">Manufacturing plant</label>
                                <input type="text" class="form-control" [ngClass]="{ 'is-invalid': (f.manufacturing_plant.touched || submitted) && f.manufacturing_plant.errors }" formControlName="manufacturing_plant" id="exampleInputEmail1" placeholder="Manufacturing plant"  >
                                <div *ngIf="(f.manufacturing_plant.touched || submitted) && f.manufacturing_plant.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.manufacturing_plant.errors?.required">Manufacturing plant is required</div>
                                </div>
                                <!-- formControlName="middle_name1"
                                <div *ngIf="(f.middle_name1.touched || submitted) && f.middle_name1.errors?.maxlength"
                                  class="invalid-feedback">
                                  <div *ngIf="f.middle_name1.errors?.maxlength">Maximum 20 characters are allowed
                                  </div>
                                </div> -->
                              </div>
                            </div>
                            <div class="col-md-4" *ngIf="client_id && client_id.region">
                              <div class="form-group">
                                <label for="Region">Region</label>
                                <input type="text" class="form-control" [ngClass]="{ 'is-invalid': (f.region.touched || submitted) && f.region.errors }" formControlName="region" id="exampleInputEmail1" placeholder="Enter Region">
                                <div *ngIf="(f.region.touched || submitted) && f.region.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.region.errors?.required">Region is required</div>
                                </div>
                                <!-- formControlName="last_name1" -->
                                <!-- [ngClass]="{ 'is-invalid': (f.last_name1.touched || submitted) && f.last_name1.errors }"  -->
                                <!-- <div *ngIf="(f.last_name1.touched || submitted) && f.last_name1.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.last_name1.errors?.required">Last Name is required</div>
                                </div>
                                <div *ngIf="(f.last_name1.touched || submitted) && f.last_name1.errors?.maxlength"
                                  class="invalid-feedback">
                                  <div *ngIf="f.last_name1.errors?.maxlength">Maximum 20 characters are allowed
                                  </div>
                                </div> -->
                              </div>
                            </div>
                            <div class="col-md-4" *ngIf="client_id && client_id.country">
                              <div class="form-group">
                                <label for="exampleInputEmail1">Country</label>
                                <input type="text" class="form-control" [ngClass]="{ 'is-invalid': (f.country.touched || submitted) && f.country.errors }" formControlName="country" id="exampleInputEmail1" placeholder="Enter country">
                                <div *ngIf="(f.country.touched || submitted) && f.country.errors?.required"
                                  class="invalid-feedback">
                                  <div *ngIf="f.country.errors?.required">Country is required</div>
                                </div>
                                <!-- <div *ngIf="(f.first_name2.touched || submitted) && f.first_name2.errors?.maxlength"
                                  class="invalid-feedback"> -->
                                  <!-- formControlName="first_name2"
                                  <div *ngIf="f.first_name2.errors?.maxlength">Maximum 20 characters are allowed
                                  </div> -->
                                </div>
                              </div>
                             
                            
                              <div class="col-md-4" *ngIf="client_id && client_id.product_desgnation">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">Product designation</label>
                                  <input type="text" class="form-control" [ngClass]="{ 'is-invalid': (f.product_designation.touched || submitted) && f.product_designation.errors }" formControlName="product_designation" id="exampleInputEmail1" placeholder="Product designation">
                                  <div *ngIf="(f.product_designation.touched || submitted) && f.product_designation.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f.product_designation.errors?.required">Product designation is required</div>
                                  </div>
                                 
                                  <!-- formControlName="middle_name2"
                                  <div *ngIf="(f.middle_name2.touched || submitted) && f.middle_name2.errors?.maxlength"
                                    class="invalid-feedback">
                                    <div *ngIf="f.middle_name2.errors?.maxlength">Maximum 20 characters are allowed
                                    </div>
                                  </div> -->
                                </div>
                              </div>
                              <div class="col-md-4"  *ngIf="client_id && client_id.sales_order_no">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">Sales order number</label>
                                  <input type="text" class="form-control"  [ngClass]="{ 'is-invalid': (f.sales_order_no.touched || submitted) && f.sales_order_no.errors }" formControlName="sales_order_no" id="exampleInputEmail1" placeholder="Sales order number">
                                  <div *ngIf="(f.sales_order_no.touched || submitted) && f.sales_order_no.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f.sales_order_no.errors?.required">Sales order number is required</div>
                                  </div>
                                  <!-- formControlName="last_name2"
                                  <div *ngIf="(f.last_name2.touched || submitted) && f.last_name2.errors?.maxlength"
                                    class="invalid-feedback">
                                    <div *ngIf="f.last_name2.errors?.maxlength">Maximum 20 characters are allowed
                                    </div>
                                  </div> -->
                                </div>
                              </div>
                              <div class="col-md-4" *ngIf="client_id && client_id.sales_order_type">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">Sales order type</label>
                                  <input type="text" class="form-control" [ngClass]="{ 'is-invalid': (f.sales_order_type.touched || submitted) && f.sales_order_type.errors }" formControlName="sales_order_type" id="exampleInputEmail1" placeholder="Sales order type">
                                  <div *ngIf="(f.sales_order_type.touched || submitted) && f.sales_order_type.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f.sales_order_type.errors?.required">Sales order type is required</div>
                                  </div>
                                  <!-- <div *ngIf="(f.first_name2.touched || submitted) && f.first_name2.errors?.maxlength"
                                    class="invalid-feedback"> -->
                                    <!-- formControlName="first_name2"
                                    <div *ngIf="f.first_name2.errors?.maxlength">Maximum 20 characters are allowed
                                    </div> -->
                                  </div>
                                </div>
                                
                                
                             
                                <div class="col-md-4" *ngIf="client_id && client_id.part_no_on_product">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Part number on product</label>
                                    <input type="text" class="form-control"  [ngClass]="{ 'is-invalid': (f.part_no_on_product.touched || submitted) && f.part_no_on_product.errors }"  formControlName="part_no_on_product" id="exampleInputEmail1" placeholder="Part number on product">
                                    <div *ngIf="(f.part_no_on_product.touched || submitted) && f.part_no_on_product.errors?.required"
                                      class="invalid-feedback">
                                      <div *ngIf="f.part_no_on_product.errors?.required">Part number on product is required</div>
                                    </div>
                                    <!-- formControlName="middle_name2"
                                    <div *ngIf="(f.middle_name2.touched || submitted) && f.middle_name2.errors?.maxlength"
                                      class="invalid-feedback">
                                      <div *ngIf="f.middle_name2.errors?.maxlength">Maximum 20 characters are allowed
                                      </div>
                                    </div> -->
                                  </div>
                                </div>
                                <div class="col-md-4" *ngIf="client_id && client_id.scc_individual_status">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Scc individual status</label>
                                    <input type="text" class="form-control"  [ngClass]="{ 'is-invalid': (f.scc_individual_status.touched || submitted) && f.scc_individual_status.errors }" formControlName="scc_individual_status" id="exampleInputEmail1" placeholder="Scc individual status">
                                    <div *ngIf="(f.scc_individual_status.touched || submitted) && f.scc_individual_status.errors?.required"
                                      class="invalid-feedback">
                                      <div *ngIf="f.scc_individual_status.errors?.required">Scc individual status is required</div>
                                    </div>
                                    <!-- formControlName="last_name2"
                                    <div *ngIf="(f.last_name2.touched || submitted) && f.last_name2.errors?.maxlength"
                                      class="invalid-feedback">
                                      <div *ngIf="f.last_name2.errors?.maxlength">Maximum 20 characters are allowed
                                      </div>
                                    </div> -->
                                  </div>
                                </div>
                                <div class="col-md-4" *ngIf="client_id && client_id.exported_from_the_factory">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Exported from the factory</label>
                                    <input type="text" class="form-control" [ngClass]="{ 'is-invalid': (f.exported_from_the_factory.touched || submitted) && f.exported_from_the_factory.errors }" formControlName="exported_from_the_factory" id="exampleInputEmail1" placeholder="Exported from the factory">
                                    <div *ngIf="(f.exported_from_the_factory.touched || submitted) && f.exported_from_the_factory.errors?.required"
                                      class="invalid-feedback">
                                      <div *ngIf="f.exported_from_the_factory.errors?.required">Export is required</div>
                                    </div>
                                    <!-- <div *ngIf="(f.first_name2.touched || submitted) && f.first_name2.errors?.maxlength"
                                      class="invalid-feedback"> -->
                                      <!-- formControlName="first_name2"
                                      <div *ngIf="f.first_name2.errors?.maxlength">Maximum 20 characters are allowed
                                      </div> -->
                                    </div>
                                  </div>
                                  <div class="col-md-4" *ngIf="client_id && client_id.reason">
                                    <div class="form-group">
                                      <label for="exampleInputEmail1">Reason</label>
                                      <textarea type="text" class="form-control" [ngClass]="{ 'is-invalid': (f.reason.touched || submitted) && f.reason.errors }"  formControlName="reason" id="exampleInputEmail1" placeholder="Reason"></textarea>
                                      <div *ngIf="(f.reason.touched || submitted) && f.reason.errors?.required"
                                        class="invalid-feedback">
                                        <div *ngIf="f.reason.errors?.required">Reason is required</div>
                                      </div>
                                      <!-- formControlName="middle_name2"
                                      <div *ngIf="(f.middle_name2.touched || submitted) && f.middle_name2.errors?.maxlength"
                                        class="invalid-feedback">
                                        <div *ngIf="f.middle_name2.errors?.maxlength">Maximum 20 characters are allowed
                                        </div>
                                      </div> -->
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12"> <button type="submit" class="btn btn-primary">Create Owner</button></div>
                                <!-- /.row -->
                              </div>
                            </div>
                          </form>
                          <!-- /.row -->
                        </div>
                      </div>
                      <div class="tab-pane active" id="menu1">
                        <div class="card card-default">
                          <div class="card-header">
                            <h3 class="card-title">Bulk Owner</h3>
                            <form  #myForm="ngForm" (ngSubmit)="reportDown(searchForm, myForm)">
                            <button class="btn btn-primary  mr-3 download_template mt-2 float-right" [disabled]="!client_id" > Download <i class="fa fa-download" aria-hidden="true"></i> </button>
                            <a href="javascript::void(0)" style="display: none; " #downloadZipLink class="btn btn-primary ml-auto download_template"><i class="fa fa-download" aria-hidden="true"></i>Download </a>
                            </form>
                          </div>
                        
                          <!-- /.card-header -->
                          <form [formGroup]="bulkOrderForm" #myForm1="ngForm" (ngSubmit)="bulkSubmit(bulkOrderForm, myForm1)"
                            class="" >
                            <div class="card-body">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>Select Client</label>
                                  <ngx-select-dropdown #id
                                  [config]="clientConfig"
                                  [options]="clients$"
                                 
                                  formControlName="client"
                                  (change)="onItemSelect($event)"
                                  [ngClass]="{ 'is-invalid': (b.client.touched || submitted) && b.client.errors }">
                                  </ngx-select-dropdown>
                                  <div *ngIf="(b.client.touched || submitted) && b.client.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="b.client.errors?.required">Client is required</div>
                                  </div>
                                </div>
                              </div>
                              
                                  <div class="col-md-6"  >
                            <div class="form-group">
                              <label>Month</label>
                               <select  [ngClass]="{ 'is-invalid': (b.month.touched || submitted) && b.month.errors?.required  || b.month.errors?.maxlength}" id="number" formControlName="month" class="form-control">
<option value="">Select Month</option>
                                <option value="01">Jan</option>
                                <option value="02">Feb</option>
                                <option value="03">Mar</option>
                                <option value="04">Apr</option>
                                <option value="05">May</option>
                                <option value="06">Jun</option>
                                <option value="07">Jul</option>
                                <option value="08">Aug</option>
                                <option value="09">Sept</option>
                                <option value="10">Oct</option>
                                 <option value="11">Nov</option>
                                <option value="12">Dec</option>

                               </select>
                              <div *ngIf="(b.month.touched || submitted) && b.month.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="b.month.errors?.required">Month number is required</div>
                              </div>
                              
                            </div>
                          </div>


       <div class="col-md-6" >
                            <div class="form-group">
                              <label>Year</label>
                               <select  [ngClass]="{ 'is-invalid': (b.year.touched || submitted) && b.year.errors?.required  || b.year.errors?.maxlength}" id="number" formControlName="year" class="form-control">
<option value="">Select Year</option>
                                <option value="15">2015</option>
                                <option value="16">2016</option>
                                <option value="17">2017</option>
                                <option value="18">2018</option>
                                <option value="19">2019</option>
                                <option value="20">2020</option>
                                <option value="21">2021</option>
                                <option value="22">2022</option>
                                <option value="23">2023</option>
                                <option value="24">2024</option>
                                <option value="25">2025</option>
                             

                               </select>
                              <div *ngIf="(b.year.touched || submitted) && b.year.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="b.year.errors?.required">Year number is required</div>
                              </div>
                              
                            </div>
                          </div>


                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="exampleInputFile">File input</label>
                                  <div class="drag_file">
                                    <div class="drag_spn"  appDrag (files)="filesDropped($event)">
                                      <i class="fas fa-cloud-upload-alt" ></i><br>
                                      <span>Drag and drop files here to</span><br>
                                      (or)
                                    </div>
                                    <!-- <div class="output file">
                                      <p class="file_name"></p>
                                    </div> -->
                                    <div class="input-group">
                                      <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="exampleInputFile"  formControlName="orderFile"
                                        (change)="handleFileInput($event, $event.target.files)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  [ngClass]="{ 'is-invalid': (b.orderFile.touched || submitted) && b.orderFile.errors }">
                                        <label class="custom-file-label" for="exampleInputFile"><span *ngIf="filename">{{filename}}</span><span *ngIf="!filename">Choose file</span></label>
                                      </div>
                                      
                                    </div>
                                    <div *ngIf="(b.orderFile.touched || submitted) && b.orderFile.errors?.required"
                                      class="invalid-feedback" style="display: block;">
                                      <div *ngIf="b.orderFile.errors?.required"> File is required</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12"><button type="submit" class="btn btn-primary">Create Owner</button>
                              </div>
                            </div>
                          </form>
                          <!-- /.row -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <!-- /.content-wrapper -->