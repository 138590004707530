import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { SiteHeaderComponent } from './_layouts/site-header/site-header.component';
import { routing } from './app.routing';
import { ModalComponent } from './_directives';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
// tslint:disable-next-line: max-line-length
import { BannersService,  AlertService, AuthenticationService, SettingsService, HomeService, ModalService, Countryservice, ClientService, ProductService, OrderService, UserService,   } from './_services';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { DataTablesModule } from 'angular-datatables';
import { SettingsComponent } from './settings'
import {NgxPaginationModule} from 'ngx-pagination';
 import { DuplicateComponent } from './duplicate/duplicate.component';
import { LSelect2Module } from 'ngx-select2';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPrintModule } from 'ngx-print';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ManageClientsComponent } from './manage-clients/manage-clients.component';
// tslint:disable-next-line: import-spacing
import { ManageUsersComponent } from './manage-users/manage-users.component'
;
import { ManageProductsComponent } from './manage-products/manage-products.component'
;
import { ManageRolesComponent } from './manage-roles/manage-roles.component'
;
import { CreateOrderComponent } from './create-order/create-order.component'
;
import { DragDirective } from './dnd.directive'
;
import { UserManagementComponent } from './user-management/user-management.component'
import { ConfirmedValidator } from './confirmed.validator';;
// tslint:disable-next-line: import-spacing
import { BulkPreviewComponent } from './bulk-preview/bulk-preview.component'
import { ManageOrdersComponent } from './manage-orders/manage-orders.component'
;
// import { ReportsComponent } from './reports/reports.component'
;
import { ProductionReportsComponent } from './production-reports/production-reports.component';
import { SummaryReportsComponent } from './summary-reports/summary-reports.component'
import { ReportService } from './_services/reports.service';;
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
;
import { InvoiceReportComponent } from './invoice-report/invoice-report.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EditOrderComponent } from './edit-order/edit-order.component'
;
import { ManageDetailsComponent } from './manage-details/manage-details.component';;
import { ManageClientComponent } from './manage-client/manage-client.component'

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        routing,
        DataTablesModule,
        LSelect2Module,
        SelectDropDownModule,
        NgxPrintModule,
        NgxPaginationModule,
        BrowserAnimationsModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circleSwish,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff'
        }),
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),

        // NgMultiSelectDropDownModule.forRoot()
    ],
    declarations: [
        AppComponent,
        ModalComponent,
        HomeComponent,
        LoginComponent,
         SiteHeaderComponent,
         SettingsComponent,
 
         ManageClientsComponent
,
         ManageUsersComponent
,
         ManageProductsComponent
,DuplicateComponent,
         ManageRolesComponent ,
         CreateOrderComponent ,
         DragDirective ,
         UserManagementComponent ,
         ManageOrdersComponent ,
        //  ReportsComponent ,
         ProductionReportsComponent,
         SummaryReportsComponent,
         ForgotPasswordComponent,
         InvoiceReportComponent,
         ResetPasswordComponent,
         EditOrderComponent,
         ManageDetailsComponent,
         BulkPreviewComponent,
         ManageClientComponent,],
        

    providers: [
        AuthSuperadminGuard,
        AuthVendorGuard,
        AlertService,
        AuthenticationService,
        SettingsService,
        HomeService,
        ModalService,
        BannersService,
        Countryservice,
        ClientService,
        ProductService,
        OrderService,
        UserService,
        ReportService,

        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
