import { Component, Renderer2, isDevMode } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html'
})

export class AppComponent {
  previousUrl: string;
  loadAPI: Promise<any>;
  curYear: string = (new Date()).getFullYear().toString();

  constructor(private renderer: Renderer2, private router: Router) {
    if (this.curYear != '2019') {
      this.curYear = '2019 - ' + this.curYear;

    }
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, this.previousUrl);
          }
          let currentUrlSlug = event.url.slice(1);
          
          if (currentUrlSlug) {
            this.renderer.addClass(document.body, currentUrlSlug);
          }
          if (currentUrlSlug == 'login' || currentUrlSlug.includes('reset-password')  || currentUrlSlug.match(/login\?returnUrl/g)) {
             this.renderer.addClass(document.body, 'login-page');
            this.renderer.removeClass(document.body, 'sidebar-mini');
            this.renderer.removeClass(document.body, 'layout-fixed');
            this.renderer.removeClass(document.body, 'sidebar-collapse');
          } else {

            var OneSignal = window['OneSignal'] || [];
            OneSignal.push(function () {
              OneSignal.init({
                appId: `${environment.oneSignalAppId}`,
              });
            });

           this.renderer.removeClass(document.body, 'login-page');
            OneSignal.push(function () {
              OneSignal.setExternalUserId(localStorage.getItem(`${environment.appName}` + '_adminId'));
            });

          }
          this.previousUrl = currentUrlSlug;
        }
      });
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [
          //  '/assets/js/jquery.min.js',
           '/assets/js/bootstrap.bundle.min.js',
           '/assets/js/bootstrap.bundle.min.js',
           '/assets/js/jquery.knob.min.js',
           '/assets/js/moment.min.js',
           '/assets/js/daterangepicker.js',
           '/assets/js/tempusdominus-bootstrap-4.min.js',
           '/assets/js/jquery.overlayScrollbars.min.js',
           '/assets/js/adminlte.js',
           '/assets/js/demo.js',
          
      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
      }

    }
  }
}
