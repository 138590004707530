<site-header></site-header>
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                        <li class="breadcrumb-item active">Settings</li>
                    </ol>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item active text-uppercase">WELCOME <span *ngIf="adminName != 'undefined'">{{adminName}}</span> <span *ngIf="companyName && role ==1">{{companyName}}</span></li>
                    </ol>
                </div>
                </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <!-- Main content -->
            <div class="tab-content container-fluid" id="quantity">
                <div class="tab-pane active" id="home">
                    <form [formGroup]="unitForm" (ngSubmit)="quantitySubmit()">
                        <div class="">
                            <div class="card-body container-fluid">
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Unit price</label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input type="number" formControlName="qty_price"  [ngClass]="{ 'is-invalid': (u.qty_price.touched || submitted) && u.qty_price.errors }" class="form-control" name="quantity" id="number" placeholder="Enter unit price">
                                                    <div *ngIf="(u.qty_price.touched || submitted) && u.qty_price.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.qty_price.errors?.required">Unit price is required</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Unit commission</label>
                                                
                                                <div class="input-group mb-3">
                                                    <input type="number" [ngClass]="{ 'is-invalid': (u.unit_commission.touched || submitted) && u.unit_commission.errors }" formControlName="unit_commission"  class="form-control" id="number" name="quantity" placeholder="Unit commission">
                                                    
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                    <div *ngIf="(u.unit_commission.touched || submitted) && u.unit_commission.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.unit_commission.errors?.required">Unit commission is required</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Unit commission price</label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input type="number" id="number" formControlName="unit_commission_price"  [ngClass]="{ 'is-invalid': (u.unit_commission_price.touched || submitted) && u.unit_commission_price.errors }" class="form-control" name="quantity" placeholder="Unit commission price">
                                                    <div *ngIf="(u.unit_commission_price.touched || submitted) && u.unit_commission_price.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.unit_commission_price.errors?.required">Unit commission price required</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Carrier Price</label>
                                                
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input type="number" id="number" formControlName="carrier_price" [ngClass]="{ 'is-invalid': (u.carrier_price.touched || submitted) && u.carrier_price.errors }"  class="form-control" name="quantity" placeholder="Carrier Price">
                                                    <div *ngIf="(u.carrier_price.touched || submitted) && u.carrier_price.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.carrier_price.errors?.required">Carrier price required</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Product Abbreviation</label>
                                                <!-- <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div> -->
                                                    <input type="text" formControlName="product_abrevation"  [ngClass]="{ 'is-invalid': (u.product_abrevation.touched || submitted) && u.product_abrevation.errors }" class="form-control" name="quantity" placeholder="Product Abbreviation">
                                                    <div *ngIf="(u.product_abrevation.touched || submitted) && u.product_abrevation.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.product_abrevation.errors?.required">Product abbreviation is required</div>
                                                    </div>
                                                    <!-- </div> -->
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Default State</label>
                                                    
                                                    <!-- <div class="input-group mb-3"> -->
                                                    <input type="text" formControlName="default_state"  [ngClass]="{ 'is-invalid': (u.default_state.touched || submitted) && u.default_state.errors }"  class="form-control" name="quantity" placeholder="Default State">
                                                    <div *ngIf="(u.default_state.touched || submitted) && u.default_state.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.default_state.errors?.required">Default state is required</div>
                                                    </div>
                                                    <!-- <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                </div> -->
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Agreement Term Months</label>
                                                <!-- <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div> -->
                                                    <input type="text" formControlName="aggreement_terms"  [ngClass]="{ 'is-invalid': (u.aggreement_terms.touched || submitted) && u.aggreement_terms.errors }" class="form-control" name="quantity" placeholder="Agreement Term Months">
                                                    <div *ngIf="(u.aggreement_terms.touched || submitted) && u.aggreement_terms.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.aggreement_terms.errors?.required">Agreement term is required</div>
                                                    </div>
                                                    <!-- </div> -->
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>MFG Labor War Months</label>
                                                    
                                                    <!-- <div class="input-group mb-3"> -->
                                                    <input type="text"  class="form-control" formControlName="mfg_labour_war" [ngClass]="{ 'is-invalid': (u.mfg_labour_war.touched || submitted) && u.mfg_labour_war.errors }" name="quantity" placeholder="MFG Labor War Months">
                                                    <div *ngIf="(u.mfg_labour_war.touched || submitted) && u.mfg_labour_war.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.mfg_labour_war.errors?.required">MFG labor is required</div>
                                                    </div>
                                                    <!-- <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                </div> -->
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>MFG Parts War Months</label>
                                                <!-- <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div> -->
                                                    <input type="text" formControlName="mfg_part_war"  [ngClass]="{ 'is-invalid': (u.mfg_part_war.touched || submitted) && u.mfg_part_war.errors }" class="form-control" name="quantity" placeholder="MFG Parts War Months">
                                                    <div *ngIf="(u.mfg_part_war.touched || submitted) && u.mfg_part_war.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.mfg_part_war.errors?.required">MFG parts is required</div>
                                                    </div>
                                                    <!-- </div> -->
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Product Manufacturer Name</label>
                                                    
                                                    <!-- <div class="input-group mb-3"> -->
                                                    <input type="text"  class="form-control" formControlName="product_manufacture" [ngClass]="{ 'is-invalid': (u.product_manufacture.touched || submitted) && u.product_manufacture.errors }" name="quantity" placeholder="Product Manufacturer Name">
                                                    <div *ngIf="(u.product_manufacture.touched || submitted) && u.product_manufacture.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.product_manufacture.errors?.required">Product manufacturer is required</div>
                                                    </div>
                                                    <!-- <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                </div> -->
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Email Invoice to</label>
                                                <!-- <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div> -->
                                                    <input type="text" formControlName="email_invoice_to"  [ngClass]="{ 'is-invalid': (u.email_invoice_to.touched || submitted) && u.email_invoice_to.errors }" class="form-control" name="quantity" placeholder="Email Invoice to">
                                                    <div *ngIf="(u.email_invoice_to.touched || submitted) && u.email_invoice_to.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.email_invoice_to.errors?.required">Email invoice is required</div>
                                                    </div>
                                                    <div *ngIf="(u.email_invoice_to.touched || submitted) && u.email_invoice_to.errors?.email"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.email_invoice_to.errors?.email">Enter valid email</div>
                                                    </div>
                                                    <!-- </div> -->
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Email Remit Document to</label>
                                                    
                                                    <!-- <div class="input-group mb-3"> -->
                                                    <input type="text"  class="form-control" formControlName="email_remit"  [ngClass]="{ 'is-invalid': (u.email_remit.touched || submitted) && u.email_remit.errors }" name="quantity" placeholder="Email Remit Document to">
                                                    <div *ngIf="(u.email_remit.touched || submitted) && u.email_remit.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.email_remit.errors?.required">Email remit is required</div>
                                                    </div>
                                                    <div *ngIf="(u.email_remit.touched || submitted) && u.email_remit.errors?.email"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.email_remit.errors?.email">Enter valid email</div>
                                                    </div>
                                                    <!-- <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                </div> -->
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Email History Backup to</label>
                                                <!-- <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div> -->
                                                    <input type="text" formControlName="email_history"  [ngClass]="{ 'is-invalid': (u.email_history.touched || submitted) && u.email_history.errors }" class="form-control" name="quantity" placeholder="Email History Backup to">
                                                    <div *ngIf="(u.email_history.touched || submitted) && u.email_history.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.email_history.errors?.required">Email history is required</div>
                                                    </div>
                                                    <div *ngIf="(u.email_history.touched || submitted) && u.email_history.errors?.email"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.email_history.errors?.email">Enter valid email</div>
                                                    </div>
                                                    <!-- </div> -->
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="submit" name="submit" value="Save" class="btn btn-primary mt-2">
                                </div>
                            </div>
                            
                        </form>
                    </div>
                </div>
                <section class="content" id="content">
                    <div class="container-fluid new_div">
                        <div class="panel">
                            <div class="panel-body container-fluid">
                                <div class="row row-lg">
                                    <div class="col-md-6">
                                        <form [formGroup]="settingForm" #myForm="ngForm" (ngSubmit)="onSubmit(settingForm, myForm)" class="">
                                            <!-- Example Basic Form (Form row) -->
                                            <div class="example-wrap">
                                                <h4 class="example-title">Change Email/ Password</h4>
                                                <div class="example">
                                                    <div class="example-wrap">
                                                        <label class="form-control-label" for="inputUserName">Email</label>
                                                        <input autocomplete="off" type="text" class="form-control username" id="inputUserName" formControlName="upd_username" onload="this.value=''" placeholder="Email" autocomplete="off" [ngClass]="{ 'is-invalid': (f.upd_username.touched || submitted) && f.upd_username.errors }"
                                                        />
                                                        <div *ngIf="(f.upd_username.touched || submitted) && f.upd_username.errors?.required"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.upd_username.errors?.required">Email is required</div>
                                                        </div>
                                                        
                                                        <div *ngIf="(f.upd_username.touched || submitted) && f.upd_username.errors?.email"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.upd_username.errors?.email"  >Please enter
                                                            valid email</div>
                                                        </div>
                                                        <div *ngIf="(f.upd_username.touched || submitted) && f.upd_username.errors?.maxlength"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.upd_username.errors?.maxlength">Maximum 50 characters are allowed
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="example-wrap">
                                                        <div class="checkbox-custom checkbox-default">
                                                            <input type="checkbox" id="inputIssReset" formControlName="is_reset_password" (change)="handleChange($event)" autocomplete="off" />
                                                            <label for="inputIssReset">Change Password</label>
                                                        </div>
                                                    </div>
                                                    <div class="example-wrap">
                                                        <label class="form-control-label" for="inputCurrent">Current Password</label>
                                                        <input autocomplete="off" onload="this.value=''" type="password" class="form-control password" id="inputCurrent" formControlName="upd_password" placeholder="Current Password" autocomplete="off" [ngClass]="{ 'is-invalid': (f.upd_password.touched || submitted) && f.upd_password.errors }"
                                                        />
                                                        <div *ngIf="(f.upd_password.touched || submitted) && f.upd_password.errors" class="invalid-feedback">
                                                            <div *ngIf="f.upd_password.errors.required">Current Password is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="example-wrap" formGroupName="passwords" *ngIf="isResetFlag">
                                                        <div class="example-wrap">
                                                            <label class="form-control-label" for="inputNew">New Password</label>
                                                            <input autocomplete="off" type="password" class="form-control password" id="inputNew" formControlName="new_password" placeholder="New Password" autocomplete="off" [ngClass]="{ 'is-invalid': (f.passwords['controls'].new_password.touched || submitted) && f.passwords['controls'].new_password.errors }"
                                                            />
                                                            <div *ngIf="(f.passwords['controls'].new_password.touched || submitted) && f.passwords['controls'].new_password.errors?.required" class="invalid-feedback">
                                                                <div *ngIf="f.passwords['controls'].new_password.errors?.required">New Password is required</div>
                                                            </div>
                                                            <div *ngIf="(f.passwords['controls'].new_password.touched || submitted) && f.passwords['controls'].new_password.errors?.maxlength" class="invalid-feedback">
                                                                <div *ngIf="f.passwords['controls'].new_password.errors?.maxlength">Maximum 100 characters are allowed</div>
                                                            </div>
                                                        </div>
                                                        <div class="example-wrap">
                                                            <label class="form-control-label" for="inputConfirm">Confirm
                                                            Password</label>
                                                            <input autocomplete="off" type="password" class="form-control" id="inputConfirm" formControlName="confirm_password" placeholder="Confirm Password" autocomplete="off" [ngClass]="{ 'is-invalid': (f.passwords['controls'].confirm_password.touched || submitted) && (f.passwords['controls'].confirm_password.errors?.required
                                                            || f.passwords.errors?.areEqual) }" />
                                                            <div *ngIf="(f.passwords['controls'].confirm_password.touched || submitted) && f.passwords['controls'].confirm_password.errors?.required " class="invalid-feedback">
                                                                <div *ngIf="f.passwords['controls'].confirm_password.errors?.required">
                                                                Confirm Password is required</div>
                                                            </div>
                                                            <div *ngIf="(f.passwords['controls'].confirm_password.touched || submitted) && f.passwords['controls'].confirm_password.errors?.maxlength " class="invalid-feedback">
                                                                <div *ngIf="f.passwords['controls'].confirm_password.errors?.maxlength">
                                                                Maximum 100 characters are allowed</div>
                                                            </div>
                                                            <div *ngIf="(f.passwords['controls'].confirm_password.touched || submitted) && (f.passwords.errors?.areEqual)" class="invalid-feedback">Passwords do not match
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group form-material">
                                                        <button type="submit" class="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <!-- End Example Basic Form (Form row) -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>