import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'site-header',
  templateUrl: './site-header.component.html',
})
export class SiteHeaderComponent implements OnInit {

role: any;
userType: any;
 
  constructor(public router: Router) { }
  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');

   companyName = localStorage.getItem(`${environment.appName}` + 'companyName');

  ngOnInit() {
    this.role = localStorage.getItem(`${environment.appName}` + '_role');

    this.userType = localStorage.getItem(`${environment.appName}` + '_userType');

  /*  if(this.router.url == '/create-orders'){
      if(this.role==2 && this.userType == 1){
        location.href='\home'
        return false;
      }
    }*/

    
    if(this.router.url == '/manage-clients' || this.router.url == '/manage-users' || this.router.url == '/manage-products'){
      if(this.role==2 || this.role == 1){
        location.href='\home'
        return false;
      }
    }
 

  }

}
