import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { BannersService, AlertService, Countryservice, ClientService, ProductService, UserService } from '../_services';
import { Subject, TimeoutError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ConfirmedValidator } from '../confirmed.validator';



declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent  implements OnInit, OnDestroy, AfterViewInit {

  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  companyName = localStorage.getItem(`${environment.appName}` + '_companyName');

  role: any;
  selectedDatasource: any;
  fileToUpload: File = null;
  imageExist: any;
  submitted = false;
  // table = false;
  cancelButton = false;
  users$: any;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  width_limit = `${environment.bannerWidth}`;
  height_limit = `${environment.bannerHeight}`;
  curPage = 1;
  perPage = 10;
  otherFiles: any;
  userForm: FormGroup;
  selectedClients = [];
  clients$ = [];
  selectedItems = [];
  widthLimit = `${environment.IdWidth}`;
  // tslint:disable-next-line: variable-name
  heightLimit = `${environment.IdHeight}`;

  proofImage: any;
   mutiselect=1;

  categoryCongfig = {

    displayKey: 'company_name', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Clients', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No Clients found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Clients', // label thats displayed in search input,
    searchOnKey: 'company_name' ,
    itemsShowLimit: 3,
    showDeselectAllBtn: true,
  showSelectAllBtn: true,
  deselectAllBtnText: 'Deselect',
  selectAllBtnText: 'Select',
    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  countryConfig = {

    displayKey: 'states', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select States', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No state found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search State', // label thats displayed in search input,


  selectAllBtnText: 'Select',
    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  stateConfig = {

    displayKey: 'county', // if objects array passed which key to be displayed defaults to description
    search: true, // true/false for the search functionlity defaults to false,
    height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select County', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No county found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search County', // label thats displayed in search input,

  selectAllBtnText: 'Select',
    // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  media_domain = `${environment.media_domain}`;

  clientForm: any;

  loading = false;
  mobileCheck: any;
  idImage: any;
  ConfirmedValidator: any;

  countries$: any;
  states$: any;
  cities$: any;

  datas: any;
   minLength: any;
   maxLength: any;
  countryModel: any;
  stateModel: any;
  userTypeModel: any;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private countryService: Countryservice,
    private clientService: ClientService,
    private productService: ProductService,
    private userService: UserService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
  ) { }


  ngOnInit() {

      this.maxLength =  '^((\\+91-?)|0)?[0-9]{15}$';
      this.minLength =  '^((\\+91-?)|0)?[0-9]{10}$';


    this.role = localStorage.getItem(`${environment.appName}` + '_role');

    const that = this;

    that.userForm = that.formBuilder.group({
      index: [{ value: null, disabled: true }],
      _id: [{ value: null, disabled: true }],
      firstName: ['', [Validators.required, Validators.maxLength(20)]],
      lastName: ['', [Validators.required, Validators.maxLength(10)]],

      phoneNumber: ['', [Validators.required, Validators.maxLength(15),Validators.minLength(10),Validators.pattern(/^[0-9]*$/)]],
      whichCountry: ['0', ],
      addressNumber: ['', [ Validators.maxLength(10)]],
      addressPrefix: ['',  [Validators.maxLength(20)]],
      streetName: ['', [ Validators.maxLength(20)]],
      addressType: ['', [Validators.maxLength(20)]],
      addressSuffix: ['', [Validators.maxLength(20)]],
      city: ['', [ Validators.maxLength(20)]],
      state: ['', ],
      zip: ['', [Validators.maxLength(15),Validators.pattern('^[0-9]*$'),]],
      country: [''],
      idProof: [''],
      emailID: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      userType: ['',[Validators.required, ]],
      client: [[], [Validators.required]],
      status: ['1'],
      password: ['', [Validators.required]],

      confirmPassword: ['', [Validators.required]]

    }, {

      validator: ConfirmedValidator('password', 'confirmPassword')


    });

    that.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: that.perPage,
      // lengthChange: false,
      language: {
        searchPlaceholder: "User Name"
      },
      searching: true,
      columnDefs: [{
        searchable: false,
        orderable: true,
        targets: 1
      }],
      // order: [[ 3, 'asc' ]]
      ordering: false,
    };

  


    that.clientService.getClients().subscribe(data => {
      that.clients$ = data.data.product;

      that.dtTrigger.next();
    });




    

  }

  onItemSelect(item: any) {
    // console.log(item)
 this.stateModel = [];
 this.states$ = item.value.county;
//  console.log(this.states$);

 this.countryModel = item.value.states;
 console.log(item.value.states);
  }

  signleClient(event){
    this.selectedClients = [];
    // console.log(event)
    // console.log(typeof(event.value))
    this.selectedClients.push(event.value._id);

  }

        multiClient(event){
        const clientArr = [];
          // console.log(event)
          // console.log(typeof(event.value))
        for (const client of event.value){
          clientArr.push(client._id);

          }
        console.log(clientArr);
        this.selectedClients = clientArr;

        }
  onSelectAll(items: any) {
    console.log(items);
  }

  handleFileInput(event: any, files: FileList) {
    const that = this;
    that.idImage = '';
    const reader = new FileReader();

    reader.onload = function(e) {
      const img = new Image;
      img.onload = function() {
        console.log(files)
        const imgWidth = parseInt(that.widthLimit);
        const imgHeight = parseInt(that.heightLimit);


          that.fileToUpload = files.item(0);

      };
      img.src = reader.result as string;

      that.imageExist = img.src;

    };

    reader.readAsDataURL(files.item(0));

  }
  deleteImage(){
    const that = this;
    that.proofImage = '';
    that.imageExist = false;

    that.idImage = '';
    that.userForm.get('idProof').setValue('');

  }
  getState(event){
    console.log(event.target.value);
    // tslint:disable-next-line: variable-name
    const country_id = event.target.value;
    this.http.get(`${environment.apiUrl}${environment.apiPrefix}/states/` + country_id).subscribe(res => {

this.cities$ = res;
this.states$ = this.cities$.data.countries.states;
// console.log(this.states$);

           });


  }



  ChnageType(event){
    this.userForm.patchValue({
      client:''
    });
    if (event.target.value == 0){
       this.mutiselect=0;
    }
    if (event.target.value == 1){
      this.mutiselect=1;
    }
  }
  get f() { return this.userForm.controls; }

  imageEdit(event) {
    this.imageExist = '';
  }
  onSubmit(form, formWrap) {
const that = this;

console.log(this.f.state.value)
that.submitted = true;
const id = form.getRawValue()._id;
const index = form.getRawValue().index;


        // stop here if form is invalid

if (id == null || !this.imageExist) {
       /*   that.userForm.get('idProof').setValidators([Validators.required]);
          that.userForm.get('idProof').updateValueAndValidity();*/
        }
if (that.userForm.invalid) {
          return;
        }

console.log(that.selectedClients);
if (id != null) {
  document.body.classList.add('jw-modal-open');
  // this.loading = true;
  alertify.confirm('Update User', 'Are you sure want to update user', function() {
    $('html, body').scrollTop(0);
    alertify.defaults.maintainFocus = false;
    that.loading = true;
    that.userService.update(
            id,
            that.f.firstName.value,
            that.f.lastName.value,

            that.f.phoneNumber.value,
            that.f.whichCountry.value,
            that.f.addressNumber.value,
            that.f.addressPrefix.value,
            that.f.streetName.value,
            that.f.addressType.value,
            that.f.addressSuffix.value,
            that.f.city.value,
            that.f.state.value,
            that.f.zip.value,
           that.countryModel,
           that.fileToUpload,
            that.f.emailID.value,
            that.f.password.value,
            that.selectedClients,
            that.f.userType.value,
            that.f.status.value,
             )
            .subscribe(
              data => {
                if (data.status.code == 0) {
                  that.submitted = false;
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.cancelButton = false;
                  // document.getElementsByClassName("tb-wrapper")[0].classList.remove('disabledTable');
                  // this.users$[index] = data.data.banner;

                  formWrap.reset();

                  location.reload();

                  location.href = '/manage-users';

                } else {
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.toastr.error(data.status.message);
                  // alertify.error(data.status.message)
                  // this.alertService.error(data.status.message);
                }
              },
              error => {
                that.toastr.error(error);

                document.body.classList.remove('jw-modal-open');
                that.loading = false;
              });

             } , function() { // alertify.error('Delete action cancelled')
            //  that.toastr.error('Delete action cancelled');
              // $('html, body').scrollTop(0);


              $('html, body').css({

                overflow: 'auto',
                overflowX: 'scroll'
            });

            });
        } else {
          document.body.classList.add('jw-modal-open');

          alertify.confirm('Create User', 'Are you sure want to create user', function() {
            that.loading = true;
            that.userService.save(
            that.f.firstName.value,
            that.f.lastName.value,

            that.f.phoneNumber.value,
            that.f.whichCountry.value,
            that.f.addressNumber.value,
            that.f.addressPrefix.value,
            that.f.streetName.value,
            that.f.addressType.value,
            that.f.addressSuffix.value,
            that.f.city.value,
            that.f.state.value,
            that.f.zip.value,
            that.countryModel,
           that.fileToUpload,
            that.f.emailID.value,
            that.f.password.value,
            that.selectedClients,
            that.f.userType.value,
            that.f.status.value,

            )
            .subscribe(
              data => {
                if (data.status.code == 0) {
                  that.submitted = false;
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.cancelButton = false;
                  location.reload();



                  // that.userForm.setValue({
                  //   index: null,
                  //   _id: null,

                  // })
                  formWrap.reset();
that.toastr.success(data.status.message);

                  // this.router.navigate(['/banners']);
                  location.href = 'manage-users';

                } else {
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
                  that.toastr.error(data.status.message);
                  // alertify.error(data.status.message)
                  // this.alertService.error(data.status.message);
                }
              },
              error => {
                that.toastr.error(error);
                // alertify.error(error)
                // this.alertService.error(error);
                document.body.classList.remove('jw-modal-open');
                that.loading = false;
              });
             } , function() { // alertify.error('Delete action cancelled')
              that.toastr.error('Create action cancelled');
              $('html, body').css({

                overflow: 'auto',
                overflowX: 'scroll'
            });
            });
        }
      }
      cancelSubmit(event) {
        this.selectedDatasource = [];
        this.countryModel = [];
        this.proofImage = '';
        this.imageExist = false;
        this.states$ = [];
        this.stateModel = [];
        // this.countryModel = '';
        this.idImage = '';
        this.cancelButton = false;
        document.getElementsByClassName('table-responsive')[0].classList.remove('disabledTable');
        this.userForm.setValue({
          index: null,
          _id: null,
          firstName: '',
          lastName:  '',
          emailID : '',
          password: '',
          confirmPassword: '',

          phoneNumber:  '',
          addressNumber:  '',
          addressPrefix: '',
          streetName: '',
          addressType: '',

          addressSuffix:  '',
          city:  '',
          state:  '',

          country: '',
          zip: '',
          idProof :  '',
          // email_invoice: client.email ? client.email : '',
          // email_general: client.email ? client.email : '',
          // email_outputfile: client.email ? client.email : '',
          // opt_output_mail: client.email ? client.email : '',

          whichCountry: '1',
          client : '',
           userType : '0',
           status : '1',
        });
        this.submitted = false;
        location.reload();
      }
      userEdit(event, user, i){

console.log(user);
document.getElementsByClassName('table-responsive')[0].classList.add('disabledTable');
this.idImage = user.id_proof;

let isAtctive = 'US';
if (user.active == 0){
          isAtctive = 'India';
        }
console.log('user type ' + user.user_type);

if (user.user_type == 0){


       this.mutiselect=0;

        }else{
this.mutiselect=1;


        }

const userArr = [];
for (const userA of user.user){
          userArr.push(userA._id);
        }
console.log(userArr);
this.cancelButton = true;

// this.countryModel = user.country;

        // this.clientModel = user.client_id;
this.stateModel = user.state;
this.selectedDatasource = userArr;
this.selectedClients = userArr;
this.userForm.setValue({

          index: i,
          _id: user._id,
          firstName: user.first_name ? user.first_name : '',
          lastName: user.last_name ? user.last_name : '',
          emailID : user.email ? user.email : '',
          password: '1234567890',
          confirmPassword: '1234567890',

          phoneNumber: user.mobile_no ? user.mobile_no : '',
          addressNumber: user.address_no ? user.address_no : '',
          addressPrefix: user.address_prefix ? user.address_prefix : '',
          streetName: user.street_name ? user.street_name : '',
          addressType: user.address_type ? user.address_type : '',

          addressSuffix: user.address_suffix ? user.address_suffix : '',
          city: user.city ? user.city : '',
          state: user.state ?  user.state : '',

          country:  user.country ?   user.country : '',
          zip: user.zip ? user.zip : '',

          whichCountry: user.country_type ? user.country_type : '',
          client: '',
          userType: user.user_type ? user.user_type : '0',
          status: user.active == 0 ? '0' : '1',
          idProof :  '',

        });
this.userForm.patchValue({
          client: user.user
        });

this.userForm.patchValue({
          idProof: user.id_proof
        });











      }


      userDelete(event, user, i) {
        const that = this;
        alertify.confirm('Delete User', 'Are you sure want to delete this record', function() {
          that.userService.delete(user._id)
            .subscribe(
              data => {
                if (data.status.code == 0) {
                  that.toastr.success('Deleted Successfully');
                  // alertify.success('Deleted Successfully')
                  setTimeout(function() {
                    location.reload();
                  }, 1000);
                  // that.users$.splice(i, 1);
                } else {
                  that.toastr.error(data.status.message);
                  // alertify.error(data.status.message)
                  // this.alertService.error(data.status.message);
                }
              },
              error => {
                that.toastr.error(error);
                // alertify.error(error)
                // this.alertService.error(error);
              });
        }
          , function() { // alertify.error('Delete action cancelled')
            that.toastr.error('Delete action cancelled');

          });

      }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ($.fn.dataTable.isDataTable('#table')) {
        const table = $('#table').DataTable();
        console.log(table);
      }
    }, 500);
  }
  }
