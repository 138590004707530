<site-header></site-header>
<ng-template #customLoadingTemplate></ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<!-- <script src="https://nightly.datatables.net/js/jquery.dataTables.js"></script> -->
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/home">Home/ </a>
                    </li>Invoice</ol>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <!-- <li class="breadcrumb-item"><a href="#">Home</a></li> -->
                        <!-- <li class="breadcrumb-item active text-uppercase">WELCOME <span *ngIf="adminName != 'undefined'">{{adminName}}</span> <span *ngIf="companyName && role ==1">{{companyName}}</span></li> -->
                    </ol>
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <form>
                <div class="order_tabs">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="order-head">Invoices</div>
                        </div>
                        <div class="col-sm-12">
                            <div class="order_tabs">
                                <div class="col-lg-4">
                                    <ngx-select-dropdown #id [config]="clientConfig" [options]="clients$" (change)="selectClients($event)" class="clientSelect" style="height:35px;"></ngx-select-dropdown>
                                </div>
                                <div class="tab-content">
                                    <div class="tab-pane active">
                                        <div class="order_list">
                                            <form [formGroup]="invoiceForm" #myForm="ngForm">
                                                <div class="table-responsive">
                                                    <table cellspacing="0" id="tb" class="table table-hover table-striped w-full">
                                                        <!-- class="table table-hover dataTable table-striped w-full" datatable [dtOptions]="dtOptions" -->
                                                        <thead>
                                                            <tr>
                                                                <th>Invoice Date</th>
                                                                <th>Invoice Number</th>
                                                                <th>Payment Date</th>
                                                                <th>Payment Method</th>
                                                                <th>Funds Forwarded to Carrier</th>
                                                                <th>Amount Forwarded to Carrier</th>
                                                                <th>Amount Due</th>
                                                                <th>Amount Paid</th>
                                                                <th>Total amount</th>
                                                                <th>Total orders</th>
                                                                <th>Date</th>
                                                                <th>Action</th>
                                                                <th>Gpg</th>
                                                                <th>Edit</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container *ngIf="length>0">
                                                            <tr class="total_order" *ngFor="let invoice of invoices$ | paginate: { itemsPerPage: 10, currentPage: curPage };let i=index;">
                                                                <td *ngIf="invoice.invoice_date">{{invoice.invoice_date}}</td>
                                                                <td *ngIf="!invoice.invoice_date">-</td>
                                                                <td>{{invoice.invoice_no}}</td>
                                                                <td *ngIf="!cancelButton || i != rowNumber">{{invoice.payment_date}}</td>
                                                                <td *ngIf="!cancelButton || i != rowNumber">{{invoice.payment_method}}</td>
                                                                <td *ngIf="!cancelButton || i != rowNumber">{{invoice.funds_forward}}</td>
                                                                <td *ngIf="!cancelButton || i != rowNumber">{{invoice.amount_forward}}</td>
                                                                <td *ngIf="cancelButton && i == rowNumber">
                                                                    <input type="text" formControlName="payment_date" class="form-control payment_date" placeholder="Payment Date"  >
                                                                </td>
                                                                <td *ngIf="cancelButton && i == rowNumber" width="20%">
                                                                    <select formControlName="payment_method" class="form-control">
                                                                        <option  value="">Select Payment Method</option>
                                                                        <option value="Check">Check</option>
                                                                        <option value="Wire">Wire</option>
                                                                    </select>
                                                                </td>
                                                                <td *ngIf="cancelButton && i == rowNumber">
                                                                    <input type="text" formControlName="funds_forward" class="form-control" placeholder="Funds Forwarded to Carrier">
                                                                </td>
                                                                <td *ngIf="cancelButton && i == rowNumber">
                                                                    <input type="text" formControlName="amount_forward" class="form-control" placeholder="Amount Forwarded to Carrier">
                                                                </td>
                                                                <td>{{invoice.invoice_amount}}</td>
                                                                <td *ngIf="!cancelButton || i != rowNumber">{{invoice.amount_paid}}</td>
                                                                <td *ngIf="cancelButton && i == rowNumber">
                                                                    <input type="text" formControlName="amount_paid" class="form-control" placeholder="Amount Paid">
                                                                </td>
                                                                <td>{{invoice.amount_due}}</td>
                                                                <td>{{invoice.count}}</td>
                                                                <td width="7%">{{invoice.date}}</td>
                                                                <!-- <td><a *ngIf="invoice.upload;else no" href="{{apiurl}}files/{{invoice.id_invoice}}" target="_blank">folder</a><ng-template #no>-</ng-template>
                                                            </td> -->
                                                            <td><a href="{{apiurl}}{{api_prefix}}/download_xl/{{invoice.id_invoice  }}"><i class="fa fa-download" title="Download"></i></a>
                                                            <!-- <i *ngIf="invoice.upload==0;else uploaded" (click)="upload_invoice(invoice.id_invoice)" class="fa fa-upload ml-3" title="Upload"></i> -->
                                                            <ng-container *ngIf="invoice.upload==1;">Uploaded</ng-container>
                                                        </td>
                                                        <td> <span *ngIf="!invoice.file"> -</span>
                                                        <a *ngIf="invoice.file" target="_blank" href="http://18.204.20.55/files/{{invoice.file}}" download> Download</a>
                                                    </td>
                                                    <td> <i class="far fa-edit" *ngIf="!cancelButton ||  i != rowNumber" (click)="editInvoice(invoice,i)"></i>
                                                        <!-- <i class="fa fa-pencil-square-o editButton" aria-hidden="true" *ngIf="!cancelButton" (click)="editInvoice(invoice)"></i> -->
                                                        <!-- <input type="checkbox" *ngIf="cancelButton &&  i == rowNumber" (change)="invoiceUpdate(invoice)"> --> <a href="javascript::void(0)" class="btn btn-success" *ngIf="cancelButton &&  i == rowNumber" (click)="invoiceUpdate(invoice)">Update</a>
                                                        <!-- <button type="button" class="btn btn-success"  *ngIf="cancelButton &&  i == rowNumber" (change)="invoiceUpdate(invoice)">Update</button> -->
                                                    </td>
                                                </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    <pagination-controls *ngIf="invoices$?.length>0" (pageChange)="curPage = $event" style="float: right;width: 100%;"></pagination-controls>
                                    <ng-container *ngIf="length<1">
                                    <h1 class="text-muted text-center">No data found</h1>
                                    </ng-container>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.col -->
    </div>
</div>
</form>
</div>
<!-- /.container-fluid -->
</section>
<!-- /.content -->
</div>
<!-- /.content-wrapper -->