<site-header></site-header>

<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                        <li class="breadcrumb-item active">Manage Client</li>
                    </ol>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item active text-uppercase">WELCOME <span *ngIf="adminName != 'undefined'">{{adminName}}</span> <span *ngIf="companyName && role ==1">{{companyName}}</span></li>
                    </ol>
                </div>
                </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <!-- Main content -->
            <div class="tab-content container-fluid" id="quantity">
                <div class="tab-pane active" id="home">
                    <form [formGroup]="unitForm" #myForm = "ngForm" (ngSubmit)="quantitySubmit(unitForm, myForm)">
                        <div class="">
                            <div class="card-body container-fluid">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Client Name</label>
                                                <div class="input-group mb-3">
                                                    <!-- <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div> -->
                                                    <input type="text" formControlName="client_name"  [ngClass]="{ 'is-invalid': (u.qty_price.touched || submitted) && u.client_name.errors }" class="form-control"  id="number" placeholder="Enter Client Name">
                                                    <div *ngIf="(u.client_name.touched || submitted) && u.client_name.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.client_name.errors?.required">Client Name is required</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Unit price</label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input type="number" formControlName="qty_price"  [ngClass]="{ 'is-invalid': (u.qty_price.touched || submitted) && u.qty_price.errors }" class="form-control" name="quantity" id="number" placeholder="Enter unit price">
                                                    <div *ngIf="(u.qty_price.touched || submitted) && u.qty_price.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.qty_price.errors?.required">Unit price is required</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Unit commission</label>
                                                
                                                <div class="input-group mb-3">
                                                    <input type="number" [ngClass]="{ 'is-invalid': (u.unit_commission.touched || submitted) && u.unit_commission.errors }" formControlName="unit_commission"  class="form-control" id="number" name="quantity" placeholder="Unit commission">
                                                    
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                    <div *ngIf="(u.unit_commission.touched || submitted) && u.unit_commission.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.unit_commission.errors?.required">Unit commission is required</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="row">
                                       
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Unit commission price</label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input type="number" id="number" formControlName="unit_commission_price"  [ngClass]="{ 'is-invalid': (u.unit_commission_price.touched || submitted) && u.unit_commission_price.errors }" class="form-control" name="quantity" placeholder="Unit commission price">
                                                    <div *ngIf="(u.unit_commission_price.touched || submitted) && u.unit_commission_price.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.unit_commission_price.errors?.required">Unit commission price required</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Carrier Price</label>
                                                
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input type="number" id="number" formControlName="carrier_price" [ngClass]="{ 'is-invalid': (u.carrier_price.touched || submitted) && u.carrier_price.errors }"  class="form-control" name="quantity" placeholder="Carrier Price">
                                                    <div *ngIf="(u.carrier_price.touched || submitted) && u.carrier_price.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.carrier_price.errors?.required">Carrier price required</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Product Abbreviation</label>
                                                <!-- <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div> -->
                                                    <input type="text" formControlName="product_abrevation"  [ngClass]="{ 'is-invalid': (u.product_abrevation.touched || submitted) && u.product_abrevation.errors }" class="form-control" name="quantity" placeholder="Product Abbreviation">
                                                    <div *ngIf="(u.product_abrevation.touched || submitted) && u.product_abrevation.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.product_abrevation.errors?.required">Product abbreviation is required</div>
                                                    </div>
                                                    <!-- </div> -->
                                                    
                                                </div>
                                            </div>
                                       
                                    </div>
                                   
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Default State</label>
                                                
                                                <!-- <div class="input-group mb-3"> -->
                                                <input type="text" formControlName="default_state"  [ngClass]="{ 'is-invalid': (u.default_state.touched || submitted) && u.default_state.errors }"  class="form-control" name="quantity" placeholder="Default State">
                                                <div *ngIf="(u.default_state.touched || submitted) && u.default_state.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="u.default_state.errors?.required">Default state is required</div>
                                                </div>
                                                <!-- <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                            </div> -->
                                            
                                        </div>
                                    </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Agreement Term Months</label>
                                                <!-- <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div> -->
                                                    <input type="text" formControlName="aggreement_terms"  [ngClass]="{ 'is-invalid': (u.aggreement_terms.touched || submitted) && u.aggreement_terms.errors }" class="form-control" name="quantity" placeholder="Agreement Term Months">
                                                    <div *ngIf="(u.aggreement_terms.touched || submitted) && u.aggreement_terms.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.aggreement_terms.errors?.required">Agreement term is required</div>
                                                    </div>
                                                    <!-- </div> -->
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>MFG Labor War Months</label>
                                                    
                                                    <!-- <div class="input-group mb-3"> -->
                                                    <input type="text"  class="form-control" formControlName="mfg_labour_war" [ngClass]="{ 'is-invalid': (u.mfg_labour_war.touched || submitted) && u.mfg_labour_war.errors }" name="quantity" placeholder="MFG Labor War Months">
                                                    <div *ngIf="(u.mfg_labour_war.touched || submitted) && u.mfg_labour_war.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.mfg_labour_war.errors?.required">MFG labor is required</div>
                                                    </div>
                                                    <!-- <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                </div> -->
                                                
                                            </div>
                                        </div>
                                       
                                           
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Product Manufacturer Name</label>
                                                
                                                <!-- <div class="input-group mb-3"> -->
                                                <input type="text"  class="form-control" formControlName="product_manufacture" [ngClass]="{ 'is-invalid': (u.product_manufacture.touched || submitted) && u.product_manufacture.errors }" name="quantity" placeholder="Product Manufacturer Name">
                                                <div *ngIf="(u.product_manufacture.touched || submitted) && u.product_manufacture.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="u.product_manufacture.errors?.required">Product manufacturer is required</div>
                                                </div>
                                                <!-- <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                            </div> -->
                                            
                                        </div>
                                    </div>
                                     <div class="col-md-4">
                                            <div class="form-group">
                                                <label>MFG Parts War Months</label>
                                                <!-- <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div> -->
                                                    <input type="text" formControlName="mfg_part_war"  [ngClass]="{ 'is-invalid': (u.mfg_part_war.touched || submitted) && u.mfg_part_war.errors }" class="form-control" name="quantity" placeholder="MFG Parts War Months">
                                                    <div *ngIf="(u.mfg_part_war.touched || submitted) && u.mfg_part_war.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.mfg_part_war.errors?.required">MFG parts is required</div>
                                                    </div>
                                                    <!-- </div> -->
                                                    
                                                </div>
                                            </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Email Invoice to</label>
                                                <!-- <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div> -->
                                                    <input type="text" formControlName="email_invoice_to"  [ngClass]="{ 'is-invalid': (u.email_invoice_to.touched || submitted) && u.email_invoice_to.errors }" class="form-control" name="quantity" placeholder="Email Invoice to">
                                                    <div *ngIf="(u.email_invoice_to.touched || submitted) && u.email_invoice_to.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.email_invoice_to.errors?.required">Email invoice is required</div>
                                                    </div>
                                                    <div *ngIf="(u.email_invoice_to.touched || submitted) && u.email_invoice_to.errors?.email"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.email_invoice_to.errors?.email">Enter valid email</div>
                                                    </div>
                                                    <!-- </div> -->
                                                    
                                                </div>
                                            </div>
                                         
                                           
                                    </div>
                                    
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Email Remit Document to</label>
                                                    
                                                    <!-- <div class="input-group mb-3"> -->
                                                    <input type="text"  class="form-control" formControlName="email_remit"  [ngClass]="{ 'is-invalid': (u.email_remit.touched || submitted) && u.email_remit.errors }" name="quantity" placeholder="Email Remit Document to">
                                                    <div *ngIf="(u.email_remit.touched || submitted) && u.email_remit.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.email_remit.errors?.required">Email remit is required</div>
                                                    </div>
                                                    <div *ngIf="(u.email_remit.touched || submitted) && u.email_remit.errors?.email"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.email_remit.errors?.email">Enter valid email</div>
                                                    </div>
                                                    <!-- <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                </div> -->
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Email History Backup to</label>
                                                <!-- <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div> -->
                                                    <input type="text" formControlName="email_history"  [ngClass]="{ 'is-invalid': (u.email_history.touched || submitted) && u.email_history.errors }" class="form-control" name="quantity" placeholder="Email History Backup to">
                                                    <div *ngIf="(u.email_history.touched || submitted) && u.email_history.errors?.required"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.email_history.errors?.required">Email history is required</div>
                                                    </div>
                                                    <div *ngIf="(u.email_history.touched || submitted) && u.email_history.errors?.email"
                                                        class="invalid-feedback">
                                                        <div *ngIf="u.email_history.errors?.email">Enter valid email</div>
                                                    </div>
                                                    <!-- </div> -->
                                                    
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Supplier Code</label>
                                                    <!-- <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">$</span>
                                                        </div> -->
                                                        <input type="text" formControlName="supplier_code"  [ngClass]="{ 'is-invalid': (u.supplier_code.touched || submitted) && u.supplier_code.errors }" class="form-control" placeholder="Supplier Code">
                                                        <div *ngIf="(u.email_history.touched || submitted) && u.email_history.errors?.required"
                                                            class="invalid-feedback">
                                                            <div *ngIf="u.supplier_code.errors?.required">Supplier code is required</div>
                                                        </div>
                                                        <!-- <div *ngIf="(u.email_history.touched || submitted) && u.email_history.errors?.email"
                                                            class="invalid-feedback">
                                                            <div *ngIf="u.email_history.errors?.email">Enter valid email</div>
                                                        </div> -->
                                                        <!-- </div> -->
                                                        
                                                    </div>
                                                </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Purchase Order Code</label>
                                                    <!-- <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">$</span>
                                                        </div> -->
                                                        <input type="text" formControlName="purchase_order_code"  [ngClass]="{ 'is-invalid': (u.purchase_order_code.touched || submitted) && u.purchase_order_code.errors }" class="form-control" placeholder="Purchase Order Code">
                                                        <div *ngIf="(u.purchase_order_code.touched || submitted) && u.email_history.errors?.required"
                                                            class="invalid-feedback">
                                                            <div *ngIf="u.purchase_order_code.errors?.required">Purchase Order Code is required</div>
                                                        </div>
                                                        <!-- <div *ngIf="(u.email_history.touched || submitted) && u.email_history.errors?.email"
                                                            class="invalid-feedback">
                                                            <div *ngIf="u.email_history.errors?.email">Enter valid email</div>
                                                        </div> -->
                                                        <!-- </div> -->
                                                        
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Serial No
                                                    <input type="checkbox" formControlName="serial_no">
                                                    <span class="checkmark" ></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Driveline ID
                                                    <input type="checkbox" formControlName="driveline_id">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Manufacturing date
                                                    <input type="checkbox" formControlName="manufacturing_date">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Shipped to
                                                    <input type="checkbox" formControlName="shipped_to">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Product Category
                                                    <input type="checkbox" formControlName="product_category">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Part No On Product
                                                    <input type="checkbox" formControlName="part_no_on_product">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Product Family
                                                    <input type="checkbox" formControlName="product_family">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Technical Platform
                                                    <input type="checkbox" formControlName="technical_platform">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Product Name
                                                    <input type="checkbox" formControlName="product_name">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Ship Date
                                                    <input type="checkbox" formControlName="ship_date">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>

                                            <div class="col-md-3">
                                                <label class="container-checkbox">Manufacturing Plant
                                                    <input type="checkbox" formControlName="manufacturing_plant">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Region
                                                    <input type="checkbox" formControlName="region">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>

                                            <div class="col-md-3">
                                                <label class="container-checkbox">Country
                                                    <input type="checkbox" formControlName="country">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Product Designation
                                                    <input type="checkbox" formControlName="product_desgnation">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Sales Order Type
                                                    <input type="checkbox" formControlName="sales_order_type">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">SCC Individual Status
                                                    <input type="checkbox" formControlName="scc_status">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Exported from the factory
                                                    <input type="checkbox" formControlName="exported_factory">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            <div class="col-md-3">
                                                <label class="container-checkbox">Reason
                                                    <input type="checkbox" formControlName="reason">
                                                    <span class="checkmark"></span>
                                                  </label>
                                                
                                            </div>
                                            </div>
                                       
                                  
                                    <button type="submit" name="submit" value="Save" class="btn btn-primary mt-2">
                                        <div *ngIf="cancelButton;then update else add">here is ignored</div>
                                        <ng-template #add>Create Client</ng-template>
                                        <ng-template #update>Update Client</ng-template>
                                    </button>
                                    <a style="margin-left:5px" (click)="cancelSubmit($event)"
                                    class="btn btn-danger mt-2 text-white cancel" *ngIf="cancelButton" >
                                    <i class="icon md-close" aria-hidden="true"></i> Cancel
                                    </a>
                                </div>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                </div>
               
                <div class="content-header">
                    <div class="container-fluid">
                        <table cellspacing="0"  class="table table-hover dataTable table-striped w-full "  >
                            <thead>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Client Name</th>
                                    <th>Unit price</th>
                                    <th>Unit commission</th>
                                    <th>Unit commission price</th>
                                    <th>Carrier Price</th>
                                    <th>Default State</th>
                                    <th>MFG Parts War Months</th>
                                    <th>Product Manufacturer Name</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let value of clients$ | paginate: { itemsPerPage: 10, currentPage: curPage };let i = index">
                                    <td>{{i+1}}</td>
                                    <td>{{value.client_name}}</td>
                                    <td>{{value.unit_price}}</td>
                                    <td>{{value.unit_commission}}</td>
                                    <td>{{value.unit_commission_price}}</td>
                                    <td>{{value.carrier_price}}</td>
                                    <td>{{value.default_state}}</td>
                                    <td>{{value.mfg_part_war}}</td>
                                    <td>{{value.product_manufacture}}</td>
                                    <td><i class="fas fa-pencil-alt" title="Edit" style="cursor: pointer;color: blue;" (click)="clientEdit($event,value,i)"></i></td>
                                    <td><i class="fas fa-trash-alt" title="Delete" style="cursor: pointer;color:red" (click)="clientDelete($event,value,i)"></i></td>
                                    
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls *ngIf="clients$?.length>0" (pageChange)="curPage = $event" style="float: right;width: 100%;"></pagination-controls>
                    </div>
                </div>
            </div>