import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalService, OrderService, ClientService, ProductService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../_services/reports.service';
declare var jquery: any;
declare var moment: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-summary-reports',
  templateUrl: './summary-reports.component.html',
  styleUrls: ['./summary-reports.component.css']
})
export class SummaryReportsComponent implements OnInit, AfterViewInit {
  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  companyName = localStorage.getItem(`${environment.appName}` + '_companyName');
  role:any;
      
  @ViewChild('downloadZipLink', {static: false}) private downloadZipLink: ElementRef;
    searchForm: FormGroup;
    count = 0;
    subjects: any;
    submitted = false;
    investigators: any;
    municipals: any;
    loading = false;
    clients$: any;
  products$: any;
  total$: 0;
    clientConfig = {

      displayKey: 'company_name', // if objects array passed which key to be displayed defaults to description
      search: true, // true/false for the search functionlity defaults to false,
      height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select Clients', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No client found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search Clients', // label thats displayed in search input,


    selectAllBtnText: 'Select',
      // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    };


    statusConfig = {

      displayKey: 'name', // if objects array passed which key to be displayed defaults to description
      search: false, // true/false for the search functionlity defaults to false,
      height: '300px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select Status', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      // noResultsFound: 'No client found!', // text to be displayed when no items are found while searching
      // searchPlaceholder: 'Search Clients', // label thats displayed in search input,
  class: 'select_status',

    selectAllBtnText: 'Select',
      // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    };

    productConfig = {
      displayKey: 'product_name', // if objects array passed which key to be displayed defaults to description
      search: false, // true/false for the search functionlity defaults to false,
      height: '250px', // height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select Product', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      // moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No product found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search Product', // label thats displayed in search input,
      searchOnKey: 'product_name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    };
  selectList: { name: string; value: number; }[];




    constructor(
      private toastr: ToastrService,
      private formBuilder: FormBuilder,
      private modalService: ModalService,
      private orderService: OrderService,
      private clientService: ClientService,
      private reportService: ReportService,
      private productService: ProductService,
      private route: ActivatedRoute,
      private router: Router,
      private http: HttpClient,
      private sanitizer: DomSanitizer,
    ) {
    }
    
  get f() { return this.searchForm.controls; }

    public async reportDown(form, formWrap): Promise<void> {
      // console.log( $('#fini').val())
      // console.log(this.f.client.value._id)
      // console.log( this.f.status.value.value)

      this.submitted = true;
      if (this.searchForm.invalid) {
        return;
      }
      document.body.classList.add('jw-modal-open');
      this.loading = true;
const product = [];
      if (this.f.product.value){
  for (const products of this.f.product.value){


    product.push(products._id);
  }
}
let status = '';
      if (this.f.status.value){
  status = this.f.status.value.value;
}

      const blob = await this.reportService.productionSummary_download(

  $('#fini').val(),
  this.f.client.value._id,
   product,
 status,

          );
let binaryData = [];
      binaryData.push(blob);
      const url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/ms-excel'}));
// const url = window.URL.createObjectURL(blob);

      const link = this.downloadZipLink.nativeElement;
      link.href = url;
      link.download = 'production_reports.xlsx';
      link.click();

// window.URL.revokeObjectURL(url);
      this.loading = false;
      document.body.classList.remove('jw-modal-open');
// link.stop();
  }

  ngOnInit() {
    this.role = localStorage.getItem(`${environment.appName}` + '_role');

    const that = this;
    that.searchForm = that.formBuilder.group({
      client: ['', [Validators.required]],
      product: [],
      status: '',
      daterange: ''


    });

    that.selectList =
    [
    { name: 'invalid', value: -2 },
      {name: 'Unassigned', value: 0},
      {name: 'In process', value: 1},
      {name: 'On hold', value: 2},
      {name: 'Cancelled', value: -1},
      {name: 'Completed', value: 10},
      {name: 'Exception', value: 3},
    ];


    that.reportService.getClients().subscribe(data => {
        that.clients$ = data.data.product;
        });

        // that.reportService.productionSummary('', [], '', '').subscribe(data => {
        //   that.total$ = data.data.total;
        //   console.log(that.total$);
        // });

  }

filter(){
  let client = '';
  const product = [];
  let status = '';
  let daterange = '';
  if (this.f.client.value){
  client = this.f.client.value._id;
  console.log(client);
  }
  if (this.f.product.value){
    console.log(this.f.product.value);
    for (const products of this.f.product.value){


      product.push(products._id);
    }
    }
  if (this.f.status.value){
    status = this.f.status.value.value;
    }
    if(this.f.client.value){
      console.log('value     fghfjg   '+this.f.client.value)
      this.productService.getClientProducts(this.f.client.value._id).subscribe(data=>{
        this.products$ = data.data.product;
      })

    }

  daterange = $('#fini').val();


  this.reportService.productionSummary(client, product, status, daterange).subscribe(data => {
    this.total$ = data.data.total;
    console.log(this.total$);
  });

}

    ngAfterViewInit() {
      const that = this;
      setTimeout(() => {
      $('#fini').daterangepicker({
      autoUpdateInput: false,
      opens: 'left',
       locale: {
        format: 'DD/MM/YYYY'
      },
      ranges: {
           Today: [moment(), moment()],
           Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
           'Last 7 Days': [moment().subtract(6, 'days'), moment()],
           'Last 30 Days': [moment().subtract(29, 'days'), moment()],
           'This Month': [moment().startOf('month'), moment().endOf('month')],
           'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
      });
      $('#fini').on('apply.daterangepicker', function(ev, picker) {
          $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
          // $(this).trigger('change');
          that.filter();
      });

    }, 1000);
  }
}
