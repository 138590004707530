// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  
  appName: "GPG",

  production: false,
  apiUrl: 'https://api.biawarranty.com/',
   oneSignalAppId: '', //dev
  media_domain:'' ,//dev
  apiPrefix: 'api/v1',
  imgWidth: '500',
  imgHeight: '500',
  servImgWidth: '720',
  servImgHeight: '480',
  servCovImgWidth: '360',
  servCovImgHeight: '200',
  banImgWidth: '64',
  banImgHeight: '64',
  gameTypeWidth: '72',
  gameTypeHeight: '72',
  bannerWidth: '930',
  bannerHeight: '480',
  IdWidth: '265',
  IdHeight: '190'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
