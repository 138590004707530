<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<!-- <script src="https://nightly.datatables.net/js/jquery.dataTables.js"></script> -->
<div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/home">Home</a></li>
                        <li class="breadcrumb-item active">Owner Listing Screen</li>
                    </ol>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <!-- <li class="breadcrumb-item"><a href="#">Home</a></li> -->
                        <li class="breadcrumb-item active text-uppercase">WELCOME <span *ngIf="adminName != 'undefined'">{{adminName}}</span> <span *ngIf="companyName && role ==1">{{companyName}}</span></li>
                    </ol>
                </div>
                </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <div class="">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="order-head">Search Filters</div>
                            </div>
                        </div>
                        <div class="">
                            <form [formGroup]="searchForm" #myForm="ngForm" (ngSubmit)="reportDown(searchForm, myForm)">
                                <div class="row">
                                    <div class="col-lg-4 ">
                                        <ngx-select-dropdown #id [config]="clientConfig" [options]="clients$" formControlName="client" (change)="selectClients($event)" class="clientSelect" style="height:35px;"></ngx-select-dropdown>
                                    </div>
                                    <div class="col-lg-4 " >
                                        <input type="text" formControlName="search" placeholder="Search" class="form-control" *ngIf="serach_flag"  (keyup)="searchFilter($event.target.value)">
                                    </div>
                                </div>
                              
                            </form>
                            <div class="row">
                                <div class="col-lg-12 float-right mt-2" >
                                    <button (click)="uploadInvoiceData()" class="btn btn-primary ml-auto download_template float-right"><i class="fa fa-upload" aria-hidden="true"></i>Upload </button>
                                    <button class="btn btn-info float-right mr-1 " (click)="generate($event)">Generate Invoice</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="order-head">Owner Management </div>
                    </div>
                    <div class="order_tabs" >
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="order_tabs">
                                    <div class="tab-content">
                                        <div class="tab-pane active">
                                            <div class="order_list"  >
                                                <div class="table-responsive">
                                                    <table cellspacing="0" id="tb" class="table table-hover dataTable table-striped w-full" datatable [dtOptions]="dtOptions"  >
                                                        
                                                        <thead>
                                                            <tr>
                                                                <th *ngIf="active_coloumns && active_coloumns.serial_no">Serial No</th>
                                                                <th >Client Name</th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.driveline_id">Driveline id</th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.manufacturing_date">Manufacturing Date</th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.sold_to">Sold To </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.shipped_to">Shipped To </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.product_category"> Product Category </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.product_family"> Product Family </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.technical_platform"> Technical Platform </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.application"> Application </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.product_name"> Product Name </th>
                                                                <th width="10%" *ngIf="active_coloumns &&active_coloumns.ship_date"> Ship Date </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.manufacturing_plant"> Manufacturing Plant </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.region"> Region </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.country"> Country </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.product_desgnation"> Product Designation </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.sales_order_no"> Sales Order No </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.sales_order_type"> Sales Order Type </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.part_no_on_product"> Part No On Product </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.scc_status"> SCC Individual Status </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.exported_from_the_factory"> Exported From The Factory </th>
                                                                <th *ngIf="active_coloumns &&active_coloumns.reason"> Reason </th>
                                                                <th>Upload date</th>
                                                                <th>Invoice date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody  >
                                                            <tr *ngFor="let order of orders$ " class="total_order">
                                                                <td *ngIf="active_coloumns.serial_no"> {{order.serial_no}} </td>
                                                                
                                                                <td > {{order.client_name}} </td>
                                                                <td  *ngIf="active_coloumns.driveline_id"> {{order.driveline_id}} </td>
                                                                
                                                                
                                                                <td *ngIf=" active_coloumns.manufacturing_date"> {{order.manufacturing_date}} </td>
                                                                
                                                                
                                                                <td *ngIf="active_coloumns.sold_to "> {{order.sold_to}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.shipped_to "> {{order.shipped_to}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.product_category "> {{order.product_category}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.product_family "> {{order.product_family}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.technical_platform "> {{order.technical_platform}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.application "> {{order.application}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.product_name "> {{order.product_name}} </td>
                                                                
                                                                <td width="10%" *ngIf="active_coloumns.ship_date "> {{order.ship_date}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.manufacturing_plant "> {{order.manufacturing_plant}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.region "> {{order.region}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.country"> {{order.country}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.product_desgnation "> {{order.product_designation}} </td>
                                                                
                                                                <td  *ngIf="active_coloumns.sales_order_no "> {{order.sales_order_no}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.sales_order_type "> {{order.sales_order_type}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.part_no_on_product "> {{order.part_no_on_product}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.scc_status "> {{order.scc_status}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.exported_from_the_factory "> {{order.exported_from_the_factory}} </td>
                                                                
                                                                <td *ngIf="active_coloumns.reason "> {{order.reason}} </td>
                                                                
                                                                <td>{{order.upload_date?order.upload_date:'-'}}</td>
                                                                <td>{{order.invoice_date?order.invoice_date:'-'}}</td>
                                                            </tr>
                                                        </tbody>
                                                        
                                                    </table>
                                                    
                                                    
                                                    
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <!-- /.col -->
                            
                        </div>
                    </div>
                 
                   </div> 
                </section>
               
            </div>
   