import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ClientService {
    constructor(private http: HttpClient) { }

    updateUnit(id,price, unit_commission,
      unit_commission_price,
        carrier_price,
        product_abrevation,
        default_state,
        aggreement_terms,
        mfg_labour_war,
        mfg_part_war,
        product_manufacture,
        email_invoice_to,
        email_remit,
        email_history,
        supplier_code,
        purchase_order_code,
        client_name,
        serial_no,
        driveline_id,
        manufacturing_date,
        shipped_to,
        product_category,
        product_family,
        technical_platform,
        product_name,
        ship_date,
        manufacturing_plant,
        region,
        product_desgnation,
        sales_order_type,
        scc_status,
        exported_factory,
        reason,
        country,
        part_no_on_product) {
        // var id = localStorage.getItem(`${environment.appName}` + '_adminId');
        const formData: FormData = new FormData();
        formData.append('unit_price', price);
        formData.append('unit_commission', unit_commission);
        formData.append('unit_commission_price', unit_commission_price);
        formData.append('carrier_price', carrier_price);
        formData.append('product_abrevation', product_abrevation);
        formData.append('default_state', default_state);
        formData.append('aggreement_terms', aggreement_terms);
        formData.append('mfg_labour_war', mfg_labour_war);
        formData.append('mfg_part_war', mfg_part_war);
        formData.append('product_manufacture', product_manufacture);
        formData.append('email_invoice_to', email_invoice_to);
        formData.append('email_remit', email_remit);
        formData.append('email_history', email_history);
        formData.append('supplier_code', supplier_code);
        formData.append('purchase_order_code', purchase_order_code);
        formData.append('client_name', client_name);
        formData.append('serial_no', serial_no);
        formData.append('driveline_id', driveline_id);
        formData.append('manufacturing_date', manufacturing_date);
        formData.append('shipped_to', shipped_to);
        formData.append('product_category', product_category);
        formData.append('product_family', product_family);
        formData.append('technical_platform', technical_platform);
        formData.append('product_name', product_name);
        formData.append('ship_date', ship_date);
        formData.append('manufacturing_plant', manufacturing_plant);
        formData.append('region', region);
        formData.append('product_desgnation', product_desgnation);
        formData.append('sales_order_type', sales_order_type);
        formData.append('scc_status', scc_status);
        formData.append('exported_factory', exported_factory);
        formData.append('reason', reason);
        formData.append('country', country);
        formData.append('part_no_on_product', part_no_on_product);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/manage-client/update/` + id, formData).pipe(map(user => {
          return user;
        }));
      }
 gettempAllClients(bulkId:any): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/order/temp/`+bulkId).pipe(map(user => user));

  }
      saveUnit(price, unit_commission,
        unit_commission_price,
        carrier_price,
        product_abrevation,
        default_state,
        aggreement_terms,
        mfg_labour_war,
        mfg_part_war,
        product_manufacture,
        email_invoice_to,
        email_remit,
        email_history,
        supplier_code,
        purchase_order_code,
        client_name,
        serial_no,
        driveline_id,
        manufacturing_date,
        shipped_to,
        product_category,
        product_family,
        technical_platform,
        product_name,
        ship_date,
        manufacturing_plant,
        region,
        product_desgnation,
        sales_order_type,
        scc_status,
        exported_factory,
        reason,
        country,
        part_no_on_product
        
        ) {
          // var id = localStorage.getItem(`${environment.appName}` + '_adminId');
          const formData: FormData = new FormData();
          formData.append('unit_price', price);
          formData.append('unit_commission', unit_commission);
          formData.append('unit_commission_price', unit_commission_price);
          formData.append('carrier_price', carrier_price);
          formData.append('product_abrevation', product_abrevation);
          formData.append('default_state', default_state);
          formData.append('aggreement_terms', aggreement_terms);
          formData.append('mfg_labour_war', mfg_labour_war);
          formData.append('mfg_part_war', mfg_part_war);
          formData.append('product_manufacture', product_manufacture);
          formData.append('email_invoice_to', email_invoice_to);
          formData.append('email_remit', email_remit);
          formData.append('email_history', email_history);
          formData.append('supplier_code', supplier_code);
          formData.append('purchase_order_code', purchase_order_code);
          formData.append('client_name', client_name);
          formData.append('serial_no', serial_no);
          formData.append('driveline_id', driveline_id);
          formData.append('manufacturing_date', manufacturing_date);
          formData.append('shipped_to', shipped_to);
          formData.append('product_category', product_category);
          formData.append('product_family', product_family);
          formData.append('technical_platform', technical_platform);
          formData.append('product_name', product_name);
          formData.append('ship_date', ship_date);
          formData.append('manufacturing_plant', manufacturing_plant);
          formData.append('region', region);
          formData.append('product_desgnation', product_desgnation);
          formData.append('sales_order_type', sales_order_type);
          formData.append('scc_status', scc_status);
          formData.append('exported_factory', exported_factory);
          formData.append('reason', reason);
          formData.append('country', country);
          formData.append('part_no_on_product', part_no_on_product);
          return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/manage-client/add_client`, formData).pipe(map(user => {
            return user;
          }));
        }
    save(
        // name: string,
        // designation: string,
        // whichCountry:string,
        // email:string,
        // mobile_number: string,
        password: string,
        status: string,
        company_name: string,
        primary_contact: string,
        prefix: string,
        address_no: string,
        street: string,
        type: string,
        suffix: string,
        city: string,
        state: string,
        postal: string,
        country: string,
        email_invoice: string,
        email_general: string,
        email_outputfile: string,
        out_email: string,
        options: any

        ) {

          if (state != undefined){
            state = state;
          }
          if(country != undefined){
            country = country;
          }

if(password !='1234567890'){
  password = password;
}
          const formData = {
          // 'name':name,
        //    'email':email,
        //  'designation':designation,
        // 'active':whichCountry,
        //  'mobile_no':mobile_number,
         password,
         company_name,
         primary_contact,
        address_no,
         address_prefix: prefix,
        street_name: street,
        address_type: type,
        address_suffix: suffix,
         active: status,
        zip: postal,
        city ,
         state,
         country,
         options,
         email: email_invoice,
         email_genaral: email_general,
         email_output: email_outputfile,
         out_email
       };


          return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/client`, formData).pipe(map(user => {
            return user;
        }));

    }

    getAllClients(): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/manage-client/client/list`).pipe(map(user => user));

  }

    update(
      id: string,
      // name: string,
      // designation: string,
    //   whichCountry:string,
    //   email:string,
    //   mobile_number: string,
      password: string,
      status: string,
      company_name: string,
      primary_contact: string,
      prefix: string,
      address_no: string,
      street: string,
      type: string,
      suffix: string,
      city: string,
      state: string,
      postal: string,
      country: string,
      email_invoice: string,
      email_general: string,
      email_outputfile: string,

      out_email: string,
      options: any) {
let pass = '';
if (password != '1234567890'){
pass = password;
}
if(state==undefined){
  state = '';
}
if(country==undefined){
  country = '';
}
console.log(country)
let formData = {
         // 'name':name,
        //    'email':email,
        //  'designation':designation,
        // 'active':whichCountry,
        //  'mobile_no':mobile_number,

        company_name,
        primary_contact,
       address_no,
        address_prefix: prefix,
       street_name: street,
       address_type: type,
       address_suffix: suffix,
        active: status,
       zip: postal,
       city ,
        state,
        country,
        options,
        email: email_invoice,
        email_genaral: email_general,
        email_output: email_outputfile,
        password: pass,
        out_email
       };

return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/client/` + id, formData).pipe(map(user => {
            return user;
        }));

    }
    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/client/` + id).pipe(map(user => {
            return user;
        }));

    }
    getClients(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/client`).pipe(map(user => user));

    }

    deleteClient(id): Observable<any> {
      return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/manage-client/delete/client/`+id).pipe(map(user => user));

  }
    getSingleClient(id: string): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/client/` + id).pipe(map(user => user));

  }
}
