import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SettingsService {
    constructor(private http: HttpClient) { }

    save(username: string, is_reset: string, password: string, new_pass: string) {
        const formData: FormData = new FormData();

        formData.append('username', username);
        formData.append('password', password);
        if (is_reset) {
            formData.append('new_password', new_pass);
        }

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/oauth/update-password`, formData).pipe(map(user => {
            return user;
        }));

    }
    requestToPassword(email: string){
      const formData: FormData = new FormData();
      formData.append('email', email);
      return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/forgot-password`, formData).pipe(map(user => {
        return user;
    }));

    }

  updateUnit(price, unit_commission,
  unit_commission_price,
  carrier_price,
  product_abrevation,
  default_state,
  aggreement_terms,
  mfg_labour_war,
  mfg_part_war,
  product_manufacture,
  email_invoice_to,
  email_remit,
  email_history) {
    var id = localStorage.getItem(`${environment.appName}` + '_adminId');
    const formData: FormData = new FormData();
    formData.append('unit_price', price);
    formData.append('unit_commission', unit_commission);
    formData.append('unit_commission_price', unit_commission_price);
    formData.append('carrier_price', carrier_price);
    formData.append('product_abrevation', product_abrevation);
    formData.append('default_state', default_state);
    formData.append('aggreement_terms', aggreement_terms);
    formData.append('mfg_labour_war', mfg_labour_war);
    formData.append('mfg_part_war', mfg_part_war);
    formData.append('product_manufacture', product_manufacture);
    formData.append('email_invoice_to', email_invoice_to);
    formData.append('email_remit', email_remit);
    formData.append('email_history', email_history);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/quantity/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  getData():Observable<any> {
    var id = localStorage.getItem(`${environment.appName}` + '_adminId');
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/quantity_get/` +id).pipe(map(user => {
      return user;
    }));
  }

updatePassword(password:string,random: string){
  const formData: FormData = new FormData();
  formData.append('random', random);
  formData.append('password', password);
  return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/update/password`, formData).pipe(map(user => {
        return user;
    }));
}

}
