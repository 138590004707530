import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';



declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.css']
})
export class EditOrderComponent implements OnInit {
 
  constructor() { }

  ngOnInit(): void {
    
  }

}
