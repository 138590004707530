<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<!-- <ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading> -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">Product Management</li>
          </ol>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <!-- <li class="breadcrumb-item"><a href="#">Home</a></li> -->
            <li class="breadcrumb-item active text-uppercase">WELCOME <span *ngIf="adminName != 'undefined'">{{adminName}}</span> <span *ngIf="companyName && role ==1">{{companyName}}</span></li>
          </ol>
        </div>
        </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-4">
              <div class="product_list">
                <form [formGroup]="productForm" #myForm="ngForm" (ngSubmit)="onSubmit(productForm, myForm)"
                  class="" >
                  <div class="user_create">
                    <div class="user_create_form">
                      <div class="form-group">
                        <label for="name">Product Name</label>
                        <input type="text" class="form-control" autocomplete="off" id="product_name" placeholder="Product Name"  formControlName="product_name"
                        [ngClass]="{ 'is-invalid': (f.product_name.touched || submitted) && f.product_name.errors }">
                        <div *ngIf="(f.product_name.touched || submitted) && f.product_name.errors?.required"
                          class="invalid-feedback">
                          <div *ngIf="f.product_name.errors?.required">Product Name is required</div>
                        </div>
                        <div *ngIf="(f.product_name.touched || submitted) && f.product_name.errors?.maxlength"
                          class="invalid-feedback">
                          <div *ngIf="f.product_name.errors?.maxlength">Maximum 30 characters are allowed
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="product_create_btn">
                      <button type="submit">
                      <div *ngIf="cancelButton;then update else add">here is ignored</div>
                      <ng-template #add>Create Product</ng-template>
                      <ng-template #update>Update Product</ng-template>
                      </button>
                      <a style="margin-left:5px" (click)="cancelSubmit($event)"
                        class="btn btn-danger cancel" *ngIf="cancelButton" >
                        <i class="icon md-close" aria-hidden="true"></i> Cancel
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-8">
              <div class="product_create">
                <div class="table-responsive">
                  <table cellspacing="0" id="table" class="table table-hover dataTable table-striped w-full"
                    datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="products$">
                      <tr *ngFor="let product of products$; let i = index">
                        <td>{{product.product_name}}
                        </td>
                        <td><i class="fas fa-pencil-alt" title="Edit" style="cursor:pointer" (click)="editProduct($event,product,i)"></i></td>
                        <td><i class="fas fa-trash-alt" title="Delete" style="cursor: pointer;" (click)="deleteProduct($event,product,i)"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
      </div>