import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ProductService {
    constructor(private http: HttpClient) { }

    save(product_name: string) {
        const formData: FormData = new FormData();

        formData.append('product_name', product_name);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/product`, formData).pipe(map(user => {
            return user;
        }));

    }

    update(id: string, product_name: string,) {
        const formData: FormData = new FormData();


        formData.append('product_name', product_name);


        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/product/` + id, formData).pipe(map(user => {
            return user;
        }));

    }
    delete(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/product/` + id).pipe(map(user => {
            return user;
        }));

    }
    getProducts(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/product`).pipe(map(user => user));

    }

    getClientProducts(id:any): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/client/products/` + id).pipe(map(user => user));

  }
  productionSummary(id:any) {


    var formData = {};
    formData['client_id'] = id;

    // tslint:disable-next-line: max-line-length
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/client/products?`+$.param(formData)).pipe(map(user => {

            return user;
        }));

      }
  getClientMultiProducts(id:any): Observable<any> {

    var formData = {};
    formData['client_id'] = id;


    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/client/products?`+$.param(formData)).pipe(map(user => user));

}
}
